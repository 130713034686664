import { Col, Row } from 'antd';
import ContentTable from 'components/visualizations/ContentTable';
import {
  AudienceMapWithData,
  ISPTableWithData,
  RebufferingTimeBarchartWithData,
  UserAgentPieWithData,
  ViewByWeekdaysBarchartWithData
} from 'containers/analytics/components';
import { WatchingTimeBarchartWithData } from 'containers/analytics/components/WatchingTimeBarchartWithData';
import { withLayout } from 'containers/analytics/utils/withLayout';
import { useFilters } from 'contexts/filters';
import React from 'react';
import { useContentData } from './utils/hooks';

export const bigNumberColProps = { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 };

const OverviewPage: React.FC<any> = () => {
  const { contents, apiKey, from, to, selectedFilters: filters, updateSelectedFilters } = useFilters();
  const option = { apiKey, from, to, filters };
  // we do not reload the content data, as we want them all.
  const { data: contentData } = useContentData({ option: { apiKey, from, to, filters: { ...filters, content: [] } } });

  return (
    <Row gutter={[19, 20]}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <AudienceMapWithData option={option} useTable />
      </Col>
      <Col {...bigNumberColProps}>
        <ViewByWeekdaysBarchartWithData option={{ ...option, from: 'now-7d/d', to: 'now' }} />
      </Col>
      <Col {...bigNumberColProps}>
        <WatchingTimeBarchartWithData />
      </Col>
      <Col {...bigNumberColProps}>
        <RebufferingTimeBarchartWithData />
      </Col>

      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <UserAgentPieWithData option={option} col="browser" topN={10} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <UserAgentPieWithData option={option} col="os" topN={10} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <ISPTableWithData option={option} />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <ContentTable
          contents={contents}
          contentTableItems={contentData}
          onContentSelected={(content: string[]) => {
            const contentSelectedInTable = { ...filters, content };
            updateSelectedFilters(contentSelectedInTable);
          }}
        />
      </Col>
    </Row>
  );
};

export default withLayout(OverviewPage);
