import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { css, useTheme } from '@emotion/react';
import { EText } from 'components/base';
import { useTranslate } from 'utils/intl';

const styles = {
  textContainer: css`
    position: relative;
    top: -200px;
    padding-left: 30%;
    padding-right: 30%;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `,
};

export default function PredictionPie({ enable = 100, disable = 0 } = {}) {
  const theme = useTheme() as any;
  const [t] = useTranslate();
  return (
    <div style={{ height: 340, maxWidth: 380 }}>
      <ReactEcharts
        style={{ height: 340 }}
        option={{
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} viewers ({d}%)',
          },
          legend: {
            orient: 'horizontal',
            bottom: 0,
            data: ['Enabled', 'Disabled'],
          },
          color: [theme.colors.primaryColor1, theme.colors.disableColor],
          series: [
            {
              name: 'V2V global enabled percent',
              type: 'pie',
              radius: ['55%', '75%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'inside',
              },
              labelLine: {
                show: false,
              },
              emphasis: {
                label: {
                  show: true,
                  fontWeight: 'bold',
                },
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
              data: [
                { value: enable, name: 'Enabled' },
                { value: disable, name: 'Disabled' },
              ],
            },
          ],
        }}
      />
      <div css={styles.textContainer}>
        <EText strong>{t('page.v2vConfig.estimated')}</EText>
        <EText
          type="paragraph"
          ellipsis={{
            rows: 3,
            expandable: false,
          }}
        >
          {t('page.v2vConfig.estimatedDesc')}
        </EText>
      </div>
    </div>
  );
}
