import React from 'react';
import { Button } from 'antd';
import { css } from '@emotion/react';
import { ButtonProps } from 'antd/lib/button/button';
import { useTranslate } from 'utils/intl';

type EButtonType = 'normal' | 'solid';


function _Button({ _type = 'normal', text: _text, ...props }: { _type?: EButtonType; text?: string } & ButtonProps) {
  const [t] = useTranslate();
  let typeProps;
  if (_type === 'normal') typeProps = { ghost: true };
  const commonProps = {
    type: 'primary',
    shape: 'round',
    css: css`
      min-width: 100px;
    `,
    ...typeProps,
    ...props,
  } as any;

  let text = _text;
  if (!text && props.htmlType === 'submit') text = 'button.submit';
  return <Button {...commonProps}>{text ? t(text) : props.children}</Button>;
}

export default _Button;
