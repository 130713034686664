import { getCountriesList } from './countries';

export enum ACCESS_FEATURES {
  INTERNAL = 'INTERNAL',
  USER_MANAGE = 'USER_MANAGE',
  LICENSE_MANAGE = 'LICENSE_MANAGE',
  CHANNEL_CONFIG = 'CHANNEL_CONFIG',
  PLAYER_INSTALL = 'PLAYER_INSTALL',
  V2V_CONFIG = 'V2V_CONFIG',
  NOTIFICATION = 'NOTIFICATION',
  REPORT = 'REPORT',
  APIKEYS_READONLY = 'APIKEYS_READONLY',
  APIKEYS_ALL = 'APIKEYS_ALL',
  GLOBAL_ROLES = 'GLOBAL_ROLES',
}

export enum CHANNEL_STATUS {
  PENDING = 'PENDING',
  WAITING = 'WAITING',
  PROCESS = 'PROCESS',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum ROLE_TYPE {
  NORMAL = 'NORMAL',
  GLOBAL = 'GLOBAL',
}

export enum ACCESS_TYPE {
  // ALL = 'ALL',
  INHERIT = 'INHERIT',
  CUSTOMIZED = 'CUSTOMIZED',
}

export enum MetricsOperator {
  lt = 'lt',
  gt = 'gt',
}

export enum Status {
  WAITING,
  ACTIVE,
  DISABLED,
}

export function getRoleTypeOptions(userFeatures: string[]) {
  return [
    {
      label: ROLE_TYPE.GLOBAL,
      value: ROLE_TYPE.GLOBAL,
      disabled: !userFeatures.includes('*') && !userFeatures.includes(ACCESS_FEATURES.INTERNAL),
    },
    {
      label: ROLE_TYPE.NORMAL,
      value: ROLE_TYPE.NORMAL,
    },
  ];
}

export function getAccessFeatureOptions(userFeatures: string[]) {
  return Object.values(ACCESS_FEATURES)
    .map((e) => ({
      value: e,
      label: e,
      disabled: !userFeatures.includes('*') && userFeatures.indexOf(e) === -1,
    }))
    .filter((e) => !e.disabled);
}

export function getAccessType() {
  return Object.values(ACCESS_TYPE);
}

export const COUNTRY_LIST = getCountriesList();
