import { Badge } from 'antd';
import React from 'react';
import { Status } from 'utils/enums';

interface StatusBadgeProps {
  status?: Status;
}

export default function ({ status = 1 }: StatusBadgeProps) {
  let _p = {};
  switch (status) {
    case Status.WAITING:
      _p = { color: 'yellow', text: 'waiting' };
      break;
    case Status.DISABLED:
      _p = { status: 'default', text: 'disabled' };
      break;
    case Status.ACTIVE:
    default:
      _p = { status: 'processing', text: 'active' };
  }

  return <Badge {..._p} />;
}
