import { message, Modal } from 'antd';
import { EText } from 'components/base';
import React from 'react';
import { getIntlInstance } from './intl';

type NoticeType = 'info' | 'success' | 'error' | 'warning' | 'loading';

type contentType = string | { id: string; values: Record<string, any> };

interface NotificationParams {
  duration: number;
  key?: string;
}

interface MessageParams {
  type: NoticeType;
  content: contentType;
  config?: NotificationParams;
}

function parseTextContent(content: contentType): string {
  const contentId = typeof content === 'string' ? content : content.id;
  const contentValues = typeof content === 'string' ? {} : content.values;
  const intl = getIntlInstance();
  const contentMsg = intl ? intl.formatMessage({ id: contentId, defaultMessage: contentId }, contentValues) : contentId;
  return contentMsg;
}

const showMessage = ({ content, config, type }: MessageParams) => {
  message.open({
    type,
    content: parseTextContent(content),
    duration: config?.duration || 3,
    key: config?.key || undefined,
  });
};

export const Notification = {
  info: (content: contentType, config?: NotificationParams) => showMessage({ type: 'info', content, config }),
  success: (content: contentType, config?: NotificationParams) => showMessage({ type: 'success', content, config }),
  error: (content: contentType, config?: NotificationParams) => showMessage({ type: 'error', content, config }),
  warning: (content: contentType, config?: NotificationParams) => showMessage({ type: 'warning', content, config }),
  loading: (content: contentType, config?: NotificationParams) => showMessage({ type: 'loading', content, config }),
};

export const ModalNotification = {
  success: (contents: contentType[], onOk: any = () => {}) => {
    Modal.success({
      content: contents.map((c) => <EText type="paragraph">{parseTextContent(c)}</EText>),
      onOk,
    });
  },
  warning: (contents: contentType[], onOk: any = () => {}) => {
    Modal.warning({
      content: contents.map((c) => <EText type="paragraph">{parseTextContent(c)}</EText>),
      onOk,
    });
  },
};

export default Notification;
