import qs from 'qs';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { requestV3 } from './requests';

const filterKeys = ['content', 'origins'];
export const emptyFilter = Object.fromEntries(filterKeys.map((key) => [key, []]));

export default function loadFiltersAPI(apikey: string) {
  return requestV3
    .get(`/filters`, {
      params: {
        apikey: apikey === '*' ? [] : [apikey],
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    .then((v) => v.data);
}

export const useAvailableFiltersAPI = (apiKey: string) => {
  const [statFilters, setStatFilters] = useState<any>(emptyFilter);
  const { data, isLoading, error } = useQuery(['available-filters', apiKey], () => {
    return loadFiltersAPI(apiKey);
  });

  useEffect(() => {
    if (isLoading === false && data) {
      const { origin, ...rest } = data;
      setStatFilters({ ...rest, origins: origin });
    }
  }, [isLoading, data]);

  return { statFilters, isLoading, error };
};
