import { useFilters } from 'contexts/filters';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import _ from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { FilterDef } from 'services/data';
import { worldGeoJsonWithStats } from 'services/data/geo';
import { loadQoEQoSRanges } from 'services/data/qoeqos';
import { requestV3 } from 'services/data/requests';
import { filtersToParams } from 'services/data/utils';
import { formatDuration } from './functions';

interface QoERangeOptions extends BaseOption {
  type: 'qoeStartupDelay';
}
export interface BaseOption {
  apiKey: string;
  from: string;
  to: string;
  lastNMinutes?: number;
  filters: FilterDef;
}

const weekDayMapping = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

// export const getLocalTimezoneString = (): string => {
//   const timezone_offset_min = new Date().getTimezoneOffset();
//   const offset_hrs = Math.round(Math.abs(timezone_offset_min / 60));
//   // offset_min = Math.abs(timezone_offset_min % 60),
//   const offset_min = '00';
//   let timezone_standard;
//   let offset_hrs_str = offset_hrs.toString();

//   if (offset_hrs < 10) offset_hrs_str = '0' + offset_hrs;

//   // Add an opposite sign to the offset
//   // If offset is 0, it means timezone is UTC
//   if (timezone_offset_min < 0) timezone_standard = '+' + offset_hrs_str + ':' + offset_min;
//   else if (timezone_offset_min > 0) timezone_standard = '-' + offset_hrs_str + ':' + offset_min;
//   else if (timezone_offset_min === 0) timezone_standard = '+00:00';
//   return timezone_standard;
// };

function apiDataToGraphData(point: any, intervalSecond: number) {
  return {
    date: new Date(point.timestamp),
    views: point.pid,
    cdn: point.bytes_cdn,
    cdnBandwidth: point.bytes_cdn / intervalSecond,
    v2v: point.bytes_p2p,
    v2vBandwidth: point.bytes_p2p / intervalSecond,
    total: point.bytes_cdn + point.bytes_p2p,
    totalBandwidth: (point.bytes_cdn + point.bytes_p2p) / intervalSecond,
  };
}

const hourToIntervalAPI = (hour: number): [string, number] => {
  if (hour <= 24 * 2) {
    return ['5m', 5];
  }
  if (hour > 24 * 2 && hour <= 24 * 14) {
    return ['1h', 60];
  }
  if (hour > 14 * 24) {
    return ['1d', 24 * 60];
  }
  return ['5m', 5];
};

export const useAPITimeseries = ({ option }: { option: BaseOption }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { apiKey, from, to, filters, lastNMinutes } = option;
  const [dataTotal, setDataTotal] = useState<{ total: number; v2v: number; cdn: number; percent: number }>({
    total: 1,
    v2v: 0,
    cdn: 0,
    percent: 0,
  });
  const [maxViewers, setMaxViewer] = useState({ views: undefined });

  useEffect(() => {
    setLoading(true);
    const rangeHour = dayjs.duration(dayjs(to).diff(dayjs(from))).asHours();
    const interval = hourToIntervalAPI(rangeHour)[0];
    const intervalInMins = hourToIntervalAPI(rangeHour)[1];
    let prom;
    if (lastNMinutes) {
      prom = requestV3.get(`/timeseries/usage/realtime`, {
        params: {
          apikey: apiKey === '*' ? [] : [apiKey],
          n: lastNMinutes,
          o: filters.origins,
          c: filters.content,
        },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });
    } else {
      prom = requestV3.get(`/timeseries/usage/history`, {
        params: {
          apikey: apiKey === '*' ? [] : [apiKey],
          s: from,
          e: to,
          interval,
          o: filters.origins,
          c: filters.content,
        },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });
    }
    prom.then((x) => {
      const _data = x.data.response.map((point: any) => apiDataToGraphData(point, intervalInMins * 60));
      setData(_data);
      setLoading(false);

      // aggregation
      setMaxViewer({ views: d3.max(_data.map((v) => v.views)) as any });

      const cdn = d3.sum(_data.map((v) => v.cdn));
      const v2v = d3.sum(_data.map((v) => v.v2v));
      setDataTotal({ total: cdn + v2v, v2v, cdn, percent: v2v / (cdn + v2v) });
      // setMaxViewer({ views: d3.max(x.data.response.map((point: any) => point.views)) || 0 });
    });
  }, [from, to, apiKey, filters, lastNMinutes]);

  return {
    data,
    loading,
    aggregation: {
      data: dataTotal,
      maxViewers,
    },
  };
};

// export const normalizeFilters = (filters: FilterDef) => {
//   return { ...filters, origin: filters.origins };
// };

export const rangeBucketsToPercentsDocCount = (ranges: any) => {
  let sumViews = 0;
  let cumulativePercent = 0;
  const buckets = ranges.buckets.map((m: any) => {
    const cnt = m.doc_count;
    sumViews += cnt;
    return { key: m.key, cnt };
  });

  buckets.map((v: any) => {
    v.percent = (v.cnt * 100) / sumViews;
    v.fromPercent = cumulativePercent;
    cumulativePercent += Number(v.percent.toFixed(2));
    v.toPercent = cumulativePercent;
    return v;
  });

  return buckets;
};

export const rangeBucketsToPercents = (ranges: any) => {
  let sumViews = 0;
  let cumulativePercent = 0;
  const buckets = ranges.buckets.map((m: any) => {
    const cnt = m.views.value;
    sumViews += cnt;
    return { key: m.key, cnt };
  });

  buckets.map((v: any) => {
    v.percent = (v.cnt * 100) / sumViews;
    v.fromPercent = cumulativePercent;
    cumulativePercent += Number(v.percent.toFixed(2));
    v.toPercent = cumulativePercent;
    return v;
  });

  return buckets;
};

const useQoERanges = ({ option }: { option: QoERangeOptions }) => {
  const { apiKey, from, to, filters } = option;
  const [loading, setLoading] = useState(false);

  const [displayedViewerStats, setDisplayedViewerStats] = useState<{
    current: number;
    max: number;
    startUpDelay: [];
    startUpDelayMedian: number;
  }>({
    current: 0,
    max: 0,
    startUpDelay: [],
    startUpDelayMedian: 0,
  });

  useEffect(() => {
    setLoading(true);
    loadQoEQoSRanges({
      apiKey,
      metric: 'qoeStartupDelay',
      from,
      to,
      ranges: [
        { from: 0, to: 1000 },
        { from: 1000, to: 4000 },
        { from: 4000, to: 10000 },
      ],
      filters,
    }).then((v) => {
      if (v) {
        setLoading(false);
        setDisplayedViewerStats({
          ...displayedViewerStats,
          startUpDelayMedian: v.medianValue.values['50.0'],
          startUpDelay: rangeBucketsToPercents(v.ranges).map((v: any, i: number) => {
            v.color = ['#C13D59', '#F8D119', '#08C388'].reverse()[i];
            v.key = `${v.key}ms`;
            return v;
          }),
        });
      }
    });
  }, [apiKey, from, to, filters, displayedViewerStats]);

  // console.info('...old data', displayedViewerStats)

  return {
    data: displayedViewerStats,
    loading,
  };
};

/**
 *
 * @param by browser, os, device
 * @returns
 */
export const useSessionBreakdown = ({ option, by, topN = 20 }: { option: BaseOption; by: string; topN?: number }) => {
  // const { apiKey, from, to, selectedFilters: filters } = useFilters();
  const { apiKey, from, to, filters } = option;

  const dayBegin = dayjs(from).startOf('day').toDate();
  const dayEnd = dayjs(to).endOf('day').toDate();

  const { isLoading, data, error } = useQuery(['ua-table-data', by, apiKey, dayBegin, dayEnd, filters], () =>
    requestV3
      .get(`/sessions/breakdown/${by}`, {
        params: { apikey: [apiKey], s: dayBegin, e: dayEnd, top_n: topN },
      })
      .then((res) => {
        const { data: _d } = res;
        const totalViews = _.sum(_d.map((v) => v.views));
        const pieData = _d.map((v) => ({ value: v.views, name: v.key, percent: 100 * (v.views / totalViews) }));
        return pieData;
      })
  );

  return { isLoading, data, error };
};

export const useContentData = ({ option }: { option: BaseOption }) => {
  const { apiKey, from, to, filters } = option;
  const dayBegin = dayjs(from).startOf('day').toDate();
  const dayEnd = dayjs(to).endOf('day').toDate();

  const { isLoading, data, error } = useQuery(['fetch-content-stats', apiKey, dayBegin, dayEnd, filters], () =>
    requestV3
      .get(`/sessions/breakdown/content`, {
        params: filtersToParams({
          apiKey,
          from,
          to,
          filters: {
            o: filters.origins,
            c: filters.content,
          },
        }),
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      })
      .then((v) => {
        return v.data.map((o) => {
          return { key: o.key, url: o.key, ...o, users: o.views, visits: o.views, v2v: [o.cdn, o.v2v] };
        });
      })
  );

  return { isLoading, data, error };
};

export const useViewsByDayOfData = ({ option, type }: { option: BaseOption; type: 'weekday' | 'dayofmonth' }) => {
  const { apiKey, from, to, filters } = option;
  const [byWeekDayData, setByWeekDayData] = useState<any>();

  const [totalViews, setTotalViews] = useState(0);

  const { isLoading, data, error } = useQuery(['views-by-day-of', type, apiKey, from, to, filters], () =>
    requestV3.get(`/timeseries/usage/${type}`, {
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      params: filtersToParams({
        apiKey,
        from,
        to,
        filters: {
          o: filters?.origins,
          c: filters?.content,
        },
      }),
    })
  );

  useEffect(() => {
    if (data) {
      setByWeekDayData(
        data.data.map((d) => ({
          x: weekDayMapping[parseInt(d.key, 10) - 1],
          y: d.views,
        }))
      );
      setTotalViews(_.sum(data.data.map((d) => d.views)));
    }
  }, [data, isLoading]);

  return { byWeekDayData, totalViews, isLoading, error };
};
const ranges = [
  { from: 0, to: 600000 },
  { from: 600000, to: 1000000 },
  { from: 1000000, to: 30000000 },
];

export const useMapData = ({ option }: { option: BaseOption }) => {
  const { apiKey, from, to, filters } = option;
  const [mapData, setMapData] = useState<any>();

  useEffect(() => {
    worldGeoJsonWithStats({ apiKey, ranges, from, to, filters }).then((x) => {
      setMapData(x);
    });
  }, [apiKey, from, to, filters]);

  return mapData;
};

export const useWatchingTimeData = () => {
  const [watchingTimeData, setWatchingTimeData] = useState<any>();
  const [avgWatchingTime, setAvgWatchingTime] = useState(0);
  const { apiKey, from, to, selectedFilters: filters } = useFilters();

  const { isLoading, data, error } = useQuery(['use-sessions-qoe-watching-time', apiKey, from, to, filters], () =>
    requestV3.get(`/sessions/qoe/watchingTime`, {
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      params: filtersToParams({
        apiKey,
        from,
        to,
        filters: {
          o: filters?.origins,
          c: filters?.content,
        },
      }),
    })
  );
  useEffect(() => {
    if (data) {
      setAvgWatchingTime(data.data.avgWatchingTime.value);
      setWatchingTimeData(
        data.data.watchingTimeRanges.buckets.map((d) => {
          return {
            x: `${formatDuration(d.from * 1000)}-${formatDuration(d.to * 1000)}`,
            y: d.doc_count, // one doc is one session
          };
        })
      );
    }
  }, [isLoading, data]);

  return { watchingTimeData, avgWatchingTime, isLoading, error };
};

export const useStartupDelayData = () => {
  const { apiKey, from, to, selectedFilters: filters } = useFilters();
  // const coreFilters = FilterDefExtractCore(filters);
  const [displayedViewerStats, setDisplayedViewerStats] = useState<{
    current: number;
    max: number;
    startUpDelay: [];
    startUpDelayMedian: number;
  }>({
    current: 0,
    max: 0,
    startUpDelay: [],
    startUpDelayMedian: 0,
  });

  const { isLoading, data, error } = useQuery(['use-sessions-qoe-startup-delay', apiKey, from, to, filters], () =>
    requestV3.get(`/sessions/qoe/startupDelay`, {
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      params: filtersToParams({
        apiKey,
        from,
        to,
        filters: {
          o: filters?.origins,
          c: filters?.content,
        },
      }),
    })
  );

  useEffect(() => {

    if (data) {
      setDisplayedViewerStats({
        max: 0,
        current: 0,
        startUpDelayMedian: data.data.percStartupDelay.values['50.0'],
        startUpDelay: rangeBucketsToPercentsDocCount(data.data.startupDelayRanges).map((v, i: number) => {
          v.color = ['#C13D59', '#F8D119', '#08C388'].reverse()[i];
          v.key = `${v.key}ms`;
          return v;
        }),
      });
    }
  }, [data]);
  return { data: displayedViewerStats, isLoading, error };
};

export const useRebufferingTimeData = () => {
  const { apiKey, from, to, selectedFilters: filters } = useFilters();
  // const coreFilters = FilterDefExtractCore(filters);
  const [rebufferingTimeData, setRebufferingTimeQoEData] = useState<any>();
  const [avgRebufferingTime, setAvgRebufferingTime] = useState(0);

  const { isLoading, data, error } = useQuery(['use-sessions-qoe-rebuf-time', apiKey, from, to, filters], () =>
    requestV3.get(`/sessions/qoe/rebufTime`, {
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      params: filtersToParams({
        apiKey,
        from,
        to,
        filters: {
          o: filters?.origins,
          c: filters?.content,
        },
      }),
    })
  );

  useEffect(() => {
    if (data) {
      setAvgRebufferingTime(data.data.avgRebufTime.value);
      setRebufferingTimeQoEData(
        data.data.rebufTimeRanges.buckets.map((d) => {
          return {
            x: `${formatDuration(d.from * 1000)}-${formatDuration(d.to * 1000)}`,
            y: d.doc_count,
          };
        })
      );
    }
  }, [isLoading, data]);

  return { rebufferingTimeData, avgRebufferingTime, error };
};

const rangeBucketsToPercentsBreakdown = (v: any) => {
  let sumViews = 0;
  const cobj = {
    content: v.key,
    configuration: v.qoeRanges.buckets.map((m: any) => {
      sumViews += m.doc_count;
      return { key: m.key, cnt: m.doc_count };
    }),
  };
  let cumulativePercent = 0;
  cobj.configuration = cobj.configuration.map((v: any) => {
    v.percent = (v.cnt * 100) / sumViews;
    v.fromPercent = cumulativePercent;
    cumulativePercent += Number(v.percent.toFixed(2));
    v.toPercent = cumulativePercent;
    return v;
  });
  return cobj;
};

export const useQoEQoSGroupByData = ({
  option,
  groupby,
  metric,
  ranges,
}: {
  option: BaseOption;
  groupby: string;
  metric: string;
  ranges: string;
}) => {
  const { apiKey, from, to, filters } = option;
  const [qoeBreakdownData, setQoEBreakdownData] = useState([]);
  const { isLoading, data, error } = useQuery(
    ['use-sessions-group-by-range', apiKey, from, to, filters, groupby, metric],
    () =>
      requestV3.get(`/sessions/groupby/${groupby}/${metric}`, {
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        params: {
          ranges, ...filtersToParams({
            apiKey,
            from,
            to,
            filters: {
              o: filters?.origins,
              c: filters?.content,
            },
          }),
        },
      })
  );

  useEffect(() => {
    if (data) {
      setQoEBreakdownData(data?.data.map(rangeBucketsToPercentsBreakdown));
    }
  }, [data]);

  return { isLoading, qoeBreakdownData, error };
};

export { useQoERanges };
