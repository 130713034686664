import { FeatureCollection } from 'geojson';
import qs from 'qs';
import * as topojson from 'topojson';
import { FilterDef } from '..';
import { requestV3 } from '../requests';
import { filtersToParams } from '../utils';

const rangeBucketsToPercentsAPI = (v: any) => {
  let sumViews = 0;
  let configuration = v.map((m: any) => {
    sumViews += m.views;
    return { key: m.key, cnt: m.views };
  });
  let cumulativePercent = 0;
  configuration = configuration.map((x: any) => {
    const percent = (x.cnt * 100) / sumViews;
    const fromPercent = cumulativePercent;
    cumulativePercent += Number(percent.toFixed(2));
    const toPercent = cumulativePercent;
    return { ...x, percent, fromPercent, toPercent };
  });
  return configuration;
};

/**
 * The functions for loading the geo location stuffs
 *
 */
export async function loadCountryStats({
  apiKey,
  from = 'now-15h/h',
  to = 'now',
  ranges,
  filters,
}: {
  apiKey: string;
  from?: string;
  to?: string;
  ranges: { from: number; to: number }[];
  filters?: FilterDef;
}) {
  return requestV3
    .get('/sessions/breakdown/country', {
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      params: {
        top_n: 1000,
        ...filtersToParams({
          apiKey,
          from,
          to,
          filters: {
            o: filters?.origins,
            c: filters?.content,
          },
        }),
      },
    })
    .then((v) => {
      return Object.fromEntries(
        v.data.map((doc: any) => {
          return [
            doc.key,
            {
              ...doc,
              total: doc.cdn + doc.v2v,
              avgChunkSize: doc.chunkSize['50.0'],
              chunkSize: rangeBucketsToPercentsAPI(doc.chunkSizeRanges.buckets),
            },
          ];
        })
      );
    })
    .then((value: any) => {
      return value;
    });
}

// This function will turn the object of
// {key: "0.0-500.0", from: 0, to: 500, doc_count: 0}
// into {key: "500.0-1000.0", fromPercent: 50, toPercent: 100, percent: 50, cnt: x}
// this is very useful for debugging the qoe

export async function worldGeoJsonWithStats({
  apiKey,
  ranges,
  from = 'now-15h/h',
  to = 'now',
  filters,
}: {
  apiKey: string;
  from?: string;
  to?: string;
  ranges: { from: number; to: number }[];
  filters?: FilterDef;
}) {
  // const c = await axios.get('https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json');
  const topo = require('assets/world-countries.json');
  const stats = await loadCountryStats({ apiKey, ranges, from, to, filters });
  const geo: FeatureCollection = topojson.feature(topo, topo.objects.countries1) as any;

  geo.features.map((v) => {
    const matched = stats[v.properties ? v.properties['Alpha-2'] : 'x'];
    if (matched) {
      v.properties = { ...v.properties, ...matched };
    } else {
      v.properties = {
        ...v.properties,
        ...{ views: 0, cdn: 0, v2v: 0, chunkSize: [] },
      };
    }
    return v;
  });

  return geo;
}
