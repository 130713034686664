import React from 'react';
import { css } from '@emotion/react';
import { Menu } from 'components/antd';
import { EText } from 'components/base';
import { useQueryState } from 'contexts/router';
import { useTranslate } from 'utils/intl';
import { useAuth } from 'contexts/auth';

interface TopMenuProps {
  key: string;
  title: string;
  component: React.ReactNode;
  authorize?: string[];
  icon?: React.ReactNode;
}

function NotFound() {
  return <EText>Not Found</EText>;
}

function TopTabsLayout({
  tabs: _tabs,
  defaultTabKey,
  topBorder,
}: {
  tabs: TopMenuProps[];
  defaultTabKey?: string;
  topBorder?: boolean;
}) {
  const { checkAccess } = useAuth();
  const tabs = _tabs.filter((_tab) => checkAccess(_tab.authorize));
  const { query, updateQuery } = useQueryState({ tab: defaultTabKey ?? tabs[0]?.key });
  const [t] = useTranslate();
  const selected = tabs.find(({ key }) => query.tab === key);

  let component = selected?.component ?? tabs[0]?.component ?? ((<NotFound />) as any);
  return (
    <>
      <div
        css={(theme) => css`
          background: #fff;
          ${topBorder ? 'border-top: 1px solid ' + theme.colors.headerBorder + ';' : ''}
          border-bottom: 1px solid ${theme.colors.headerBorder};
          display: flex;
        `}
      >
        <Menu
          css={css`
            border: 0;
            max-width: 1200px;
            margin: auto;
            flex: 1;
          `}
          selectedKeys={[query.tab]}
          onSelect={({ key }) => updateQuery({ tab: String(key) })}
          mode="horizontal"
        >
          {tabs.map(({ key, title, icon }) => (
            <Menu.Item key={key} icon={icon}>
              {t(title)}
            </Menu.Item>
          ))}
        </Menu>
      </div>
      {component}
    </>
  );
}

export default TopTabsLayout;
