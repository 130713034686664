import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Col, Divider, Row } from 'antd';
import PercentBar from 'components/visualizations/PercentBar';
import PieChart from 'components/visualizations/Piechart';
import TimeseriesChart from 'components/visualizations/TimeseriesChart';
import { ChartConfig } from 'containers/custom-dashboard/visualizations/base';
import { useFilters } from 'contexts/filters';
import dayjs from 'dayjs';
import { compactInteger, fileSize, intComma } from 'humanize-plus';
import ChartCardLayout from 'layouts/ChartCardLayout';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslate } from 'utils/intl';
import Notification from 'utils/notification';
import realtimeStyles from './styles/realtimePage.module.less';
import { formatDuration } from './utils/functions';
import { useAPITimeseries, useStartupDelayData } from './utils/hooks';
import { withLayout } from './utils/withLayout';

const colorsSettings = {
  cdn: '#8dc0e6',
  v2v: 'rgb(253, 212, 0)',
};

const aes = {
  x: 'date',
  ySeries: [{ y: 'views', name: 'concurrent viewers', fillColor: colorsSettings.cdn, lineColor: colorsSettings.cdn }],
  // yMin: 0,

  tooltipFormater: (s: any, datum: any) => {
    if (!datum) {
      return 'No data';
    }
    return `${s.name}: ${compactInteger(datum[s.y])} viewers <br/>`;
  },
  showGrid: true,
  tooltipHeader: 'yo',
};

const aesData = {
  x: 'date',
  yMin: 0,
  tooltipHeader: 'Bandwidth',
  ySeries: [
    { y: 'totalBandwidth', name: 'Total Bandwidth', lineColor: 'rgba(50, 50, 50)', fillColor: 'none' },
    { y: 'cdnBandwidth', name: 'CDN bandwidth', lineColor: colorsSettings.cdn, fillColor: colorsSettings.cdn },
    { y: 'v2vBandwidth', name: 'V2V bandwidth', lineColor: colorsSettings.v2v, fillColor: colorsSettings.v2v },
    // faad14
  ],
  showGrid: true,
  tooltipFormater: (s: any, datum: any) => {
    if (!datum) {
      return 'No data';
    }
    return `<div style="color: ${s.fillColor}">${s.name}: ${fileSize(datum[s.y] * 8).replace('B', 'bit')}/s </div>`;
  },
  yticksFormater: (v: number) => {
    return fileSize(v * 8).replace('B', 'bit') + '/s';
  },
};

export const leftCol = {
  xs: { span: 24, order: 2 },
  sm: { span: 24, order: 2 },
  md: { span: 16, order: 1 },
  lg: { span: 18, order: 1 },
  xl: { span: 18, order: 1 },
};
export const rightCol = {
  xs: { span: 24, order: 1 },
  sm: { span: 24, order: 1 },
  md: { span: 8, order: 2 },
  lg: { span: 6, order: 2 },
  xl: { span: 6, order: 2 },
};

const RealtimePage: React.FC<any> = () => {
  const [t] = useTranslate();
  const { apiKey, from, to, realTimeLastNMinutes, selectedFilters: filters, updateDate } = useFilters();
  const isRealtime = from === 'now';

  const { data: usageData, aggregation: usageAggregation } = useAPITimeseries({
    option: { apiKey, from, to, filters, lastNMinutes: realTimeLastNMinutes },
  });

  const { data: displayedViewerStats } = useStartupDelayData();

  // const { data: displayedViewerStats } = useQoERanges({
  //   option: { type: 'qoeStartupDelay', apiKey, from, to, filters },
  // });

  // const { from, to, updateDate, dateOptionKey } = useFilters();
  const [dataPieData, setDataPieData] = useState<{ key: string; value: number; color: string }[]>([]);

  // calculate the pie data
  useEffect(() => {
    setDataPieData([
      {
        key: 'v2v',
        value: usageAggregation.data.v2v,
        color: colorsSettings.v2v,
      },
      { key: 'cdn', value: usageAggregation.data.cdn, color: colorsSettings.cdn },
    ]);
  }, [usageAggregation.data]);

  const handleScaleTime = useCallback(
    (start: any, end: any) => {
      if (!dayjs(start).isValid() || !dayjs(end).isValid()) {
        console.warn('The time is not valid', start, end);
        return;
      }
      if (dayjs(end).diff(start, 'minute') < 10) {
        Notification.warning('Duration selected is less than 10 minutes');
      }
      const startDate = dayjs(start).toDate();
      const endDate = dayjs(end).toDate();
      const dateOptionKey = `${startDate.toISOString()}##${endDate.toISOString()}`;
      updateDate({ start: startDate, end: endDate, dateOptionKey });
    },
    [updateDate]
  );

  // function renderRefreshTag() {
  //   return <div></div>;
  // }

  return (
    <>
      <Row gutter={[19, 20]} justify="center">
        <Col {...leftCol}>
          <ChartCardLayout title={t('analytics.views')}>
            {usageData.length === 0 ? (
              <div style={{ height: 300 }}>No Data</div>
            ) : (
              <TimeseriesChart
                data={usageData}
                aes={aes}
                chartConfig={new ChartConfig().setSize(800, 300)}
                onTimerangeChange={handleScaleTime}
              />
            )}
          </ChartCardLayout>
        </Col>
        <Col {...rightCol}>
          <ChartCardLayout title={t('analytics.viewerstats')}>
            <div className={realtimeStyles.noDataPlaceholder}>
              {isRealtime ? 'Current Viewers' : 'Max viewers'}{' '}
              {isRealtime && usageData.length !== 0 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null}
              <br />
              <div className={realtimeStyles.viewerStatsLargeNumber}>
                {isRealtime
                  ? intComma(usageData[usageData.length - 1]?.views || 0)
                  : usageAggregation?.maxViewers.views}
              </div>
              <Divider className={realtimeStyles.dividerSmaller} />
              {!!displayedViewerStats &&
                displayedViewerStats.startUpDelayMedian &&
                displayedViewerStats.startUpDelay ? (
                <>
                  Startup delay
                  <div>
                    <div className={realtimeStyles.viewerStatsLargeNumber}>
                      {formatDuration(displayedViewerStats.startUpDelayMedian)}
                    </div>
                    <PercentBar width={200} height={30} ticks={5} data={displayedViewerStats.startUpDelay} />
                  </div>
                </>
              ) : (
                <div style={{ height: 30 }}>No startup delay data</div>
              )}
              <Divider className={realtimeStyles.dividerSmaller} />
              <div>
                <div>
                  {' '}
                  <Button
                    type="link"
                    onClick={() => {
                      // dispatch(
                      //   setSelectedTimeRangeKey(
                      //     parseToCustomisedDateStr({ start: dayjs().add(-4, 'hour'), end: dayjs() })
                      //   )
                      // );
                    }}
                  >
                    Back to realtime
                  </Button>
                </div>
              </div>
            </div>
          </ChartCardLayout>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }} gutter={[19, 20]} justify="center">
        <Col {...leftCol}>
          <ChartCardLayout title={t('analytics.bandwidth')}>
            {usageData.length === 0 ? (
              <div className={realtimeStyles.noDataPlaceholder}>No Data</div>
            ) : (
              <TimeseriesChart
                data={usageData}
                aes={aesData}
                chartConfig={new ChartConfig().setSize(800, 300)}
                onTimerangeChange={handleScaleTime}
              />
            )}
          </ChartCardLayout>
        </Col>
        <Col {...rightCol}>
          <ChartCardLayout title={t('analytics.v2vpercent')}>
            <PieChart
              style={{ height: 200, display: 'flex', alignItems: 'center' }}
              aes={{
                innerRadius: 40,
                outerRadius: 60,
                centerTxt: `V2V: ${(usageAggregation.data.percent * 100).toFixed(1)}%`,
                tooltipFormater: (d: any) => {
                  return `${(d.data as any).key}: ${fileSize((d.data as any).value)}<br/>`;
                },
              }}
              data={dataPieData}
            />
            <div style={{ height: 100 }}>
              During the period, <b>{fileSize(usageAggregation.data.total)}</b> has been consumed, in which{' '}
              <b>
                {fileSize(usageAggregation.data.v2v)} ({(usageAggregation.data.percent * 100).toFixed(1)}%)
              </b>{' '}
              is offloaded by EasyBroadcast v2v tech.
            </div>
          </ChartCardLayout>
        </Col>
      </Row>
    </>
  );
};

export default withLayout(RealtimePage);
