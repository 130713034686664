// This file contains the data processing

export function humanReadableSize(bytes: number) {
  if (bytes === 0) {
    return '0.00 B';
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, e)).toFixed(1) + ' ' + ' KMGTP'.charAt(e) + 'B';
}

export function readableSimpleMetricName(metricName: string) {
  switch (metricName) {
    case 'uniq_views':
      return 'visits';
    case 'pv_cnt':
      return 'visits';
    case 'bytes_cdn':
    case 'sum_bytes_cdn':
      return 'cdn';
    case 'bytes_p2p':
    case 'sum_bytes_p2p':
      return 'v2v';
    case 'cdn_avg_chunk_size':
      return 'Avg. Chunk size';
    default:
      console.warn(metricName, ' is not handled by this function');
      return metricName;
  }
}

export interface ImporvementStats {
  pv_cnt: {
    improvementLastWeek: Number;
    thisWeekSum: Number;
    lastWeekSum: Number;
  };
}
