import React from 'react';
import { VizContainerProps } from '../../components/VizContainer';
export * from './helpers';

type supportedViewGraphs = 'view_bar_dow' | 'view_bar_hod' | 'view_data_bar_dow';
type supportedTimeGraphs = 'timeseries';
type realTimeGraph = 'realtime';
type supportedCalendarGraphs = 'calendar';

type supportedUaGraphs = 'ua_pie_os' | 'ua_pie_browser' | 'ua_tab_os' | 'ua_tab_browser' | 'ua_pie_isp' | 'ua_tab_isp';

type supportedContentGraphs = 'content';

type supportedQoEGraphs = 'qoe';

type geoGraph = 'geo';
type supportedGraphs =
  | supportedViewGraphs
  | supportedUaGraphs
  | supportedContentGraphs
  | supportedTimeGraphs
  | supportedCalendarGraphs
  | supportedQoEGraphs
  | geoGraph
  | realTimeGraph;

export interface Filters {
  origin?: string[];
  content?: string[];
  apiKey: string[];
  start: string;
  end: string;
}

export interface RenderConfig {
  dataUnit?: 'MB' | 'GB';
}

export interface GraphConfig<T> {
  // graphID is use to switch to the correct graph
  graphID: supportedGraphs;
  // data fetch config
  filters: Filters;
  // the config for rendering
  render: RenderConfig & T;
}

export interface VisualizationProps<T = {}, D = any> {
  config: GraphConfig<T>;
  data?: D;
  width?: number;
  height?: number;
}

export const BaseVisualization: React.FC<VisualizationProps<any>> = (props: any) => {
  return <div>Hello, I am a visualization</div>;
};

export interface PiechartSliceConfig {
  fromPercent: number;
  toPercent: number;
  percent: number;
  cnt: number;
  color: string;
  key: string;
}

export class PaddingConfig {
  left: number = 10;
  right: number = 10;
  top: number = 10;
  bottom: number = 10;
}
export class ChartConfig {
  paddings = new PaddingConfig();
  height = 185;
  width = 300;

  public xExtend = () => {
    return [this.paddings.left, this.width - this.paddings.right];
  };
  public yExtend = () => {
    return [this.paddings.top, this.height - this.paddings.bottom - this.paddings.top];
  };

  public setSize(width: number, height: number): ChartConfig {
    this.height = height;
    this.width = width;
    return this;
  }
}

export interface OptionGenArgs {
  data: any[];
  optionFragGen?: (data: any[], config?: any) => any;
  sorter?: (a, b) => any;
  keyConverter?: (key: string) => any;
}
export interface BaseChartBundle<T, D = any> {
  fetchConfig: VizContainerProps<T>;
  chartRenderer: React.FC<VisualizationProps<T, D>>;
}
