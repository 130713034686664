import { css } from '@emotion/react';
import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import TagGroup from 'components/antd/TagGroup';
import { EButton, EText } from 'components/base';
import { PasswordFields } from 'components/misc/PasswordFields';
import { useAuth } from 'contexts/auth';
import CardPageLayout from 'layouts/CardPageLayout';
import TopTabsLayout from 'layouts/TopTabsLayout';
import React, { useEffect } from 'react';
import { resetPassword, updateProfile } from 'services/auth';
import { useRequestWithLoading } from 'services/hook';
import { ACCESS_FEATURES, COUNTRY_LIST } from 'utils/enums';
import { LanguagesOptions, useTranslate } from 'utils/intl';
import { ModalNotification } from 'utils/notification';

const styles = {
  form: css`
    max-width: 600px;
    padding-left: 20px;
  `,
};

function Profile() {
  const { user, updateUserProfile } = useAuth();
  const [form] = FormWrap.useForm();
  const [loading, _updateProfile] = useRequestWithLoading(updateProfile);

  useEffect(() => {
    if (user.profile && form) {
      const values = user.profile ?? {};
      form.setFieldsValue({ ...values, country: values.region });
    }
  }, [user, form]);

  const onFinish = async (values) => {
    const res = await _updateProfile({ ...values, region: values.country });
    updateUserProfile(res);
  };

  return (
    <CardPageLayout>
      <FormWrap css={styles.form} name="user_profile" form={form} onFinish={onFinish}>
        <FormItemWrap label="auth.email">{`${user.email}`}</FormItemWrap>
        <FormItemWrap label="auth.username">{`${user.username ?? 'unset'}`}</FormItemWrap>
        <FormItemWrap.Input required={false} label="auth.firstName" name="firstName" />
        <FormItemWrap.Input required={false} label="auth.lastName" name="lastName" />
        <FormItemWrap.Input required={false} label="page.account.avatar" name="avatarUrl" />
        <FormItemWrap.Select
          autoComplete="nope"
          required={false}
          label="page.account.country"
          name="country"
          options={COUNTRY_LIST.map((c) => ({ label: c.emoji + ' ' + c.name, value: c.code }))}
        />
        <FormItemWrap.RadioGroup label="page.account.language" name="lang" options={LanguagesOptions} />
        <FormItemWrap>
          <EButton loading={loading} htmlType="submit" _type="solid" />
        </FormItemWrap>
      </FormWrap>
    </CardPageLayout>
  );
}

function ChangePassword() {
  const { user, logout } = useAuth();
  const [t] = useTranslate();
  const [form] = FormWrap.useForm();
  const [loading, _updatePwd] = useRequestWithLoading(resetPassword);
  const onFinish = async (values) => {
    await _updatePwd(values);
    ModalNotification.success(['page.account.changePwdSuccess', 'page.account.loginAgain'], () => logout());
  };
  return (
    <CardPageLayout>
      <FormWrap css={styles.form} name="change_pwd" form={form} onFinish={onFinish}>
        <FormItemWrap.Input type="password" label="page.account.currentPwd" name="currentPassword" />
        <PasswordFields
          password={{
            label: 'page.account.newPwd',
            name: 'newPassword',
            rules: [
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('currentPassword') !== value) {
                    return Promise.resolve();
                  }
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject(t('page.account.samePwd'));
                },
              }),
            ],
          }}
        />
        <FormItemWrap>
          <EButton loading={loading} htmlType="submit" _type="solid" />
        </FormItemWrap>
      </FormWrap>
    </CardPageLayout>
  );
}

function LicenseInfo() {
  const { license = {} } = useAuth();
  const [form] = FormWrap.useForm();
  return (
    <CardPageLayout>
      <FormWrap
        css={css`
          max-width: 600px;
          padding-left: 20px;
        `}
        name="license_setting"
        form={form}
      >
        <FormItemWrap label="page.account.licenseName">{`${license.name}`}</FormItemWrap>
        <FormItemWrap label="page.account.licenseFeatures">
          <TagGroup options={license.features} />
        </FormItemWrap>
        <FormItemWrap label="page.account.maxUsers">{license.maxUsers}</FormItemWrap>
        <FormItemWrap label="page.account.maxApiKeys">{license.maxApiKeys}</FormItemWrap>
        <FormItemWrap label="column.apikeys">
          {license.apiKeys.map((e) => (
            <EText key={e.key} type="paragraph">
              <EText strong>{e.name}</EText>
              {` ( ${e.key} )`}
            </EText>
          ))}
        </FormItemWrap>
      </FormWrap>
    </CardPageLayout>
  );
}

export default function () {
  const tabs = [
    {
      key: 'profile',
      title: 'page.account.profile',
      component: <Profile />,
    },
    {
      key: 'password',
      title: 'page.account.changePwd',
      component: <ChangePassword />,
    },
    {
      key: 'license',
      title: 'page.account.licenseInfo',
      component: <LicenseInfo />,
      authorize: [ACCESS_FEATURES.LICENSE_MANAGE],
    },
  ];
  return <TopTabsLayout tabs={tabs} />;
}
