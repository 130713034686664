import React, { useEffect } from 'react';
import { IntlProvider as ReactIntlProvider, useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';
import Languages, { LanguageType } from './lang';
export type { LanguageType } from './lang';

export const LanguagesOptions = Object.entries(Languages).map(([key, value]): {
  label: string;
  value: LanguageType;
} => ({ label: value.name, value: key as LanguageType }));

export const DEFAULT_LANGUAGE = LanguagesOptions[0].value;

export function flattenMessages(nestedMessages: any, prefix = '') {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

export const Messages: Record<LanguageType, any> = LanguagesOptions.reduce(
  (prev, { value }) => ({
    ...prev,
    [value]: flattenMessages(Languages[value].messages),
  }),
  {} as any
);

export interface PropsWithIntl {
  t: (id: string, values?: Record<string, any>) => string;
  intl: IntlShape;
}

export function withIntl<T extends PropsWithIntl = PropsWithIntl>(
  WrappedComponent: React.ComponentType<any>
): React.ComponentType<any> {
  return function (props) {
    const intl = useIntl();
    function translate(id: string, values?: Record<string, any>) {
      // use english as default message
      return intl.formatMessage({ id, defaultMessage: id }, values);
    }
    return <WrappedComponent {...props} t={translate} intl={intl} />;
  };
}

export function useTranslate(): [(id: string, values?: Record<string, any>) => string, IntlShape] {
  const intl = useIntl();
  function translate(id: string = 'unset', values?: Record<string, any>) {
    // use english as default message
    return intl.formatMessage({ id, defaultMessage: id }, values);
  }
  return [translate, intl];
}

export function useTranslateWithPrefix(
  prefix: string
): [(id: string, values?: Record<string, any>) => string, IntlShape] {
  const intl = useIntl();
  function translate(_id: string = 'unset', values?: Record<string, any>) {
    let id = `${prefix}.${_id}`;
    if (_id.includes('.')) {
      id = _id;
    }
    return intl.formatMessage({ id, defaultMessage: id }, values);
  }
  return [translate, intl];
}

let intlInstance: IntlShape | null = null;

const IntlChildrenWrapper = withIntl(({ children, intl }: any) => {
  intlInstance = intl;
  return children;
});

export function getIntlInstance() {
  return intlInstance;
}

export function IntlProvider(props: { children: React.ReactNode; language?: LanguageType }) {
  const { children, language = 'en' } = props;
  return (
    <ReactIntlProvider locale={language} messages={Messages[language]}>
      <IntlChildrenWrapper>{children}</IntlChildrenWrapper>
    </ReactIntlProvider>
  );
}
