import { css, jsx } from '@emotion/react';
import { ReactComponent as EBIcon } from 'assets/eb_icon_long.svg';
import { ReactComponent as EBIconSquare } from 'assets/eb_icon_square.svg';
import React from 'react';
import { SizeMe } from 'react-sizeme';
import { useLayout } from '../model';

export default function MenuTopEBIcon() {
  const { menuCollapsed, mobileMode } = useLayout();
  // const icon = jsx(mobileMode || !menuCollapsed ? EBIcon : EBIconSquare, {
  //   css: css`
  //     height: 100%;
  //     width: 100%;
  //     max-height: 64px;
  //   `,
  // });

  const square = (
    <EBIconSquare
      css={css`
        height: 100%;
        max-height: 40px;
        width: 40px;
      `}
    />
  );

  const full = (
    <EBIcon
      css={css`
        height: 100%;
        max-height: 40px;
        width: 176px;
      `}
    />
  );

  return (
    <div
      css={css`
        height: 64px;
        background: #fff;
        padding: 12px;
        transition: 'all 0.2s';
        // display: flex;
        // justify-content: center;
        // align-items: center;
      `}
    >
      {mobileMode || !menuCollapsed ? full : square}
    </div>
  );

  return (
    <SizeMe>
      {({ size }) => {
        console.log(size.width);

        const icon = jsx(mobileMode || !menuCollapsed ? EBIcon : EBIconSquare, {
          css: css`
            height: 90%;
            max-height: 40px;
          `,
        });
        // const icon = jsx((size.width ?? 200) > 70 ? EBIcon : EBIconSquare, {
        //   css: css`
        //     height: 90%;
        //     // width: 100%;
        //     max-height: 64px;
        //   `,
        // });
        return (
          <div
            css={css`
              height: 64px;
              background: #fff;
              padding: 12px;
              transition: 'all 0.2s';
            `}
          >
            {icon}
          </div>
        );
      }}
    </SizeMe>
  );
}
