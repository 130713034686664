import React from 'react';
import { css } from '@emotion/react';
import { Text } from 'components/base';

const styles: any = {
  outer: css`
    padding: 20px;
  `,
  inner: (props) => css`
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    min-height: 400px;
    background: ${props.colors.cardBackground};
  `,
  center: css`
    max-width: 1200px;
  `,
};

function CardPageLayout(props: any) {
  const { children = null, center = true, description = null } = props;
  return (
    <div css={styles.outer}>
      <div css={[styles.inner, center ? styles.center : null]}>{children}</div>
    </div>
  );
}

function Title(props) {
  if (!props.children) return null;
  return <Text type="title">{props.children}</Text>;
}

CardPageLayout.Title = Title;

export default CardPageLayout;
