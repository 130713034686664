import React, { useCallback, useEffect, useState } from 'react';
import { Tag, Button } from 'antd';
import { css } from '@emotion/react';

interface TagCheckboxProps {
  options?: (
    | { value?: string; label?: string; disabled?: boolean; id?: string; _id?: string; name?: string }
    | string
  )[];
  value?: string[];
  initialValue?: string[];
  onChange?: (any) => void;
  disabled?: boolean;
}

function TagCheckbox(props: TagCheckboxProps) {
  const {
    options: _options = [],
    value: _value,
    initialValue = [],
    onChange: _onChange,
    disabled: disabledAll = false,
  } = props;

  const options = _options.map((o) =>
    typeof o === 'string' ? { label: o, value: o } : { ...o, value: o._id ?? o.id ?? o.value, label: o.name ?? o.label }
  );

  const [selected, setSelected] = useState<any>(initialValue);

  const values = _value ?? selected;

  const triggerChange = (changedValue: any[]) => {
    _onChange?.(changedValue);
  };

  const onChange = (v, prev) => {
    const prevSelected = prev.indexOf(v) > -1;
    let res = [...prev];
    if (prevSelected) {
      res = res.filter((p) => p !== v);
    } else {
      res = [...res, v];
    }
    setSelected(res);
    triggerChange(res);
  };

  return (
    <>
      {options.map(({ value: v, label: l, disabled = false }) => (
        <Button
          key={v}
          onClick={() => onChange(v, values)}
          type={values.indexOf(v) > -1 ? 'primary' : 'default'}
          disabled={disabledAll || disabled}
          size="small"
          css={css`
            margin: 0 4px 4px 0;
          `}
          
        >
          {l}
        </Button>
      ))}
      {/* {options.map(({ value: v, label: l }) =>
        _disabledProps ? (
          <Button size="small" disabled>
            {l}
          </Button>
        ) : (
          <Tag.CheckableTag
            css={(theme) => css`
              margin-bottom: 4px;
              border: ${theme.colors.border} 0.5px solid;
            `}
            key={v}
            checked={values.indexOf(v) > -1}
            onChange={() => onChange(v, values)}
          >
            {l}
          </Tag.CheckableTag>
        )
      )} */}
    </>
  );
}

export default TagCheckbox;
