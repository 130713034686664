import { css } from '@emotion/react';
import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import { TableModalTemplate } from 'components/misc/TableTemplate';
import React from 'react';
import { DefaultService } from '../../services';

const modelParams = {
  name: 'column.apikey',
  service: DefaultService.apikeys,
  searchKeys: ['name', 'key'],
};

const tableColumns = [
  {
    title: 'column.name',
    dataIndex: 'name',
    clickToEdit: true,
    width: '30%',
  },
  {
    title: 'column.key',
    // the table use key to identifier key, all "key" property in table will be changed to "originalKey"
    dataIndex: 'originalKey',
  },
];

function ApiKeyForm(props) {
  return (
    <FormWrap
      css={css`
        min-height: 150px;
      `}
      {...props}
    >
      <FormItemWrap.Input label="column.display" name="name" />
      {/* <FormItemWrap.Input disabled label="column.key" name="key" required={false} tooltip="apikey.uniqueKeyTip" /> */}
    </FormWrap>
  );
}

export default function () {
  const model = TableModalTemplate.useModel(modelParams);
  return (
    <TableModalTemplate
      {...model}
      baseColumns={tableColumns}
      formComponent={<ApiKeyForm form={model.form} name={modelParams.name} />}
    />
  );
}
