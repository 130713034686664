import { CalendarOutlined } from '@ant-design/icons';
import css from '@emotion/css';
import { Avatar, Checkbox, Col, Row, Space, Tooltip } from 'antd';
import { Tag } from 'components/antd';
import FormWrap, { FormItemWrap, FormSelect } from 'components/antd/FormWrap';
import Select from 'components/antd/Select';
import { EButton, EText } from 'components/base';
import DatePicker from 'components/input/DatePicker';
import { CubeOutlined } from 'components/misc/GradienIcon';
import { useFilters } from 'contexts/filters';
import dayjs from 'dayjs';
import HeaderContentLayout from 'layouts/HeaderContentLayout';
import React, { useCallback, useEffect, useState } from 'react';
import { AuthService } from 'services';
import { parseTimeRangeKey, timeRangeOptions } from 'utils/days';

function getTimeOptionText(timeRangeKey: string) {
  const option = timeRangeOptions.find(({ key }) => key === timeRangeKey);
  if (option) return option.key;
  const [start, end] = timeRangeKey.split('##');
  if (start && end) {
    const format = 'DD/MM/YY HH:mm:ss';
    return [dayjs(start).format(format), dayjs(end).format(format)].join(' - ');
  }
  return timeRangeOptions[0].key;
}

function FilterSelect({ options = [], ...rest }) {
  if (options.length > 10)
    return (
      <FormSelect
        mode="multiple"
        options={options.map((o: any) => ({ value: o.value, label: o.label }))}
        css={css`
          max-width: 400px;
        `}
        {...rest}
      />
    );
  return <Checkbox.Group options={options} {...rest} />;
}

const formLayout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
  labelAlign: 'left',
};

function FilterPanel() {
  const { availableFilters, updateSelectedFilters, selectedFilters } = useFilters();
  const [form] = FormWrap.useForm();

  useEffect(() => {
    form.setFieldsValue(selectedFilters);
  }, [form, selectedFilters, selectedFilters.content, selectedFilters.origins]);

  return (
    <FormWrap
      name="filters"
      form={form}
      colon
      size="small"
      {...formLayout}
      css={css`
        padding: 12px 0;
      `}
      onFinish={(values) => {
        const res = Object.fromEntries(Object.entries(values).filter(([k, v]) => !!v));
        updateSelectedFilters(res);
      }}
    >
      <FormItemWrap name="origins" label="origins">
        <FilterSelect options={availableFilters.origins} />
      </FormItemWrap>
      <FormItemWrap name="content" label="channels">
        <FilterSelect
          options={
            availableFilters.content.length > 10
              ? availableFilters.content
              : availableFilters.content.map(({ value, label }) => ({
                value,
                label: (
                  <Tooltip title={value}>
                    <EText>{label}</EText>
                  </Tooltip>
                ),
              }))
          }
        />
      </FormItemWrap>
      <Space>
        <EButton _type="solid" size="small" htmlType="submit" text="Apply" />
        <EButton
          size="small"
          text="Reset"
          onClick={() => {
            updateSelectedFilters({}, false, true);
          }}
        />
      </Space>
    </FormWrap>
  );
}

function ApiKeySelect() {
  const { statApiKeys, currentApiKeyValue, selectApiKeys, updateApiKey } = useFilters();
  return (
    <Row
      align="middle"
      css={(theme) =>
        css`
          padding: 0 8px;
          border: 0.25px ${theme.colors.border} solid;
          width: 250px;
          height: 35px;
        `
      }
    >
      <Col>
        <Avatar
          css={css`
            transform: scale(0.85);
          `}
          size="small"
          icon={<CubeOutlined />}
        />
      </Col>
      <Col flex={1}>
        <Select
          bordered={false}
          style={{ width: '100%' }}
          showArrow
          options={statApiKeys}
          maxTagCount={0}
          maxTagTextLength={12}
          dropdownMatchSelectWidth={150}
          placeholder="apikeys"
          value={currentApiKeyValue?._id}
          onChange={updateApiKey}
        />
      </Col>
    </Row>
  );
}

function FilterHeader({ timeRangeStartIndex = [0] }: any) {
  // Get the from and to
  const { from, to, updateDate, dateOptionKey } = useFilters();

  const [moreVisible, setMoreVisible] = useState(false);

  const onRangeChange = useCallback(
    (params) => {
      let _timeRangeKey;

      // select option
      if (params.optionKey) _timeRangeKey = params.optionKey;

      // select range
      if (params.start && params.end) {
        _timeRangeKey = [params.start.toISOString(), params.end.toISOString()].join('##');
      }

      AuthService.updateState({ timeRangeKey: _timeRangeKey });
      updateDate({ ...parseTimeRangeKey(_timeRangeKey), dateOptionKey: _timeRangeKey });
    },
    [updateDate]
  );

  return (
    <>
      <HeaderContentLayout.HeaderLeft>
        <Space size={13}>
          <ApiKeySelect />
          <EText type="link" onClick={() => setMoreVisible((prev) => !prev)}>
            {moreVisible ? 'Hide' : 'More'}
          </EText>
        </Space>
      </HeaderContentLayout.HeaderLeft>
      <HeaderContentLayout.HeaderRight>
        <DatePicker
          startDate={dayjs(from)}
          endDate={dayjs(to)}
          timeRangeOptions={timeRangeOptions.slice(...timeRangeStartIndex)}
          onRangeChange={onRangeChange}
          selectedTimeRangeKey={dateOptionKey}
        >
          <Tag style={{ marginRight: 0, padding: '1px 5px' }} icon={<CalendarOutlined />}>
            {getTimeOptionText(dateOptionKey)}
          </Tag>
        </DatePicker>
      </HeaderContentLayout.HeaderRight>
      <Col span={24}>{moreVisible && <FilterPanel />}</Col>
    </>
  );
}

export default FilterHeader;
