import { Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';
import styles from './text.module.less';
import { BlockProps } from 'antd/lib/typography/Base';

type TextType = 'title' | 'cardTitle' | 'normal' | 'link' | 'paragraph';

function HeaderTitle(props: any) {
  return (
    <Typography.Title
      level={1}
      style={{ fontSize: 22 }}
      {...props}
      className={classNames(styles.headerTitle, props.className)}
    >
      {props.children}
    </Typography.Title>
  );
}

function CardTitle(props: any) {
  return (
    <Typography.Text strong type="secondary" {...props} className={classNames(styles.headerTitle, props.className)}>
      {props.children}
    </Typography.Text>
  );
}

function NormalText(props: any) {
  return (
    <Typography.Text {...props} className={classNames(styles.headerTitle, props.className)}>
      {props.children}
    </Typography.Text>
  );
}

function LinkText(props: any) {
  return (
    <Typography.Link {...props} className={classNames(styles.headerTitle, props.className)}>
      {props.children}
    </Typography.Link>
  );
}

function Paragraph({ children, ...props }: any) {
  return <Typography.Paragraph {...props}>{children}</Typography.Paragraph>;
}

interface TextProps {
  type?: TextType;
  style?: Record<string, any>;
  className?: string;
  onClick?: (e: any) => void;
}

function Text<T extends TextProps | BlockProps>({ type, ...props }: T) {
  switch (type) {
    case 'title':
      return <HeaderTitle {...props} />;
    case 'cardTitle':
      return <CardTitle {...props} />;
    case 'link':
      return <LinkText {...props} />;
    case 'paragraph':
      return <Paragraph {...props} />;
    default:
      return <NormalText {...props} />;
  }
}

Text.defaultProps = {
  type: 'normal',
};

export default Text;
