import React from 'react';
import { Col, Layout, Row } from 'antd';
import { css } from '@emotion/react';

const { Header, Content } = Layout;

interface HeaderContentLayoutProps {
  children?: React.ReactNode;
  header?: React.ReactNode;
}

interface HeaderContentLayoutType extends React.FC<HeaderContentLayoutProps> {
  HeaderLeft: any;
  HeaderRight: any;
  Header: any;
  Content: any;
}

const HeaderContentLayout: HeaderContentLayoutType = (props) => {
  const { children = null, header = null } = props;
  return (
    <>
      {header ? <_Header>{header}</_Header> : null}
      <Content style={{ padding: '20px', maxWidth: 1200, margin: 'auto' }}>{children}</Content>
    </>
  );
};

const cols = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 };

const Left = function (props: any) {
  return (
    <Col {...cols}>
      <Row style={{ minHeight: 64 }} align="middle" justify="start">
        {props.children}
      </Row>
    </Col>
  );
};

const Right = function (props: any) {
  return (
    <Col {...cols}>
      <Row style={{ minHeight: 64 }} align="middle" justify="end">
        {props.children}
      </Row>
    </Col>
  );
};

const _Header = function (props: any) {
  return (
    <div
      css={css`
        background: #fff;
        padding: 0;
        min-height: 64px;
        height: auto;
      `}
    >
      <Row style={{ maxWidth: 1200, margin: 'auto', padding: '0 20px' }} align="middle">
        {props.children}
      </Row>
    </div>
  );
};

HeaderContentLayout.HeaderLeft = Left;
HeaderContentLayout.HeaderRight = Right;
HeaderContentLayout.Header = _Header;
HeaderContentLayout.Content = Content;

export default HeaderContentLayout;
