import { Form, Table, Typography } from 'antd';
import { TableProps } from 'antd/es/table';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { useTranslate } from 'utils/intl';
import styles from './table.module.less';
import TableCell from './TableCell';

export enum InputType {
  INPUT,
  NUMBER,
  SELECT,
  MULTI_SELECT,
}

interface EditableCellTableProps extends TableProps<object> {
  columns: any[];
  dataSource: any[];
  rowSelection?: any;
  handleSave?: (row: any) => void;
  bordered?: boolean;
}

const Title = (props: any) => {
  const [t] = useTranslate();
  const { editable, label } = props;
  if (typeof label !== 'string') return null;
  if (!label.trim()) return null;
  return (
    <Typography.Paragraph
      ellipsis={{ tooltip: true }}
      className={styles.tableHeader}
      style={{ paddingLeft: editable ? 12 : 0, marginBottom: 0 }}
      strong
    >
      {t(label)}
    </Typography.Paragraph>
  );
};

class EditableCellTable extends React.Component<EditableCellTableProps> {
  formRef = React.createRef<FormInstance>();

  render() {
    // const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const columns = this.props.columns
      .map((col) => {
        if (!col.editable) {
          return { ...col };
        }
        return {
          ...col,
          onCell: (record: any) => ({
            record,
            inputType: col.inputType,
            options: typeof col.options === 'function' ? col.options(record) : col.options,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.props.handleSave,
            form: this.formRef.current,
          }),
        };
      })
      .map((col, i) => ({ ...col, title: <Title key={String(i)} editable={col.editable} label={col.title} /> }));

    const { rowSelection = { columnWidth: 60 }, pagination = {} } = this.props;

    return (
      <>
        <Form ref={this.formRef} component={false}>
          <Table
            className={styles.tableContainer}
            bordered={this.props.bordered}
            pagination={pagination ? { position: ['bottomRight'], size: 'small', ...pagination } : false}
            components={
              this.props.components || {
                body: {
                  cell: TableCell,
                },
              }
            }
            rowClassName={
              this.props.rowClassName ||
              function () {
                return styles.editableRow;
              }
            }
            rowSelection={{
              ...rowSelection,
              columnWidth: 60,
            }}
            {...this.props}
            columns={columns}
            // dataSource={this.props.dataSource}
            scroll={this.props.scroll || { x: 1000 }}
          />
        </Form>
      </>
    );
  }
}

export default EditableCellTable;
