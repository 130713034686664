import * as d3 from 'd3';
import { FeatureCollection } from 'geojson';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef, useState } from 'react';
import { QualityPercent } from './BreakdownPercent';
import './map-view.css';
import { ChartConfig } from './options';
import { getOrCreate } from './utils';

export interface MapAes {
  tileLayer: {
    color: string;
    tooltipCol: {
      formatter: (x: any) => any;
      col: string;
      title: string;
    }[];
  };
  qualityMap: { colors: string[]; lowerBounds: number[]; labels: string[] };
}

export interface MapViewProps {
  chartConfig?: ChartConfig;
  tileServer: number;
  aes: MapAes;
  data?: any;
}
export const plotPie = (
  parent: d3.Selection<d3.BaseType, any, any, any>,
  radius: number,
  configuration: QualityPercent[],
  innerRadius = 3
) => {
  const pie = d3.pie().value((d: any) => d['percent']);
  const arcs = pie(configuration as any);
  parent
    .append('g')
    .attr('transform', `translate(${radius}, ${radius})`)
    .selectAll('path')
    .data(arcs)
    .join('path')
    .attr('d', d3.arc().innerRadius(innerRadius).outerRadius(radius) as any)
    .attr('fill', (d) => (d.data as any)['color']);
};
// needed components:
// a world map topojson
// when clicking on each country, the topojson of that country

// implementation of quality map:
// the quality map is done per geo centroid or per region_iso_code
// if we do it with geo centroid, we don't need to have to map to the svgs
// if we do it per region_iso_code, then we have to join with the actual value

// and as we use the elasticsearch it provides very good aggregation of the centroid
// so i think i will do it like Kibana without inventing another thing
const drawTileLayer = (
  svgElement: d3.Selection<any, any, any, any>,
  geoj: FeatureCollection,
  pathGen: d3.GeoPath<any, d3.GeoPermissibleObjects>,
  map: L.Map,
  aes: MapAes,
  ref: any
) => {
  const g = getOrCreate(svgElement, 'map', 'g');
  const { color } = aes.tileLayer;
  const qualityLayerG = getOrCreate(svgElement, 'quality', 'g');

  const valueAccessor = (n: string) => {
    return (d: any) => d.properties[n];
  };

  const maxValue = d3.max(geoj.features.map(valueAccessor(color)));
  const interpolator = d3.interpolateLab('#dcf5fd', '#0052ba');

  // const getConfigFromProperties = (p: any) => {
  //   const values = Object.values(p['chunkSize']);
  //   const config = EstimationToConfiguration(
  //     EstimateDistribution(aes.qualityMap.lowerBounds, values.sort() as any)
  //   ).map((v: any, i: number) => {
  //     return {
  //       ...v,
  //       color: aes.qualityMap.colors[i],
  //       percent: v.toPercent - v.fromPercent,
  //       key: `${i}`,
  //     };
  //   });
  //   return config;
  // };

  const tileColorLegendConfig = new Array(10)
    .fill(1)
    .map((_, i) => (i + 1) / 10)
    .map((v, i) => {
      return { value: v, color: interpolator(v), x: i * 10 };
    });

  // This is the side effect !!!!
  const legendContainer = d3.select(ref.current).select('div.map-legend-container'),
    tileLegendContainer = getOrCreate(legendContainer, 'tile-legend', 'svg').attr('height', 70);
  tileLegendContainer
    .selectAll('text.tile-legend')
    .data(tileColorLegendConfig.filter((d, i) => i % 4 === 0))
    .join('text')
    .attr('class', 'tile-legend')
    .attr('x', (d) => d.x)
    .attr('y', 25)
    .text((d) => Math.round(d.value * maxValue));

  tileLegendContainer
    .selectAll('rect.tile-legend')
    .data(tileColorLegendConfig)
    .join('rect')
    .attr('class', 'tile-legend')
    .attr('width', 10)
    .attr('height', 10)
    .attr('x', (d) => d.x)
    // .attr('transform', (d) => `translate(${d.x}, 0)`)
    .attr('fill', (d) => d.color);

  tileLegendContainer
    .selectAll('rect.quality-legend')
    .data([0, ...aes.qualityMap.lowerBounds])
    .join('rect')
    .attr('class', 'quality-legend')
    .attr('width', 10)
    .attr('height', 10)
    .attr('y', 30)
    .attr('x', (_, i) => i * 38 + 0)
    .attr('fill', (_, i) => aes.qualityMap.colors[i]);

  tileLegendContainer
    .selectAll('text.quality-legend')
    .data([0, ...aes.qualityMap.lowerBounds])
    .join('text')
    .attr('class', 'quality-legend')
    .attr('y', 39)
    .attr('x', (_, i) => i * 38 + 12)
    .text((d, i, a) => {
      const prefix = aes.qualityMap.labels[i];
      // let range = `${fileSize(d)} - ${fileSize(aes.qualityMap.lowerBounds[i])}`;
      // if (i === 0) range = `< ${fileSize(aes.qualityMap.lowerBounds[i])}`;
      // else if (i === a.length -1) range = `> ${fileSize(aes.qualityMap.lowerBounds[i - 1])}`
      // return `${prefix} ${range}`
      return prefix;
    });

  // for each tile
  g.selectAll('path.tiles')
    .data(geoj.features)
    .join('path')
    .attr('d', pathGen)
    .attr('class', 'tiles leaflet-interactive')
    .attr('fill', (v: any) => {
      if (maxValue === 0) {
        return 'transparent';
      }
      if (valueAccessor(color)(v) === 0) return '#e7e7e7';
      // if (v === 0) return 'gray'
      return interpolator(valueAccessor(color)(v) / maxValue);
    })
    .attr('stroke-width', 1)
    .attr('stroke', '#fefefe')
    .attr('opacity', '0.8')
    .on('mouseleave', () => {
      d3.select(ref.current).select('.tooltip-container').style('display', 'none');
    })
    .on('mouseenter', (_, feat) => {
      // Plot the tooltips
      const p = map.latLngToContainerPoint(d3.geoCentroid(feat).reverse() as any);
      const pieChartConfig = (feat.properties as any)['chunkSize'].map((v: any, i: number) => {
        v['color'] = aes.qualityMap.colors[i];
        return v;
      });
      const container = d3.select(ref.current).select('.tooltip-container').style('display', null);
      container
        .transition()
        .duration(100)
        .style('width', '110px')
        .style('z-index', '1111')
        .style('left', p.x + 'px')
        .style('top', p.y + 'px');
      // set the title
      container.select('.tooltip-header').text(valueAccessor('name')(feat));

      // set the text content
      const contentContainer = container.select('.tooltip-content');
      getOrCreate(contentContainer, 'text-tooltip', 'div').html(
        aes.tileLayer.tooltipCol
          .map((v) => `${v.title}: ${v.formatter(valueAccessor(v.col)(feat))}`)
          .reduce((a, b) => a + '<br/>' + b)
      );

      // put the piechart
      if (pieChartConfig.length !== 0) {
        const piechartContainer = getOrCreate(contentContainer, 'piechart-tooltip', 'svg')
          .style('display', null)
          .attr('height', 20)
          .attr('width', 20)
          .attr('viewbox', '0 0 20 20');

        plotPie(piechartContainer, 10, pieChartConfig);
      } else {
        getOrCreate(contentContainer, 'piechart-tooltip', 'svg').style('display', 'none');
      }
    });

  const getLayerXY = (f: any) => {
    const p = map.latLngToLayerPoint(d3.geoCentroid(f).reverse() as any);
    return p;
  };

  // The quality maps
  const { qualityMap } = aes;

  qualityLayerG
    .selectAll('circle.dots')
    .data(geoj.features)
    .join('circle')
    .attr('class', 'dots')
    .attr('cx', (f) => {
      return getLayerXY(f).x;
    })
    .attr('cy', (f) => {
      return getLayerXY(f).y;
    })
    .attr('r', () => {
      const p1 = map.project([0, 1], map.getZoom());
      const p2 = map.project([0, 1.5], map.getZoom());
      const length = Math.abs(p1.x - p2.x);
      return length;
    })
    // .attr('fill', 'black');
    .attr('fill', (f) => {
      const v = valueAccessor('avgChunkSize')(f);
      return v ? qualityMap.colors[d3.bisectLeft(qualityMap.lowerBounds, v)] : 'none';
    });
};

function downloadCSV(csv: any, filename: any) {
  var csvFile;
  var downloadLink;

  //define the file type to text/csv
  csvFile = new Blob([csv], { type: 'text/csv' });
  downloadLink = document.createElement('a');
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = 'none';

  document.body.appendChild(downloadLink);
  downloadLink.click();
}

export const MapView: React.FC<MapViewProps> = ({ chartConfig, tileServer, aes, data }) => {
  const parisCenter = [30, 10];
  const initialZoom = 2;
  const config = chartConfig ?? new ChartConfig();
  const ref = useRef<any>();
  const [map, setMap] = useState<any>();

  const getPath = (m: any) => {
    const transform = d3.geoTransform({
      point: function (x, y) {
        var point: any = map.latLngToLayerPoint(new L.LatLng(y, x));
        this.stream.point(point.x, point.y);
      },
    });
    var path = d3.geoPath().projection(transform);
    return path;
  };

  useEffect(() => {
    const servers = [
      'http://tile.openstreetmap.org/{z}/{x}/{y}.png',
      'http://b.tile.stamen.com/toner/{z}/{x}/{y}.png',
      'http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
    ];
    const myMap = L.map('map').setView(parisCenter as any, initialZoom);
    setMap(myMap);
    const mapUrl = servers[tileServer];
    L.tileLayer('', {
      maxZoom: 18,
      minZoom: 2,
    }).addTo(myMap);

    L.svg().addTo(myMap);

    const svgElement = d3.select('#map').select('svg');
    const path = getPath(myMap);

    // worldGeoJsonWithStats('snrt').then((r) => {});
  }, []);

  useEffect(() => {
    const svgElement = d3.select('#map').select('.leaflet-overlay-pane').select('svg');
    const path = getPath(map);
    if (map && data) {
      map.on('zoom', () => {
        drawTileLayer(svgElement, data, path, map, aes, ref);
      });
    }
    if (data) {
      drawTileLayer(svgElement, data, path, map, aes, ref);
    }
  }, [data, map]);

  return (
    <div
      id="map-container"
      style={{
        height: 400,
        width: '100%',
        display: 'flex',
      }}
      ref={ref}
    >
      <div
        id="map"
        style={{
          height: 400,
          width: '100%',
          background: '#fefefe',
        }}
        ref={ref}
      >
        <div className="tooltip-container" style={{ display: 'none' }}>
          <div className="tooltip-header"></div>
          <div className="tooltip-content"></div>
        </div>
        <div className="map-legend-container"></div>
      </div>
    </div>
  );
};
