import {
  BarChartOutlined,
  LockOutlined,
  PlaySquareOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import AccountSettingPage from 'containers/accounts/AccountSettingPage';
import AlertPage from 'containers/accounts/Alert';
import ApiKeyPage from 'containers/accounts/ApiKeyPage';
import ChannelPage from 'containers/accounts/ChannelPage';
import LicensePage from 'containers/accounts/LicensePage';
import UserPage from 'containers/accounts/user';
import VadConfigPage from 'containers/accounts/VADConfigPage';
import BreakdownPage from 'containers/analytics/BreakdownPage';
import CDNAnalyticsPage from 'containers/analytics/CDNAnalytics';
import ContentCentric from 'containers/analytics/ContentCentric';
import OverviewPage from 'containers/analytics/OverviewPage';
import RealtimePage from 'containers/analytics/RealtimePage';
import PlayerPage from 'containers/player-integration/PlayerPage';
import React from 'react';
import { ACCESS_FEATURES } from 'utils/enums';

export interface RouterProps {
  key: string;
  path: string;
  title?: string;
  authority?: string[];
  component?: React.ComponentType<any>;
  redirect?: string;
  exact?: boolean;
  showInLeftMenu?: boolean;
  parent?: string;
  icon?: React.ComponentType<any>;
  children?: any[];
}

function NullComponent() {
  return null;
}

export const Routes: RouterProps[] = [
  // {
  //   path: '/analytics/display_by_categories',
  //   key: 'test1',
  //   title: 'route.dashboard',
  //   icon: DashboardOutlined,
  //   component: NewAnalytics,
  // },
  {
    path: '/analytics',
    key: 'analytics',
    title: 'route.analytics.title',
    icon: BarChartOutlined,
    children: [
      {
        path: '/analytics/overview',
        key: 'overview',
        title: 'route.analytics.overview',
        component: OverviewPage,
      },
      {
        path: '/analytics/realtime',
        key: 'realtime',
        title: 'route.analytics.realtime',
        component: RealtimePage,
      },
      {
        path: '/analytics/breakdown',
        key: 'breakdown',
        title: 'route.analytics.breakdown',
        component: BreakdownPage,
      },
      {
        path: '/analytics/content',
        key: 'contentcentric',
        title: 'route.analytics.content',
        component: ContentCentric,
        showInLeftMenu: false,
      },
      {
        path: '/analytics/cdn',
        key: 'cdnanalytics',
        title: 'route.analytics.cdn',
        component: CDNAnalyticsPage,
        showInLeftMenu: true,
      },
      // {
      //   path: '/analytics/categories',
      //   key: 'categories',
      //   title: 'categories',
      //   // icon: DashboardOutlined,
      //   // component: NewAnalytics,
      //   children: NewAnalyticsLayouts.map((e) => ({
      //     path: '/analytics/categories/' + e.key,
      //     key: e.key,
      //     title: e.key.replace('_', ' '),
      //     component: () => null,
      //   })),
      // },
      // {
      //   path: '/analytics/audience',
      //   key: 'audience',
      //   title: 'Audience',
      //   children: [
      //     {
      //       path: '/analytics/audience/viewers',
      //       key: 'audience_viewers',
      //       title: 'Viewers',
      //       component: () => <AudienceViewersPage />,
      //     },
      //     // {
      //     //   path: '/analytics/audience/players',
      //     //   key: 'audience_player',
      //     //   title: 'Players',
      //     //   component: () => <AudiencePlayersPage />,
      //     // },
      //     {
      //       path: '/analytics/audience/month',
      //       key: 'month',
      //       title: 'Month',
      //       component: () => <MonthCalendarPage />,
      //     },
      //   ],
      // },
      // {
      //   path: '/analytics/network',
      //   key: 'network',
      //   title: 'Network',
      //   children: [
      //     {
      //       path: '/analytics/network/cdn',
      //       key: 'network_cdn',
      //       title: 'CDN',
      //       component: () => <CDNPage />,
      //     },
      //     {
      //       path: '/analytics/network/vad',
      //       key: 'network_vad',
      //       title: 'VAD',
      //       component: () => <VADPage></VADPage>,
      //     },
      //   ],
      // },
      // {
      //   path: '/analytics/quality',
      //   key: 'quality',
      //   title: 'Quality',
      //   children: [
      //     // {
      //     //   path: '/analytics/quality/loadingtime',
      //     //   key: 'quality_loadingtime',
      //     //   title: 'Loading Time',
      //     //   component: () => <span>coming soon</span>,
      //     // },
      //     {
      //       path: '/analytics/quality/watchingtime',
      //       key: 'quality_watching_time',
      //       title: 'Watching Time',
      //       component: () => <WatchingTimePage />,
      //     },
      //     {
      //       path: '/analytics/quality/buffering',
      //       key: 'quality_buffering',
      //       title: 'Buffering',
      //       component: () => <RebufferingTimePage />,
      //     },
      //     // {
      //     //   path: '/analytics/quality/bitrate',
      //     //   key: 'quality_bitrate',
      //     //   title: 'Bitrate',
      //     //   component: () => <span>coming soon</span>,
      //     // },
      //     // {
      //     //   path: '/analytics/quality/http',
      //     //   key: 'quality_http',
      //     //   title: 'HTTP',
      //     //   component: () => <span>coming soon</span>,
      //     // },
      //   ],
      // },
    ],
  },
  {
    path: '/contents',
    key: 'contents',
    title: 'route.contents',
    icon: UnorderedListOutlined,
    component: ChannelPage,
    authority: [ACCESS_FEATURES.CHANNEL_CONFIG],
  },
  {
    path: '/player_installation',
    key: 'playerInstallation',
    title: 'route.player',
    icon: PlaySquareOutlined,
    component: PlayerPage,
    authority: [ACCESS_FEATURES.PLAYER_INSTALL],
  },
  {
    path: '/users',
    key: 'users',
    title: 'route.users',
    component: UserPage,
    icon: TeamOutlined,
    authority: [ACCESS_FEATURES.USER_MANAGE],
  },
  {
    path: '/setting',
    key: 'setting',
    title: 'route.setting.title',
    redirect: '/setting/accout',
    icon: SettingOutlined,
    children: [
      {
        path: '/setting/vtov',
        key: 'settingVToV',
        title: 'route.setting.v2v',
        component: VadConfigPage,
        authority: [ACCESS_FEATURES.V2V_CONFIG],
      },
      {
        path: '/setting/alert',
        key: 'alert',
        title: 'route.setting.alert',
        component: AlertPage,
        authority: [ACCESS_FEATURES.NOTIFICATION],
      },
      {
        path: '/setting/accout',
        key: 'account',
        title: 'route.setting.account',
        component: AccountSettingPage,
      },
    ],
  },
  {
    path: '/internal',
    key: 'internal',
    title: 'route.internal',
    component: NullComponent,
    redirect: '/internal/licenses',
    icon: LockOutlined,
    authority: [ACCESS_FEATURES.INTERNAL],
    children: [
      {
        path: '/internal/apikeys',
        key: 'apikey',
        title: 'route.apikey',
        component: ApiKeyPage,
        authority: [ACCESS_FEATURES.APIKEYS_ALL],
      },
      {
        path: '/internal/licenses',
        key: 'license',
        title: 'route.licenses',
        component: LicensePage,
        parent: 'internal',
        authority: [ACCESS_FEATURES.INTERNAL],
      },
    ],
  },
];

export default Routes;
