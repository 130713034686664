import { useTheme } from '@emotion/react';
import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
// import { primaryColor1 } from 'styles/theme.less';
import './barchart.css';
import { withChartWrapper } from './functions/wrappers';
import { ChartConfig } from './options';
interface ChartWrapperProps {
  width: number;
  height?: number;
}
export interface BarchartProps extends ChartWrapperProps {
  data: { x: string | number; y: number }[];
  barPadding: number;
  chartConfig?: ChartConfig;
}

const Barchart: React.FC<BarchartProps> = ({ data, barPadding, chartConfig, width, height: propsHeight }) => {
  const config = chartConfig ?? new ChartConfig();
  const theme = useTheme() as any;

  // const svgWidth = useParentWidth(undefined, ref);
  // config.setSize((rest as any)['width'], config.height);
  const { height } = config;

  const ref = useRef<any>();

  useEffect(() => {
    // load the reference to the containers
    config.setSize(width, height);
    const svgElement = d3.select(ref.current).select('svg');
    const tooltip = d3.select(ref.current).select('.tooltip');
    const bars = svgElement.select('.bars');
    const xlabels = svgElement.select('.xlabels');

    const color = d3.interpolate(theme.colors.primaryColor1, theme.colors.primaryColor1);

    // create x scale
    // scaleBand is for the band like vis, e.g. The bar chart
    // set the range to domain
    // range = []

    if (!data) {
      return;
    }

    let x = d3
      .scaleBand()
      // the range is from left padding to the x of right padding
      .range(config.xExtend())
      // domain is of course the list of the keywords
      .domain(data.map((x) => x['x'] as string))
      // padding is to create nice looking white spaces
      .padding(barPadding);

    // const x = d3;

    let xAxis = d3.axisBottom(x);
    if (x.domain().length > 10) {
      let every = Math.round(x.domain().length / 10);
      xAxis = xAxis.tickValues(x.domain().filter((_, i) => i % every == 0));
    }

    // create y scale
    const y = d3
      // the most common type of scale
      .scaleLinear()
      // it's better to take the inverse for easier calculation
      .domain([0, d3.max(data.map((y) => y.y)) || 0])
      .range(config.yExtend().reverse());

    // the common accesors
    const xAccessor = (d: any) => x(d['x']) as any;
    const yAccessor = (d: any) => y(d['y']) as any;
    const maxValue = d3.max(data.map(yAccessor));

    xlabels
      .attr('transform', `translate(0, ${height - 20} )`)
      .call(xAxis as any)
      .call((g) => g.selectAll('.domain').remove())
      .call((g) => g.selectAll('line').remove())
      .call((g) => g.selectAll('text').attr('class', 'barchart-xlabels'));

    bars
      .selectAll('rect')
      .data(data)
      .join('rect')
      .attr('x', xAccessor)
      .attr('class', 'barchart-bar')
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('height', (d: any) => 0)
      .attr('fill', (value) => color(yAccessor(value) / maxValue))
      .attr('width', x.bandwidth())
      .attr('y', height - 20)
      .call((enter) =>
        enter
          .transition()
          .duration(200)
          .attr('y', yAccessor)
          .attr('height', (d: any) => (height - 20 - y(d['y'])) as any)
      )

      .on('mouseover', (d, datum) => {
        tooltip
          .html(datum.x + '<br/>views: ' + datum.y)
          .transition()
          .duration(200)
          .style('opacity', 1)
          .style('left', d.offsetX + 'px')
          .style('top', d.offsetY - 10 + 'px');
      })
      .on('mouseleave', (d) => {
        tooltip.transition().duration(200).style('opacity', 0);
      });
  }, [data, barPadding, width]);

  return (
    <div ref={ref} style={{ display: 'inline-block' }}>
      <div className="tooltip"></div>
      <svg style={{ width, height }} viewBox={`0 0 ${width} ${height}`}>
        <g className="bars"></g>
        <g className="xlabels"></g>
      </svg>
    </div>
  );
};

export default withChartWrapper(Barchart);
