import React from 'react';
import { Button, Modal, ModalFuncProps, Row, Space } from 'antd';
import { Text } from '../base';
import { useTranslate } from '../../utils/intl';

interface ModalWrapProps extends ModalFuncProps {
  header?: string;
  children?: React.ReactNode;
  buttons?: {
    label: string;
    primary?: boolean;
    onClick: (params?: any) => void;
  }[];
  loading?: boolean;
  hide?: () => void;
  submit?: (params?: any) => void;
}

export function generateCommonButtons(cancel: () => void = () => {}, ok: (params?: any) => void = () => {}) {
  return [
    {
      label: 'button.cancel',
      onClick: cancel,
      primary: false,
    },
    {
      label: 'button.ok',
      primary: true,
      onClick: ok,
    },
  ];
}

function ModalWrap(props: ModalWrapProps) {
  const { header, children, buttons, loading } = props;
  const [t] = useTranslate();

  const renderButtons = buttons ?? generateCommonButtons(props.hide, props.submit);

  return (
    <Modal
      {...props}
      title={
        <Text type="title" style={{ marginBottom: 0 }}>
          {header}
        </Text>
      }
      closable={false}
      footer={null}
    >
      {children}
      <Row justify="end" style={{ marginTop: 40 }}>
        <Space>
          {renderButtons.map(({ label, primary = false, onClick }, index) => (
            <Button
              loading={primary && loading}
              ghost={!primary}
              type="primary"
              shape="round"
              onClick={onClick}
              key={label || String(index)}
            >
              {t(label)}
            </Button>
          ))}
        </Space>
      </Row>
    </Modal>
  );
}

export default ModalWrap;
