import countriesList from 'countries-list';

export const countriesObject = countriesList.countries as any;

interface CountryObject {
  code: string;
  country: any;
  name?: string;
  emoji?: string;
}

export function getCountryName(countryCode: string) {
  if (countryCode.length !== 2) {
    return false;
  }
  const code = countryCode.toUpperCase();
  return countriesObject[code].name;
}

export function getPhone(countryCode: string) {
  if (countryCode.length !== 2) {
    return false;
  }
  const code = countryCode.toUpperCase();
  return countriesObject[code].phone;
}

// {
//   "name": "Andorra",
//   "native": "Andorra",
//   "continent": "EU",
//   "currency": "EUR",
//   "languages": [
//       "ca"
//   ],
//   "emoji": "🇦🇩",
//   "emojiU": "U+1F1E6 U+1F1E9"
// }
export function getCountriesList(): CountryObject[] {
  return Object.entries(countriesObject).map((c: any) => ({
    code: c[0],
    country: c[1],
    name: `${c[1]?.native} (${c[1]?.name})`,
    emoji: c[1]?.emoji ?? null,
  }));
}

