import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Layout, Radio, Row } from 'antd';
import { Text } from 'components/base';
import { QualityBreakdownPercent } from 'components/visualizations/BreakdownPercent';
import { useFilters } from 'contexts/filters';
import ContentLayout from 'layouts/CardPageLayout';
import ChartCardLayout from 'layouts/ChartCardLayout';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { PropsWithIntl, useTranslate, withIntl } from 'utils/intl';
import styles from './styles/breakdown.module.less';
import { useQoEQoSGroupByData } from './utils/hooks';
import CommonLayout from './utils/withLayout';

// interface BreakdownPageProps {
//   metric: string;
//   by: string;
// }

export const bigNumberColProps = { xs: 24, sm: 24, md: 8, lg: 8, xl: 8 };

export const rangeBucketsToPercents = (v: any) => {
  let sumViews = 0;
  const cobj = {
    content: v.key,
    configuration: v.views.buckets.map((m: any) => {
      sumViews += m.views.value;
      return { key: m.key, cnt: m.views.value };
    }),
  };
  let cumulativePercent = 0;
  cobj.configuration = cobj.configuration.map((v: any) => {
    v.percent = (v.cnt * 100) / sumViews;
    v.fromPercent = cumulativePercent;
    cumulativePercent += Number(v.percent.toFixed(2));
    v.toPercent = cumulativePercent;
    return v;
  });
  return cobj;
};

export interface MetricConfiguration {
  label: string;
  largerBetter: boolean;
  valueFormatter: string;
  ranges: { from: number; to: number }[];
  rangesTxt: string;
}

const availableQoEMetrics: { [id: string]: MetricConfiguration } = require('assets/qoe-breakdown.cfg.v2.json');

const BreakdownPage: React.FC<any> = () => {
  const [t] = useTranslate();
  const { apiKey, from, to, selectedFilters: filters, contents } = useFilters();

  const [metric, setMetric] = useState<string>('sessionTime');
  const [by, setBy] = useState<string>('content');
  const [metricDef, setMetricDef] = useState<MetricConfiguration>(availableQoEMetrics[metric]);
  // const [data, setData] = useState([]);
  const [dataProcessed, setDataProcessed] = useState([]);
  const { rangesTxt } = availableQoEMetrics[metric];
  const { isLoading, qoeBreakdownData } = useQoEQoSGroupByData({
    ranges: rangesTxt,
    groupby: by,
    metric,
    option: { apiKey, from, to, filters },
  });

  useEffect(() => {
    setMetricDef(availableQoEMetrics[metric]);
  }, [metric]);

  // This is used to join the content with the backend settings
  useEffect(() => {
    // only perform the action when everything is there
    if (!!contents && !!qoeBreakdownData) {
      const urlKeyMap = Object.fromEntries(contents?.map((v) => [v.url, v.name]));
      const processData = qoeBreakdownData
        .map((v: any) => {
          if (urlKeyMap[v.content]) {
            v.content = urlKeyMap[v.content];
          }
          v.sumViews = _.sum(v.configuration.map((x) => x.cnt));
          return v;
        })
        .sort((x, y) => y.sumViews - x.sumViews);
      setDataProcessed(processData as any);
    }
  }, [contents, qoeBreakdownData]);

  return (
    <CommonLayout>
      <Layout>
        <Layout.Content>
          <ContentLayout>
            {isLoading ? (
              <div> Loading... </div>
            ) : (
              <div style={{ maxWidth: 1200, margin: 'auto' }}>
                <Row justify="center">
                  <Col span={24}>
                    <ChartCardLayout
                      title={t('analytics.breakdownTitle', { metric: t(availableQoEMetrics[metric].label), by })}
                    >
                      <QualityBreakdownPercent
                        data={dataProcessed}
                        colors={
                          metricDef.largerBetter
                            ? ['#C13D59', '#F8D119', '#08C388']
                            : ['#C13D59', '#F8D119', '#08C388'].reverse()
                        }
                        valueFormater={metricDef.valueFormatter}
                      />
                    </ChartCardLayout>
                  </Col>
                </Row>
              </div>
            )}
          </ContentLayout>
        </Layout.Content>
        <RightSettingPanel
          onByChange={(value: any) => setBy(value)}
          onMetricChange={(value: string) => setMetric(value)}
        />
      </Layout>
    </CommonLayout>
  );
};

const qoeOptions = Object.entries(availableQoEMetrics).map(([key, value]) => {
  return {
    value: key,
    label: value.label,
  };
});

// const dimensionOptions = ['content', 'isp', 'country', 'browser', 'os'].map((i) => ({
//   value: i,
//   label: _.capitalize(i),
// }));

const dimensionOptions = [
  { value: 'content', label: 'Content' },
  { value: 'isp', label: 'ISP' },
  { value: 'country', label: 'Country' },
  { value: 'browser', label: 'Browser' },
  { value: 'os', label: 'OS' },
];

const OptionsGroup = withIntl(function ({ options, title, onChange = () => {}, t, collapsed, setCollapsed }: any) {
  if (!options.length) return null;

  const [value, setValue] = useState(options[0].value);

  return (
    <Col span={24}>
      <Row gutter={[0, 10]}>
        <Col span={24} className={collapsed ? styles.collapsedText : ''}>
          <Text type="cardTitle">{collapsed ? t(title).substring(0, 3) : t(title)}</Text>
        </Col>
        <Col span={24}>
          {collapsed ? (
            <Button type="primary" size="small" style={{ fontSize: 10 }} onClick={() => setCollapsed(false)}>
              {value.substring(0, 3)}..
            </Button>
          ) : (
            <Radio.Group
              onChange={(e: any) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              value={value}
            >
              {options.map(({ value: optionValue, label }: any) => (
                <Radio value={optionValue} key={optionValue}>
                  {t(label)}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </Col>
      </Row>
    </Col>
  );
});

const RightSettingPanel = withIntl(function ({ t, onByChange, onMetricChange }: PropsWithIntl & any) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout.Sider
      // breakpoint={'md'}
      collapsible
      trigger={null}
      collapsed={collapsed}
      className={styles.rightBarContainer}
      width={300}
      collapsedWidth={70}
    >
      <Row align="middle" className={styles.containerPadding}>
        <Col flex="auto" span={collapsed ? 0 : 23}>
          <Text style={{ fontSize: 16 }}>{t('analytics.breakdown')}</Text>
        </Col>
        {React.createElement(collapsed ? DoubleLeftOutlined : DoubleRightOutlined, {
          className: styles.rightBarIcon,
          onClick: () => setCollapsed(!collapsed),
        })}
      </Row>
      <Divider />
      <Row gutter={[16, 16]} className={styles.optionsContainer}>
        <OptionsGroup
          options={qoeOptions}
          title={t('analytics.qoe')}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          onChange={onMetricChange}
        />
        <OptionsGroup
          options={dimensionOptions}
          title={t('analytics.dimension')}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          onChange={onByChange}
        />
      </Row>
    </Layout.Sider>
  );
});

export default BreakdownPage;
