import React from 'react';
import { useTranslate } from 'utils/intl';
import { FormItemWrap } from 'components/antd/FormWrap';
import { PublicService } from 'services';

export default function () {
  const [t] = useTranslate();
  return (
    <FormItemWrap.Input
      name="email"
      label={'auth.email'}
      rules={[
        {
          type: 'email',
          message: t('auth.notValidEmail'),
        },
        {
          validator: async (_, email) => {
            if (!email) {
              return Promise.resolve();
            }
            try {
              await PublicService.checkMailAvailable({ email });
              return Promise.resolve();
            } catch (e) {
              if (e.message) {
                return Promise.reject(new Error(t(e.message)));
              }
            }
          },
        },
      ]}
      hasFeedback
    />
  );
}
