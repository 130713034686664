enum ENUM_ENV {
  DEV = 'development',
  PROD = 'production',
}

export const ENV: ENUM_ENV = process.env.NODE_ENV as any;
export const LOCAL_BE: string = process.env.REACT_APP_LOCAL_BE ?? '';
export const STAGING_BE: string = process.env.REACT_APP_STAGING_BE ?? '';

export const DEV_BASE_URL = 'http://localhost:7001/api/';
export const PROD_BASE_URL = 'https://board.easybroadcast.fr/api/';
export const STAG_BASE_URL = 'https://board-staging.kube.easybroadcast.fr/api/';

let _BASE_URL = PROD_BASE_URL;
// let _V3_URL = 'https://api.easybroadcast.fr/';
if (ENV === ENUM_ENV.DEV && LOCAL_BE === 'on') {
  _BASE_URL = DEV_BASE_URL;
} else if (STAGING_BE === 'on') {
  _BASE_URL = STAG_BASE_URL;
}

export const BASE_URL = _BASE_URL;

export const AUTHORITY_KEY = 'EB_Dashboardv2_AUTHORITY';
// export const DATA_API_URL = 'http://localhost:5000/v2';
export const DATA_API_URL = 'https://board.easybroadcast.fr/v2';
export const DATA_API_V2_URL = BASE_URL + 'v2/proxy/es';
export const DATA_API_V3_URL = 'https://api.easybroadcast.fr/data';
// export const DATA_API_V3_URL = "http://localhost:8000"

export const AUTO_REFRESH_INTERNAL = 30 * 1000;
export const MOCK_ANALYTICS_RESULT = false;

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const COPYRIGHT_FOOTER_TEXT = `Copyright © EasyBroadcast 2021, v${APP_VERSION}`;
