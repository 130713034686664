import { css } from '@emotion/react';
import ModalWrap from 'components/antd/ModalWrap';
import TableWrap from 'components/antd/TableWrap';
import { Text } from 'components/base';
import Loading from 'components/misc/Loading';
import Search from 'components/misc/Search';
import HeaderContentLayout from 'layouts/HeaderContentLayout';
import React from 'react';
import { useFormInModal, useModalWithFetcher } from 'utils/hooks/modal';
import { useTranslate } from 'utils/intl';

function flattenObject(obj: any, prefix = '', res = {}) {
  return Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`;
    if (Object.prototype.toString.call(val) === '[object Object]') {
      flattenObject(val, `${k}.`, r);
    } else {
      res[k] = val;
    }
    return r;
  }, res);
}

function unflattenObject(obj: any) {
  let result = {};

  for (var i in obj) {
    var keys = i.split('.');
    keys.reduce(function (r, e, j) {
      return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? obj[i] : {}) : []);
    }, result);
  }
  return result;
}

function defaultParseData(params: any) {
  return flattenObject(params);
}

function defaultToData(params: any) {
  return unflattenObject(params);
}

export function useTemplateModel({
  name = 'item',
  service,
  searchKeys = ['name'],
  parseData = defaultParseData,
  toData = defaultToData,
}: {
  name: string;
  service: any;
  searchKeys?: string[];
  parseData?: (params: any) => any;
  toData?: (params: any) => any;
}) {
  const {
    data,
    dataLoading,
    modalLoading,
    modalTitle,
    hideModal,
    modalVisible,
    openCreateModal,
    openEditModal,
    submitData,
    destroy,
    destroyMany,
    modalSelectedData,
    search,
    searchText,
  } = useModalWithFetcher(name, service, searchKeys);
  const { form, submitForm } = useFormInModal(modalVisible, modalSelectedData, parseData, toData, submitData);
  return {
    data,
    dataLoading,
    modalLoading,
    modalTitle,
    hideModal,
    modalVisible,
    openCreateModal,
    openEditModal,
    submitData,
    destroy,
    destroyMany,
    modalSelectedData,
    search,
    form,
    submitForm,
    name,
    searchKeys,
    searchText,
  };
}

export function TableModalTemplate(props: any) {
  const [t] = useTranslate();
  const {
    data,
    dataLoading,
    modalLoading,
    modalTitle,
    hideModal,
    modalVisible,
    openCreateModal,
    openEditModal,
    destroy,
    destroyMany,
    search,
    submitForm,
    searchKeys,
    name,
    baseColumns,
    formComponent,
    config = {},
    searchText,
  } = props;

  const { hideCreate, hideSearch } = config;

  return (
    <HeaderContentLayout
      header={
        <>
          {!hideSearch && (
            <HeaderContentLayout.HeaderLeft>
              <Search
                css={css`
                  width: 100%;
                  max-width: 350px;
                `}
                value={searchText}
                onSearch={search}
                placeholder={t('common.searchBy', { label: searchKeys.join('/') })}
              />
            </HeaderContentLayout.HeaderLeft>
          )}
          {!hideCreate && (
            <HeaderContentLayout.HeaderRight>
              <Text type="link" onClick={openCreateModal}>
                {t('common.createLabel', { label: t(name) })}
              </Text>
            </HeaderContentLayout.HeaderRight>
          )}
        </>
      }
    >
      <Loading loading={dataLoading}>
        <TableWrap columns={baseColumns} data={data} destroy={destroy} edit={openEditModal} destroyMany={destroyMany} />
      </Loading>
      <ModalWrap
        header={t(modalTitle)}
        hide={hideModal}
        submit={submitForm}
        visible={modalVisible}
        loading={modalLoading}
      >
        {formComponent}
      </ModalWrap>
    </HeaderContentLayout>
  );
}

TableModalTemplate.useModel = useTemplateModel;
TableModalTemplate.flattenObject = flattenObject;
TableModalTemplate.unflattenObject = unflattenObject;

// function TableTemplate({ name, service, searchKeys, baseColumns, children, ...rest }) {
//   const model = useTemplateModel(name, service, searchKeys, rest.parseData, rest.toData);
//   return <TableModalTemplate {...model} baseColumns={baseColumns} formComponent={children ?? null} />;
// }

export default TableModalTemplate;
