import React, { useState, useEffect, useCallback } from 'react';
import { generateRestService } from 'services/index';
import { compareStr } from 'utils/common';
import { useRestFetcher } from './fetcher';
import { useForm } from 'components/antd/FormWrap';

const fakeService = generateRestService('fake');

export function useModal() {
  const [visible, setVisible] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const show = useCallback((id = '') => {
    setSelectedId(id);
    setVisible(true);
  }, []);

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  return { visible, show, hide, title: !selectedId ? 'common.create' : 'common.edit', selectedId };
}

export function useModalWithFetcher(name = 'Item', service = fakeService, searchKeys: string[] = []) {
  const { visible, show: showModal, hide, title, selectedId } = useModal();
  const { loading, data, add: serviceAdd, edit: serviceEdit, destroy, list, destroyMany } = useRestFetcher({
    name,
    service,
  });
  const [modalLoading, setModalLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const add = useCallback(async (params: any) => {
    setModalLoading(true);
    await serviceAdd(params);
    setModalLoading(false);
    hide();
  }, []);

  const edit = useCallback(
    async (params: any) => {
      setModalLoading(true);
      await serviceEdit(selectedId, params);
      setModalLoading(false);
      hide();
    },
    [selectedId]
  );

  const submitData = useCallback(
    async (params?: any) => {
      // TODO: compare if there's changes
      if (!params) return;
      if (!selectedId) {
        add(params);
      } else {
        edit(params);
      }
    },
    [selectedId]
  );

  const openCreateModal = useCallback(() => {
    showModal('');
  }, []);

  const openEditModal = useCallback((id: string) => {
    showModal(id);
  }, []);

  const search = useCallback((text = '') => setSearchText(text), []);

  let filteredData = [...data];

  if (searchKeys.length) {
    filteredData = data.filter((ele: any) =>
      searchKeys.some((k) => compareStr(typeof ele[k] === 'string' ? ele[k] : ele[k]?.name ?? '', searchText))
    );
  }

  return {
    modalVisible: visible,
    modalTitle: title,
    modalSelectedId: selectedId,
    modalSelectedData: data.find((e: any) => e.id === selectedId || e._id === selectedId),
    modalLoading,
    openCreateModal,
    openEditModal,
    submitData,
    hideModal: hide,
    dataLoading: loading,
    data: filteredData,
    add,
    edit,
    destroy,
    list,
    search,
    destroyMany,
    searchText
  };
}

export function useFormInModal(
  visible = false,
  selected = undefined,
  parseData: (params?: any) => any,
  toData: (params?: any) => any,
  submit: (params?: any) => void
) {
  const [form] = useForm();
  useEffect(() => {
    if (visible && !selected) {
      form.resetFields();
    }
    if (visible && selected) {
      form.setFieldsValue({ ...parseData(selected) });
    }
  }, [visible, selected]);

  const submitForm = useCallback(async () => {
    const values = await form.validateFields();
    const params = toData(values);
    // modal submit will send create or edit request
    submit(params);
  }, [submit, form]);

  return { form, submitForm };
}
