import ISPList from 'components/visualizations/ISPList';
import { BaseOption, useSessionBreakdown } from 'containers/analytics/utils/hooks';
import { capitalize } from 'lodash';
import React from 'react';
import { useTranslate } from 'utils/intl';

const ISPTableWithData = ({ option }: { option: BaseOption }) => {
  const [t] = useTranslate();

  const { data: ispData, isLoading } = useSessionBreakdown({ option, by: 'isp', topN: 20 });

  return (
    <ISPList
      loading={isLoading}
      data={ispData ? ispData.map((v) => ({ ...v, key: v.name })) : []}
      title={capitalize(t('analytics.ispDim'))}
      colNames={t('analytics.ispColNames').split(',') as [string, string]}
    />
  );
};

export default ISPTableWithData;
