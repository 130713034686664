import { DownloadOutlined, ExpandOutlined, SyncOutlined } from '@ant-design/icons/lib';
import { css } from '@emotion/react';
import { Dropdown, Menu, Space } from 'antd';
import { Text } from 'components/base';
import LoadingSpin from 'components/misc/Loading';
import TipsIcon from 'components/misc/TipsIcon';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import React from 'react';
import styles from 'styles/layouts.module.less';

function MoreButton({ menu = null, onClick }: { onClick?: () => void; menu?: any }) {
  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 16px;
          padding: 0 4px;
          transform: scale(0.85);
        `}
      >
        {Array.from(Array(3).keys()).map((e) => (
          <span
            css={theme => css`
              height: 4px;
              width: 4px;
              border-radius: 2px;
              background: ${theme.colors.text};
            `}
            key={String(e)}
          />
        ))}
      </div>
    </Dropdown>
  );
}

export interface MoreAction {
  label: string;
  value?: string;
  icon?: React.ReactNode;
  onClick: (params: { id?: string; titleId?: string }) => void;
}

export interface ChartCardLayoutProps {
  children?: React.ReactNode;
  title?: string;
  className?: string;
  loading?: boolean;
  moreActions?: MoreAction[];
  style?: any;
  tipsText?: string;
  expandable?: boolean;
  refreshing?: boolean;
  extra?: React.ReactNode;
}

function generateFileName(title = 'unset') {
  return `${title.replace(/ /g, '_')}_${dayjs().format('hhmm-DDMMYY')}`;
}

async function exportPng(id = '') {
  // reset the css "width" style and svg "width" attribute
  const svgElements = document.body.querySelectorAll('svg');
  svgElements.forEach(function (item: any) {
    item.setAttribute('width', item.getBoundingClientRect().width);
    item.setAttribute('height', item.getBoundingClientRect().height);
    // eslint-disable-next-line no-param-reassign
    item.style.width = null;
    item.style.height = null;
  });

  const com = document.getElementById(id);
  if (!com) return;
  const w = com.offsetWidth;
  const h = com.offsetHeight;
  const scale = 2;

  let rect = com.getBoundingClientRect();
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

  const opt = {
    x: rect.left,
    y: rect.top,
    scrollY: -scrollTop,
    scale: scale,
    width: w,
    height: h,
  };

  const canvas = await html2canvas(com, opt);

  const context = canvas.getContext('2d');
  if (context) {
    // adapt different browser

    // @ts-ignore
    context.mozImageSmoothingEnabled = false;
    // @ts-ignore
    context.webkitImageSmoothingEnabled = false;
    // @ts-ignore
    context.msImageSmoothingEnabled = false;
    context.imageSmoothingEnabled = false;
  }
  canvas.toBlob(function (blob) {
    if (!blob) return;
    saveAs(blob, generateFileName(id));
  });
}

const defaultAction: MoreAction[] = [
  {
    label: 'Image (.png)',
    icon: <DownloadOutlined />,
    onClick: ({ id = '', titleId = '' }) => exportPng(id),
  },
  {
    label: 'Image with Title (.png)',
    icon: <DownloadOutlined />,
    onClick: ({ id = '', titleId = '' }) => exportPng(titleId),
  },
];

function generateRandomNumber(length = 4) {
  return Math.random().toFixed(length).substring(2);
}

const ChartCardLayout: React.FC<ChartCardLayoutProps> = ({
  children,
  title,
  className,
  loading,
  refreshing,
  moreActions,
  tipsText,
  ...props
}) => {
  const id = `eb-chart-${(title || '').toLowerCase()}-${generateRandomNumber()}`;
  const titleId = `eb-chart-title-${(title || '').toLowerCase()}-${generateRandomNumber()}`;

  function renderTitle() {
    if (!title) return null;
    return (
      <Text type="cardTitle" className={styles.chartCardTitle}>
        {title}
      </Text>
    );
  }

  function renderMoreBtn() {
    if (!moreActions) return null;

    const menu = (
      <Menu className={styles.menu}>
        {[...defaultAction, ...moreActions].map(({ label, value, icon, onClick }: MoreAction) => (
          <Menu.Item key={value || label} icon={icon ?? null} onClick={() => onClick({ id, titleId })}>
            {label}
          </Menu.Item>
        ))}
      </Menu>
    );
    return <MoreButton menu={menu} />;
  }

  return (
    <LoadingSpin loading={loading}>
      <div className={`${styles.chartCardContainer} ${className}`} id={titleId}>
        <div className={styles.chartCardHeaderRow}>{renderTitle()}</div>
        <div className={styles.chart} id={id}>
          {children || null}
        </div>
      </div>
      <Space className={styles.rightTopContainer}>
        {refreshing && <SyncOutlined spin />}
        {tipsText && <TipsIcon placement="bottom" iconClassName={styles.tipsIcon} tipsText={tipsText} />}
        {props.expandable ? <ExpandOutlined className={styles.iconExpansion}></ExpandOutlined> : null}
        {props.extra ?? null}
        {renderMoreBtn()}
      </Space>
    </LoadingSpin>
  );
};

ChartCardLayout.defaultProps = {
  moreActions: [],
  loading: false,
  tipsText: '',
};

export default ChartCardLayout;
