import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
import './breakdown.css';
import { ChartWrapperProps, withChartWrapper } from './functions/wrappers';
import { ChartConfig } from './options';
import './tooltip.css';
import { getOrCreate } from './utils';

export interface QualityPercent {
  fromPercent: number;
  toPercent: number;
  percent: number;
  cnt: number;
  color: string;
  key: string;
}

export interface QualityBreakdownPercentProps {
  data?: any;
  chartConfig?: ChartConfig;
  colors?: any[];
  reverseColor?: Boolean;
  valueFormater?: string;
}

export const QualityBreakdownPercent = withChartWrapper(function ({
  data,
  colors = ['#C13D59', '#F8D119', '#08C388'].reverse(),
  width,
  valueFormater = '%v',
}: QualityBreakdownPercentProps & ChartWrapperProps) {
  const ref = useRef<any>();

  const plotBar = (
    parent: d3.Selection<d3.BaseType, any, any, any>,
    plotBarWidth: number,
    height: number,
    configuration: QualityPercent[],
    container: any
  ) => {
    const x = d3.scaleLinear().domain([0, 100]).range([0, plotBarWidth]);

    parent
      .selectAll('.block')
      .data(configuration)
      .join('rect')
      .attr('x', (d) => x(d.fromPercent))
      .attr('width', (d) => x(d.percent))
      .attr('height', height)
      .attr('fill', (d) => d.color)
      .attr('class', 'block')
      .attr('transform', `translate(0, 5)`)
      .on('mouseenter mousemove', (e, f) => {
        d3.select(e.srcElement).attr('class', 'block block-hovered');
        const tooltipContainer = container.select('.tooltip-container');

        const left = Math.round(e.offsetX + 20) + 'px';
        const top = Math.round(e.offsetY - 20) + 'px';

        tooltipContainer.style('left', left).style('top', top).style('display', null);
        tooltipContainer
          .select('.tooltip-content')
          .html(
            `<b>${f.cnt}</b> viewers (<b>${f.percent.toFixed(2)}%</b>) <br/><b>${valueFormater.replaceAll(
              '%v',
              f.key
            )}</b>`
          );
      })
      .on('mouseleave', (e) => {
        const tooltipContainer = container.select('.tooltip-container');
        tooltipContainer.style('display', 'none');
        d3.select(e.srcElement).attr('class', 'block');
      });
  };

  useEffect(() => {
    const container = d3.select(ref.current);
    const svgElement = getOrCreate(container, 'breakdown-percent-svg', 'svg')
      .attr('height', data.length * 50)
      .attr('width', width);

    if (!data) {
      return;
    }

    data
      .map((_: any, i: number) =>
        getOrCreate(svgElement, `block-container-${i}`, 'g')
          .attr('height', 50)
          .attr('transform', `translate(0, ${i * 50 + 20})`)
      )
      .map((c: any, i: number) => {
        data[i].configuration = data[i].configuration.map((v: any, i: number) => {
          return {
            ...v,
            // percent: v.toPercent - v.fromPercent,
            color: colors[i],
          };
        });
        plotBar(c, width, 25, data[i].configuration, container);
        const sumViews = d3.sum(data[i].configuration.map((v: any) => v.cnt));

        getOrCreate(c, 'key-name', 'text').text(data[i].content + ` (${sumViews} views)`);
      });
  }, [data, width]);

  return (
    <div ref={ref}>
      <div className="tooltip-container" style={{ display: 'none' }}>
        <div className="tooltip-header"></div>
        <div className="tooltip-content"></div>
      </div>
      <div className="map-legend-container"></div>
      <svg className="breakdown-percent-svg"></svg>
    </div>
  );
});
