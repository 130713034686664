import { Form, Input, InputNumber, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { InputType } from './index';
import styles from './table.module.less';

interface TableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  handleSave: (record: any) => void;
  record: any;
  form: any;
  inputType: InputType;
  options: any[];
}

const TableCell: React.FC<TableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  handleSave,
  record,
  form,
  inputType,
  options,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>();

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  });

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async (e: any) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  function renderDataInput(type: InputType) {
    switch (type) {
      case InputType.NUMBER:
        return <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} style={{ width: '100%' }} />;
      case InputType.SELECT:
        return (
          <Select ref={inputRef} defaultOpen onChange={save} onBlur={save}>
            {options.map((r) => (
              <Select.Option key={r.id} value={r.id}>
                {r.name}
              </Select.Option>
            ))}
          </Select>
        );
      case InputType.MULTI_SELECT:
        // TODO: multi_select
        return (
          <Select
            defaultOpen
            maxTagTextLength={10}
            maxTagCount={5}
            options={options.map((t: any) => ({ value: t }))}
            ref={inputRef}
            defaultValue={record[dataIndex]}
            mode="multiple"
            placeholder="Please select"
            onBlur={save}
          />
        );
      case InputType.INPUT:
      default:
        return (
          <Input.TextArea
            style={{ wordBreak: 'break-all' }}
            autoSize={{ minRows: 1, maxRows: 5 }}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        );
    }
  }

  if (editable) {
    if (editing) {
      childNode = (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {renderDataInput(inputType)}
        </Form.Item>
      );
    } else {
      childNode = (
        <div className={styles.editableCellWrap} onClick={toggleEdit}>
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

export default TableCell;
