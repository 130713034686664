import { Tooltip } from 'antd';
import { Text } from 'components/base';
import EditableCellTable from 'components/input/EditableCellTable';
import { useFilters } from 'contexts/filters';
import { useRouterActions } from 'contexts/router';
import ReactEcharts from 'echarts-for-react';
import ChartCardLayout from 'layouts/ChartCardLayout';
import React from 'react';
import { DefaultService } from 'services';
import { humanReadableSize } from 'utils/dataproc';
import { useTranslate } from 'utils/intl';
import { Notification } from 'utils/notification';
import styles from './contentTable.module.less';

interface ContentTableProps {
  apikeys: any[];
  newContents: any[];
}

export const renderPieChart = (cdn: any, p2p: any) => (
  <ReactEcharts
    style={{ height: 34, width: 34 }}
    option={{
      color: ['#f8d119', '#1fa9dd'],
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {d}%',
      },
      series: [
        {
          type: 'pie',
          labelLine: {
            show: false,
          },
          label: {
            show: false,
          },
          hoverAnimation: false,
          left: 0,
          data: [
            {
              name: 'v2v',
              value: p2p,
            },
            {
              name: 'cdn',
              value: cdn,
            },
          ],
          radius: ['50%', '100%'],
        },
      ],
    }}
  />
);

function ContentTable({ contents = [], contentTableItems = [], onContentSelected = null }: any) {
  const { apiKey, currentApiKeyValue, loadContents } = useFilters();
  const [t] = useTranslate();
  const { goTo } = useRouterActions();

  //   {
  //     "status": "PENDING",
  //     "tags": [],
  //     "createdBy": "import_channel_schedule",
  //     "_id": "610ac3dc8114680034bca291",
  //     "name": "ge.com/gc/live/ramdisk/demo_hd/hls_ts/demo_hd.m3u8",
  //     "url": "https://demo.mdb.cdn.orange.com/gc/live/ramdisk/demo_hd/hls_ts/demo_hd.m3u8",
  //     "apiKey": {
  //         "name": "globecast",
  //         "key": "globecast"
  //     },
  //     "__v": 0,
  //     "createdAt": "2021-08-04T16:44:12.961Z",
  //     "updatedAt": "2021-08-04T16:44:12.961Z"
  // }

  // console.info(contentTableItems[0])
  //   {
  //     "key": "https://cdnamd-hls-globecast.akamaized.net/live/ramdisk/al_aoula_inter/hls_snrt/al_aoula_inter.m3u8",
  //     "p2p": 3705644650.3999996,
  //     "cdn": 9198939865.599998,
  //     "visits": 50,
  //     "v2v": [
  //         9198939865.599998,
  //         3705644650.3999996
  //     ],
  //     "url": "https://cdnamd-hls-globecast.akamaized.net/live/ramdisk/al_aoula_inter/hls_snrt/al_aoula_inter.m3u8"
  // }

  const columns: any[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: {
        showTitle: false,
      },
      width: 200,
      render: (text: any, record: any) => {
        const ci = contents.find((c) => c.url === record.url);
        const label = ci ? ci.name : t('analytics.tooltips.toImport');
        return (
          <Tooltip
            placement="topLeft"
            title={ci ? t('analytics.tooltips.clickToEdit') : t('analytics.tooltips.clickToImport')}
          >
            <Text
              type="link"
              onClick={async () => {
                if (ci) {
                  goTo('/contents', { id: ci._id, url: record.url });
                } else {
                  if (apiKey.includes('*')) {
                    Notification.error('select api key first');
                    return;
                  }
                  try {
                    await DefaultService.channels.create({
                      url: record.url,
                      name: record.url.slice(-50),
                      apiKey: currentApiKeyValue._id,
                    });
                    await loadContents();
                    Notification.success('import success');
                  } catch (e) { }
                }
              }}
            >
              {label}
            </Text>
          </Tooltip>
        );
      },
      align: 'left' as any,
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
      responsive: ['md'],
    },
    {
      title: 'Visits',
      dataIndex: 'visits',
      sorter: (a, b) => a.visits - b.visits,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      key: 'visits',
      width: 75,
    },
    {
      title: 'VAD',
      dataIndex: 'v2v',
      key: 'v2v',
      align: 'center',
      sorter: (a, b) => a.v2v?.[1] ?? 0 - b.v2v?.[1] ?? 0,
      sortDirections: ['descend', 'ascend'],
      render: (data_usages: number[]) => {
        const cdn = data_usages[0];
        const p2p = data_usages[1];
        return (
          <div className={styles.pieContainer}>
            <span className={styles.pie}>{renderPieChart(cdn, p2p)}</span>
            <span className={styles.pieText}>
              <span>cdn: {humanReadableSize(cdn)}</span>
              <span>v2v: {humanReadableSize(p2p)}</span>
            </span>
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'name',
      key: 'action',
      ellipsis: {
        showTitle: false,
      },
      render: (text: any, record: any) => {
        const ci = contents.find((c) => c.url === record.url);
        return (
          <Tooltip
            placement="topLeft"
            title={ci ? t('analytics.tooltips.clickToEdit') : t('analytics.tooltips.clickToImport')}
          >
            <Text
              type="link"
              onClick={async () => {
                // console.info("yooooooooooooooooo", record.url, apiKey)
                const start = new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 7).toISOString();
                const end = new Date().toISOString();
                goTo(`/analytics/content?content=${record.url}&api_key=${apiKey}&start=${start}&end=${end}`);
              }}
            >
              See Details
            </Text>
          </Tooltip>
        );
      },
    },
  ];
  return (
    <ChartCardLayout title="Contents">
      <EditableCellTable
        dataSource={contentTableItems}
        columns={columns}
        scroll={{ x: 550, y: 1000 }}
        pagination={{ pageSize: 5 }}
        rowSelection={{
          selectionType: 'radio',
          onChange: onContentSelected,
        }}
      />
    </ChartCardLayout>
  );
}

export default ContentTable;
