import { Button, Dropdown, Menu } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { DateRange } from 'react-date-range';
import themeStyle from 'styles/theme.less';
import { TimeRangeOption } from '../../../utils/days';
import styles from './datePicker.module.less';

export interface TimeRangeChangeParams {
  optionKey?: string;
  startStr?: string;
  endStr?: string;
  start?: dayjs.Dayjs;
  end?: dayjs.Dayjs;
}

export interface DatePickerProps {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  timeRangeOptions: TimeRangeOption[];
  onRangeChange: (params: TimeRangeChangeParams) => void;
  children: React.ReactNode;
  selectedTimeRangeKey: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  children,
  onRangeChange,
  startDate,
  endDate,
  timeRangeOptions,
  selectedTimeRangeKey,
}) => {
  const [dateRangeSelectState, setDateRangeSelectState] = React.useState([] as any[]);
  const [panelVisible, setPanelVisible] = React.useState(false);

  React.useEffect(() => setDateRangeSelectState([]), [panelVisible]);

  const dateRangeState =
    dateRangeSelectState.length === 0
      ? [
          {
            startDate: new Date(startDate.format()),
            endDate: new Date(endDate.format()),
            key: 'selection',
          },
        ]
      : dateRangeSelectState;

  const handleItemClick = ({ key }: any) => {
    onRangeChange({ optionKey: key });
    setPanelVisible(false);
  };

  const handleCustomizedRange = () => {
    if (dateRangeSelectState.length) {
      if (dateRangeSelectState[0].startDate && dateRangeSelectState[0].endDate) {
        onRangeChange({
          start: dayjs(dateRangeSelectState[0].startDate).hour(4).minute(0).second(0).millisecond(0),
          end: dayjs(dateRangeSelectState[0].endDate).add(1, 'day').hour(3).minute(59).second(59).millisecond(999),
        });
        setPanelVisible(false);
      }
    }
  };

  const onChange = (item: any) => setDateRangeSelectState([item.selection]);

  return (
    <Dropdown
      visible={panelVisible}
      onVisibleChange={(visible) => setPanelVisible(visible)}
      overlay={
        <div className={styles.container}>
          <div className={styles.horizon}>
            <DateRange
              rangeColors={[themeStyle.primaryColor]}
              editableDateInputs
              onChange={onChange}
              moveRangeOnFirstSelection={false}
              ranges={dateRangeState}
              minDate={new Date(new Date().getTime() - 180 * 24 * 60 * 60 * 1000)}
              maxDate={new Date()}
            />
            <Menu onClick={handleItemClick} selectedKeys={[selectedTimeRangeKey]} className={styles.menu}>
              {timeRangeOptions.map((v) => (
                <Menu.Item key={v.key} className={styles.menuItem}>
                  {v.display || v.key}
                </Menu.Item>
              ))}
            </Menu>
          </div>
          {dateRangeSelectState.length ? (
            <div className={styles.bottom}>
              <span>Customized date</span>
              <Button type="primary" size="small" onClick={handleCustomizedRange}>
                Apply
              </Button>
            </div>
          ) : null}
        </div>
      }
      trigger={['click']}
      placement="bottomRight"
    >
      {children}
    </Dropdown>
  );
};

export default DatePicker;
