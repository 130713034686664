import { Col, Row } from 'antd';
import { Text } from 'components/base';
import Barchart from 'components/visualizations/Barchart';
import { ChartConfig, PaddingConfig } from 'components/visualizations/options';
import ChartCardLayout from 'layouts/ChartCardLayout';
import React from 'react';
import { useTranslate } from 'utils/intl';
import styles from './barchartCard.module.less';
const chartConfig = new ChartConfig();
chartConfig.paddings = new PaddingConfig();
chartConfig.paddings.left = -10;
chartConfig.paddings.right = -10;
chartConfig.height = 130;

export interface BarChartCardProps {
  data?: any;
  summaryText?: string;
  intlKey: string;
  unit?: string;
  extra?: string;
}

const BarChartCard: React.FC<any> = ({ data = [], summaryText = '', intlKey, unit = '', extra = '', ...args }) => {
  const [t] = useTranslate();
  return (
    <ChartCardLayout title={t(intlKey)} {...args}>
      <Row align="middle">
        <Col flex="auto">
          <Text strong style={{ fontSize: 20 }}>
            <Text style={{ fontSize: 28 }}>{summaryText}</Text>
            &nbsp;{unit}
          </Text>
        </Col>
        {extra && (
          <Col>
            <span className={styles.barChartCardExtra}>{extra}</span>
          </Col>
        )}
      </Row>
      <Barchart data={data} barPadding={0.35} chartConfig={chartConfig} />
    </ChartCardLayout>
  );
};

export default BarChartCard;
