import { Col, Row, Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useFilters } from 'contexts/filters';
import React from 'react';
import { useTranslate } from 'utils/intl';
import { getToken } from 'utils/localStorage';
import { withLayout } from './utils/withLayout';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ChartCardLayout from 'layouts/ChartCardLayout';
import EditableCellTable from 'components/input/EditableCellTable';


const blockStyle = { backgroundColor: 'white', padding: '1rem', borderRadius: '10px', marginBottom: '1rem' };

interface streamData {
  stream_name_type: string;
  gb: number;
}

const CDNAnalyticsPage = () => {
  const [t] = useTranslate();
  const { apiKey, from, to } = useFilters();
  const jwtToken = getToken().accessToken;
  const [data, setData] = useState<streamData[]>([]);

  /* 
    Purge cache button handler
  */
  const handleButtonClick = async () => {
    try {
      const purgeUrl = "https://api.bunny.net/pullzone/1654017/purgeCache";
      const purgeHeaders = {
        "content-type": "application/json",
        "AccessKey": "91484099-24f0-42c3-b045-b236c3c2fa63678caccf-33e7-40e1-8a16-b5e6c4cdcd70"
      };

      const purgeResponse = await axios.post(purgeUrl, null, { headers: purgeHeaders });

      if (purgeResponse.status === 204) {
        window.alert("Cache purge successful");
      }
      else {
        window.alert("Cache purge failed, please contact operator");
      }
    } catch (error) {
      console.error('Cache purge error:', error);
    }
  };

  /* 
    Fetch CDN bandwidth data using API
  */
  useEffect(() => {
    const fetchCDNData = async () => {
      try {
        const response = await axios.post("https://api.pixa.cdn.easybroadcast.fr/pixagility", {
          starttime: from,
          endtime: to,
        });
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (apiKey === 'pixagility') {
      fetchCDNData();
    }
  }, [apiKey, from, to])

  /** 
   * Fill in TableColumns to be displayed in the chart table
  */
  const tableColumns = [
    {
      title: 'Stream link',
      dataIndex: 'stream_name_type',
      key: 'stream_name_type',
    },
    {
      title: Object.keys(data?.[0] || {})[1]?.toUpperCase(),
      dataIndex: Object.keys(data?.[0] || {})[1],
      key: Object.keys(data?.[0] || {})[1],
    },
    {
      title: 'Max Audience',
      dataIndex: 'max_audience',
      key : 'max_audience'
    }
  ]

  /** 
   * Render the page
   * Display when apiKey===pixagility: CDN bandwidth data in a table + Purge Cache button
   * Display when apiKey!==pixagility: empty page
  */
  if (apiKey === 'pixagility') {
    return (
      <ChartCardLayout title="Bandwidth">
      <EditableCellTable
        dataSource={data}
        columns={tableColumns}
        scroll={{ x: 550, y: 1000 }}
        pagination={{ pageSize: 10 }}
      />
      <Button onClick={handleButtonClick}>Purge Cache</Button>
      </ChartCardLayout>
      
      )
  }
  else{
      return (
        <Content style={{ padding: '20px', maxWidth: 1200, margin: 'auto' }}>
          <Row style={blockStyle}>
            <Col span={24}>
              {apiKey === '*' ? (
                <>{t('msg.selectApiKey')}</>
              ) : (
                <iframe
                  title="Daily Visits"
                  scrolling="no"
                  style={{ overflow: 'hidden' }}
                  frameBorder={0}
                  width="100%"
                  height="1500"
                  src={`https://api.easybroadcast.fr/data/dash/cdn_usage?token=${jwtToken}&api_key=${apiKey}&start=${from.toISOString()}&end=${to.toISOString()}`}
                />
              )}
            </Col>
          </Row>
        </Content>
      );
  }
};

export default withLayout(CDNAnalyticsPage);
