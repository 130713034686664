import React, { useRef } from 'react';
import { SizeMe } from 'react-sizeme';

export interface ChartWrapperProps {
  width: number;
  height?: number;
}

export function withChartWrapper(WrappedComponent: React.ComponentType<any>) {
  return function (props: any) {
    const wrapperRef = useRef(null as any);

    const { className = '', style = {}, ...rest } = props;
    return (
      <SizeMe>
        {({ size }) => (
          <div ref={wrapperRef} className={`${className} react-d3-container`} style={style}>
            <WrappedComponent {...rest} width={props.width || size.width || 800} height={size.height} />
          </div>
        )}
      </SizeMe>
    );
  };
}
