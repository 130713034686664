import RolePage from 'containers/accounts/user/RolePage';
import UserPage from 'containers/accounts/user/UserPage';
import TopTabsLayout from 'layouts/TopTabsLayout';
import React from 'react';
import { ACCESS_FEATURES, Status } from 'utils/enums';

export default function () {
  const tabs = [
    {
      key: 'users',
      title: 'page.user.users',
      component: <UserPage />,
    },
    {
      key: 'roles',
      title: 'page.user.roles',
      component: <RolePage />,
    },
    {
      key: 'new_users',
      title: 'page.user.newUsers',
      component: <UserPage userStatus={Status.WAITING} />,
      authorize: [ACCESS_FEATURES.INTERNAL],
    },
    {
      key: 'disabled_users',
      title: 'page.user.disabledUsers',
      component: <UserPage userStatus={Status.DISABLED} />,
      authorize: [ACCESS_FEATURES.INTERNAL],
    },
  ];
  return <TopTabsLayout tabs={tabs} />;
}
