import { css } from '@emotion/react';
import { Slider, Space } from 'antd';
import { Col, Row } from 'components/antd';
import FormWrap, { FormItemWrap, useForm } from 'components/antd/FormWrap';
import { EButton, Text } from 'components/base';
import CardPageLayout from 'layouts/CardPageLayout';
import React from 'react';
import { useTranslate } from 'utils/intl';
import FilterItemGroup from './FilterItem';
import { useModel } from './functions';
import PredictionPie from './PredictionPie';


const styles = {
  form: css`
    max-width: 500px;
    margin-top: 24px;
  `,
};

function VADConfigPage() {
  const [t] = useTranslate();
  const [form] = useForm();
  const { reset, onFormValueChanged, save, formFilters, apiKeys, predict, updatePredict } = useModel(form);
  return (
    <CardPageLayout>
      <CardPageLayout.Title>{t('page.v2vConfig.title')}</CardPageLayout.Title>
      <Row gutter={[10, 10]}>
        <Col sm={24} md={16}>
          <Text>{t('page.v2vConfig.pageDesc')}</Text>
        </Col>
        <Col sm={24} md={16}>
          <FormWrap form={form} name="VToVConfig" css={styles.form} onValuesChange={onFormValueChanged} onFinish={save}>
            <FormItemWrap.Select label="column.apikeys" name="_id" options={apiKeys} required={false} />
            <FormItemWrap label="page.v2vConfig.percentage" name="allowed_percentage" required={false}>
              <Slider
                tipFormatter={(value) => `${value}%`}
                onAfterChange={() => {
                  const allValues = form.getFieldsValue();
                  updatePredict(allValues);
                }}
              />
            </FormItemWrap>
            <FilterItemGroup items={formFilters} />
            <Space>
              <EButton text="button.reset" onClick={reset} />
              <EButton _type="solid" text="button.save" htmlType="submit" />
            </Space>
          </FormWrap>
        </Col>
        <Col sm={24} md={8}>
          <PredictionPie {...predict} />
        </Col>
      </Row>
    </CardPageLayout>
  );
}
export default VADConfigPage;
