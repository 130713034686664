import dayjs from 'dayjs';

export function numberFormatter(value: number) {
  if (value > 1000000) return `${value / 1000000} M`;
  if (value > 1000) return `${value / 1000}K`;
  return value % 1 === 0 ? value : value.toFixed(2);
}

export function dateFormatter(value: any) {
  return dayjs(value).format('hh:mm DD/MM/YY');
}

export function pieLabelFormatter({ name, percent }: any) {
  let displayName = name;
  if (name.length > 8) {
    displayName = displayName.replace(/ /g, '\n');
  }
  return `${displayName}\n${percent}%`;
}
