import { Col, Row } from 'components/antd';
import { Text } from 'components/base';
import MapDataList from 'components/visualizations/MapDataList';
import { MapView } from 'components/visualizations/MapView';
import { BaseOption, useMapData } from 'containers/analytics/utils/hooks';
import { fileSize } from 'humanize-plus';
import ChartCardLayout from 'layouts/ChartCardLayout';
import React from 'react';
import styles from './audience-map.module.less';

function MapViewWithTable(props: any) {
  const totalViewers =
    props.data?.features?.map((v: any) => v.properties.views).reduce((p: number, c: number) => p + c, 0) ?? 0;

  const { useTable } = props;
  let mapWidth = 24;

  if (useTable) {
    mapWidth = 17;
  }

  return (
    <Row gutter={[10, 5]}>
      <Col xs={24} sm={24} md={mapWidth} lg={mapWidth} xl={mapWidth}>
        <MapView {...props} />
        <div className={styles.totalViewersBlock}>
          <Text type="cardTitle" style={{ fontSize: 13 }}>
            Total Views: {totalViewers}
          </Text>
        </div>
      </Col>
      {useTable ? (
        <Col xs={24} sm={24} md={24 - mapWidth} lg={24 - mapWidth} xl={24 - mapWidth}>
          <MapDataList data={props.data} />
        </Col>
      ) : null}
    </Row>
  );
}

const AudienceMapWithData = ({ option, useTable }: { option: BaseOption; useTable?: boolean }) => {
  const mapData = useMapData({ option });
  const mapAes = {
    qualityMap: {
      colors: ['#C13D59', '#F8D119', '#08C388'],
      lowerBounds: [568309, 900000],
      labels: ['SD', 'MD', 'HD'],
    },
    tileLayer: {
      color: 'views',
      tooltipCol: [
        {
          formatter: (x: any) => x,
          col: 'views',
          title: 'Views',
        },
        {
          formatter: (x: any) => fileSize(x || 0),
          col: 'total',
          title: 'Data',
        },
      ],
    },
  };

  return (
    <ChartCardLayout
      title="Geographical Dimension"
      moreActions={[
        { ...MapDataList.downloadCSVAction, onClick: () => MapDataList.downloadCSVAction.onClick(mapData) },
      ]}
    >
      <MapViewWithTable tileServer={0} aes={mapAes} data={mapData} useTable={useTable} />
    </ChartCardLayout>
  );
};

export default AudienceMapWithData;
