import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, TableProps, Tooltip } from 'antd';
import { Text } from 'components/base';
import EditableCellTable from 'components/input/EditableCellTable';
import React, { useCallback, useState } from 'react';
import { useTranslate } from '../../utils/intl';
import styles from './index.module.less';
import TagGroup from './TagGroup';

export function ActionBtn({ icon, onClick = () => {} }: any) {
  return (
    <Button
      type="text"
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {icon}
    </Button>
  );
}

export function DefaultActionButtons({ edit = () => {}, destroy = () => {} }) {
  return (
    <span>
      <ActionBtn icon={<EditOutlined />} onClick={edit} />
      <ActionBtn icon={<DeleteOutlined />} onClick={destroy} />
    </span>
  );
}

function useRowSelect(defaultValue: string[]) {
  const [rowSelection, setRowSelection] = useState(defaultValue);
  const onRowSelect = useCallback((record: any) => {
    if (!record || !record._id) return;
    const id = record._id;
    setRowSelection((prevState) => {
      // remove current clicked record
      if (prevState.includes(id)) {
        return prevState.filter((ele) => ele !== id);
      }
      // add current clicked record
      return [...prevState, id];
    });
  }, []);
  const onChange = useCallback((selectedRowKeys: string[]) => setRowSelection(selectedRowKeys), []);
  return { selectedRowKeys: rowSelection, onRowSelect, onChange };
}

function ActionBtnGroup({ actions = [], rowSelection = [] }: { actions: any[]; rowSelection: string[] }) {
  const [t] = useTranslate();
  if (!actions.length) return null;
  const disabled = rowSelection.length === 0;

  const { Item } = Menu;
  const actionMenu = (
    <Menu>
      {actions.map(({ label = '', onClick }: any, index: number) => (
        <Item key={label || String(index)} onClick={() => onClick && onClick(rowSelection)}>
          {t(label)}
        </Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown disabled={disabled} overlay={actionMenu}>
        <Button type="primary" shape="round" style={{ width: 110 }}>
          {t('common.actions')} <DownOutlined />
        </Button>
      </Dropdown>
      <div style={{ height: 16 }} />
    </>
  );
}

interface TableWrapProps extends TableProps<any> {
  data: any[];
  edit: (id: string) => void;
  destroy: (id: string) => void;
  destroyMany?: (ids: string[]) => void;
  mulSelectActionButtons?: { label: string; onClick: (ids: string[]) => void }[];
  actionButtons?: { icon: React.ReactNode; onClick: (id: string) => void }[];
}

function TableWrap(props: TableWrapProps) {
  const { columns: originalColumn = [], data, edit, destroy } = props;
  const [currentHoverId, setCurrentHoverId] = useState('');
  const { selectedRowKeys, onChange, onRowSelect } = useRowSelect([]);

  const onRow = useCallback(
    (record: any) => ({
      onClick: () => {
        if (record.readOnly) return;
        onRowSelect(record);
      },
      onMouseEnter: () => {
        setCurrentHoverId(record?.id || record?._id || '');
      }, // mouse enter row
      onMouseLeave: () => {
        setCurrentHoverId('');
      }, // mouse leave row
    }),
    [selectedRowKeys]
  );

  const columns = [
    ...originalColumn.map((col: any) => {
      if (col.clickToEdit) {
        return {
          render: (v: any, record: any) => {
            if (record.readOnly) return v;
            return (
              <Text
                type="link"
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (record._id || record.id) {
                    edit(record._id || record.id);
                  }
                }}
              >
                {v}
              </Text>
            );
          },
          ...col,
        };
      }
      if (col.showWithTips) {
        return {
          render: (v) => (
            <Tooltip placement="topLeft" title={v}>
              {v}
            </Tooltip>
          ),
          ellipsis: {
            showTitle: false,
          },
          ...col,
        };
      }
      if (col.showAsTags) {
        return {
          render: (ele: string[]) => <TagGroup options={ele} />,
          ...col,
        };
      }
      return { ...col };
    }),
    // show actions buttons
    {
      title: ' ',
      dataIndex: '_id',
      width: 100,
      align: 'end',
      className: styles.tableAction,
      render: (id: string, record) => {
        if (currentHoverId !== id || record?.readOnly) return null;
        if (props.actionButtons)
          return (
            <span>
              {props.actionButtons.map((btn) => (
                <ActionBtn {...btn} />
              ))}
            </span>
          );
        return <DefaultActionButtons edit={() => edit(id)} destroy={() => destroy(id)} />;
      },
    },
  ];

  const mulSelectActions = [
    // props contains destroy many action, display it
    ...(props.destroyMany ? [{ label: 'common.delete', onClick: props.destroyMany }] : []),
    // other multi selection actions
    ...(props.mulSelectActionButtons ?? []),
  ];

  return (
    <>
      <ActionBtnGroup actions={mulSelectActions} rowSelection={selectedRowKeys} />
      <EditableCellTable
        {...props}
        columns={columns}
        dataSource={data.map((e: any) => ({ ...e, originalKey: e.key, key: e._id }))}
        pagination={{ position: ['bottomRight'], size: 'small' }}
        onRow={onRow}
        scroll={{ x: 600 }}
        rowSelection={{
          selectedRowKeys,
          onChange,
          columnWidth: 60,
          getCheckboxProps: (record: any) => ({
            disabled: !!record.readOnly, // Column configuration not to be checked
            // name: record.name,
          }),
        }}
      />
    </>
  );
}

export default TableWrap;
