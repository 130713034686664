import messagesEn from './en.json';
import messagesFr from './fr.json';

export type LanguageType = 'en' | 'fr';

const Language: Record<LanguageType, { name: string; messages: any }> = {
  en: { name: 'English', messages: messagesEn },
  fr: { name: 'Français', messages: messagesFr },
};

export default Language;
