import { Drawer, Layout, Menu } from 'antd';
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'contexts/auth';
import { usePath, useRouterActions } from 'contexts/router';
import { useTranslate } from '../../utils/intl';
import MenuTopEBIcon from './MenuTopEBIcon';
import { useLayout } from '../model';
import styles from '../../styles/navigation.module.less';

function MenuContent() {
  const { menuCollapsed } = useLayout();
  const { routes, flattenRoutes } = useAuth();
  const { goTo } = useRouterActions();
  const path = usePath();
  const [t] = useTranslate();

  const currentSelectedKey: string[] = flattenRoutes.filter((r) => r.path === path).map((r) => r.key);

  const [openKeys, setOpenKeys] = useState<any[]>([]);

  // useEffect(() => {
  //   if (routes.length > 0 && !menuCollapsed) {
  //     setOpenKeys(routes.filter((r) => r.path === path && !!r.parent).map((r) => r.parent));
  //   }
  // }, [routes.length, path, menuCollapsed]);

  const onSelect = useCallback(
    function ({ key: selectedKey }) {
      const selectedRoute = flattenRoutes.find(({ key }) => selectedKey === key);
      if (selectedRoute) {
        goTo(selectedRoute.path);
      }
    },
    [goTo, flattenRoutes]
  );

  const onOpenChange = useCallback((keys) => {
    setOpenKeys((prev) => {
      const latestOpenKey = keys.find((key) => prev.indexOf(key) === -1);
      return latestOpenKey ? [latestOpenKey] : [];
    });
  }, []);

  function renderMenuItems(r) {
    if (typeof r.showInLeftMenu !== 'undefined' && !r.showInLeftMenu) return null;
    const renderIcon = (icon) => (icon ? React.createElement(icon, { style: { fontSize: '1rem' } }) : null);
    if (r.children?.length > 0) {
      return (
        <Menu.SubMenu popupClassName={styles.popup} key={r.key} title={t(r.title)} icon={renderIcon(r.icon)}>
          {r.children.map(renderMenuItems)}
        </Menu.SubMenu>
      );
    }

    return (
      <Menu.Item key={r.key} icon={renderIcon(r.icon)}>
        {t(r.title)}
      </Menu.Item>
    );
  }

  return (
    <>
      <MenuTopEBIcon />
      <Menu
        css={css`
          background: transparent;
          width: 100%;
          font-size: 1rem;
        `}
        theme="dark"
        mode="inline"
        onSelect={onSelect}
        defaultOpenKeys={routes.filter((r) => r.children?.length > 0).map((r) => r.key)}
        selectedKeys={currentSelectedKey}
        // onOpenChange={onOpenChange}
      >
        {routes.map(renderMenuItems)}
      </Menu>
    </>
  );
}

function LeftMenu({ children }: any) {
  const { mobileMode, menuCollapsed, triggerCollapsed } = useLayout();

  if (mobileMode) {
    return (
      <Drawer
        // css={css`
        //   overflow: auto;
        //   height: 100vh;
        //   position: fixed;
        //   left: 0;
        // `}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
        placement="left"
        onClose={() => triggerCollapsed(false)}
        visible={menuCollapsed}
        closable={false}
        bodyStyle={{
          height: '100vh',
          padding: 0,
        }}
        width={256}
      >
        <Layout.Sider
          style={{
            minHeight: '100vh',
          }}
          width={256}
          className={styles.side}
        >
          {children}
        </Layout.Sider>
      </Drawer>
    );
  }

  if (!mobileMode) {
    return (
      <Layout.Sider
        className={styles.side}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
        trigger={null}
        collapsible
        collapsed={menuCollapsed}
        breakpoint="lg"
        onBreakpoint={triggerCollapsed}
        collapsedWidth={64}
      >
        {children}
      </Layout.Sider>
    );
  }
  return null;
}

export default function () {
  return (
    <LeftMenu>
      <MenuContent />
    </LeftMenu>
  );
}
