import { List, Progress, Space } from 'antd';
import { Text } from 'components/base';
import Loading from 'components/misc/Loading';
import ChartCardLayout from 'layouts/ChartCardLayout';
import React from 'react';
import styles from './isplist.module.less';

function ListHeader({ title, value }: { title: string; value: string }) {
  return (
    <div className={styles.listHeader}>
      <Text type="cardTitle" className={styles.listHeaderId}>
        {title}
      </Text>
      <Text type="cardTitle" className={styles.listHeaderVisit}>
        Visits
      </Text>
    </div>
  );
}
interface ISPListProps {
  data?: any;
  loading?: boolean;
  title?: string;
  colNames?: [string, string];
}

const ISPList: React.FC<ISPListProps> = ({
  loading = false,
  data = [],
  title = 'ISP dimension',
  colNames = ['Name', 'Visits'],
}) => {
  return (
    <ChartCardLayout title={title}>
      <ListHeader title={colNames[0]} value={colNames[1]} />
      <Loading loading={loading} />
      <div style={{ height: '278px', overflowY: 'scroll' }}>
        <List
          size="small"
          className={styles.list}
          renderItem={(d: any) => {
            return (
              <>
                <List.Item key={d.key} style={{ padding: 0 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Space>{d.key}</Space>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <div>{d.value}</div>
                      <div style={{ width: '5rem' }}>
                        <Progress percent={Math.round(d.percent)} />
                      </div>
                    </div>
                  </div>
                </List.Item>
              </>
            );
          }}
          dataSource={data}
        />
      </div>
    </ChartCardLayout>
  );
};

export default ISPList;
