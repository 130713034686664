import PublicService from '../services/public';
import { createAxiosInstance } from './common';
import { getToken, setToken } from './localStorage';

const requestFactory = (url?: string, timeout?: number, wrapRes = true) => {
  // const instance = createAxiosInstance(url, timeout);

  const retryInstance = createAxiosInstance(url, timeout);

  function getJWTHeader() {
    const tokens = getToken();
    if (!tokens) {
      return {};
    }
    const { accessToken } = tokens;
    return { Authorization: `Bearer ${accessToken}`, 'X-Dash-Path': window.location.pathname };
  }

  async function refreshToken() {
    const tokens = getToken();
    if (!tokens) {
      return;
    }
    try {
      const res = await PublicService.refresh({
        refreshToken: tokens.refreshToken,
      });
      setToken({
        accessToken: res.accessToken,
        refreshToken: res.refreshToken,
      });
    } catch (e) {
      console.log('refresh token failed');
      setToken();
      throw e;
    }
  }

  retryInstance.interceptors.request.use(
    async function (config) {
      // Do something before request is sent
      return { ...config, headers: getJWTHeader() };
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  retryInstance.interceptors.request.use(
    async function (config) {
      // Do something before request is sent

      return { ...config, headers: getJWTHeader() };
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  retryInstance.interceptors.response.use(
    function (response) {
      if (!wrapRes) return response;
      if (response?.data.code === 0) {
        return response.data.data;
      }
      return response;
    },
    async function (error) {
      if (!wrapRes) return Promise.reject(error);
      if (error.response?.status === 401) {
        try {
          await refreshToken();
          const retry = await retryInstance.request(error.config);
          if (retry.data) {
            return retry.data.data;
          }
          return retry;
        } catch (e) {
          return Promise.reject(e);
        }
      }
      if (error.response?.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error);
    }
  );
  return retryInstance;
};

const instance = requestFactory();

export default instance;
export { requestFactory };
