import React, { useState, useEffect, useCallback } from 'react';
import { Notification } from 'utils/notification';
import { getIntlInstance } from 'utils/intl';

type LoadingType = boolean | 'loading' | 'refreshing';

function t(id: string = 'unset', values?: Record<string, string>) {
  const intl = getIntlInstance();
  if (!intl) return id;
  return intl.formatMessage({ id, defaultMessage: id }, values);
}

export function useRestFetcher({ offset: defaultOffset = 0, limit: defaultLimit = -1, name = 'Item', service }: any) {
  const [loading, setLoading] = useState('loading' as LoadingType);
  const [data, setData] = useState([] as any[]);

  const load = useCallback(
    async ({ offset = defaultOffset, limit = defaultLimit } = {}) => {
      setLoading('loading');
      try {
        const res = await service.list({ offset, limit });
        setData(res);
      } catch (e) {
        Notification.error(e.message);
      }
      setLoading(false);
    },
    [defaultOffset, defaultLimit]
  );

  const add = useCallback(
    async (params: any) => {
      let res;
      try {
        res = await service.create(params);
        await load();
        Notification.success(`create ${t(name)} success`);
      } catch (e) {
        Notification.error(e.message);
      }
      return res;
    },
    [name, service]
  );

  const edit = useCallback(
    async (id: string, params: any) => {
      let res;
      try {
        res = await service.update(id, params);
        await load();
        Notification.success(`edit ${t(name)} success`);
      } catch (e) {
        Notification.error(e.message);
      }
      return res;
    },
    [name, service]
  );

  const destroy = useCallback(
    async (id: string) => {
      let res;
      try {
        res = await service.destroy(id);
        await load();
        Notification.success(`delete ${t(name)} success`);
      } catch (e) {
        Notification.error(e.message);
      }
      return res;
    },
    [name, service]
  );

  const destroyMany = useCallback(
    async (ids: string[]) => {
      try {
        await Promise.all(
          ids.map(async (id) => {
            await service.destroy(id);
          })
        );
        load();
        Notification.success(`delete ${t(name)}s success`);
      } catch (e) {
        Notification.error(e.message);
      }
    },
    [name, service]
  );

  useEffect(() => {
    load();
  }, []);

  return { loading, data, add, edit, destroy, list: load, destroyMany };
}

export function useSingleFetcher(request: (...params: any) => Promise<any>) {
  const [loading, setLoading] = useState(false);
  const _request = useCallback(
    async (...params) => {
      setLoading(true);
      let res;
      try {
        res = await request(...params);
      } catch (e) {
        Notification.error(e.message);
      }
      setLoading(false);
      return res;
    },
    [request]
  );
  return [loading, _request];
}

export function useFetchers(...requests: ((...params: any) => Promise<any>)[]) {
  const [loading, setLoading] = useState(false);
  const _requests = [] as any[];
  for (let i = 0; i < requests.length; i += 1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const _req = useCallback(async (...params: any) => {
      setLoading(true);
      let res;
      try {
        res = await requests[i](...params);
      } catch (e) {
        Notification.error(e.message);
      }
      setLoading(false);
      return res;
    }, []);
    _requests.push(_req);
  }
  return [loading, ..._requests];
}
