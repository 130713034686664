import React from 'react';

const isDev = process.env.NODE_ENV !== 'production';

const NO_PROVIDER = {};

function createUseContext(context: React.Context<any>): any {
  return () => {
    const value = React.useContext(context);
    if (isDev && value === NO_PROVIDER) {
      console.warn('Component must be wrapped with Provider.');
    }
    return value;
  };
}

export function buildSimpleContext(useSomeValues: (initValues: any) => any, displayName = '') {
  const NewContext = React.createContext(NO_PROVIDER);
  if (isDev && displayName) {
    NewContext.displayName = displayName;
  }
  const _hook = createUseContext(NewContext);

  const _Provider: React.FC<any> = ({ children, ...props }) => {
    const value = useSomeValues(props);
    return <NewContext.Provider value={value}>{children}</NewContext.Provider>;
  };

  return [_Provider, _hook];
}

type Selector<V> = (v: V) => any;

type SelectorHook<V, S extends Selector<V>> = () => S extends (v: V) => infer R ? R : never;

type Hook<V, S extends Selector<V>, Selectors extends S[]> = Selectors['length'] extends 0
  ? () => V
  : SelectorHook<V, S>;

export function buildContext<InitV, V>(
  useSomeValues: (initValues: InitV) => V,
  ...selectors: Selector<V>[]
): [React.FC<InitV>, ...Hook<V, Selector<V>, Selector<V>[]>[]] {
  const _contexts = [] as React.Context<any>[];
  const _hooks = [] as Hook<V, Selector<V>, Selector<V>[]>[];
  
  const createContext = (displayName: string) => {
    const _context = React.createContext(NO_PROVIDER);
    if (isDev && displayName) {
      _context.displayName = displayName;
    }
    _contexts.push(_context);
    _hooks.push(createUseContext(_context));
  };

  if (selectors.length) {
    selectors.forEach((s) => createContext(s.name));
  } else {
    createContext(useSomeValues.name);
  }

  const _Provider: React.FC<InitV> = ({ children, ...props }) => {
    const value = useSomeValues(props as InitV);
    let component = children as React.ReactElement;
    for (let i = 0; i < _contexts.length; i += 1) {
      const C = _contexts[i];
      const s =
        selectors[i] ||
        function (v) {
          return v;
        };
      component = <C.Provider value={s(value)}>{component}</C.Provider>;
    }
    return component;
  };

  return [_Provider, ..._hooks];
}
