import { createAxiosInstance } from 'utils/common';

const request = createAxiosInstance();

request.interceptors.response.use(
  function (response) {
    if (response?.data.code === 0) {
      return response.data.data;
    }
    return response;
  },
  function (error) {
    if (error.response?.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
);

async function getConfig() {
  return request({
    url: 'v2/config',
  });
}

async function login(params: any) {
  return request({
    url: 'v2/auth/login',
    method: 'post',
    data: params,
  });
}

async function refresh(params: any): Promise<{ refreshToken: string; accessToken: string }> {
  return request({
    url: 'v2/auth/refresh',
    method: 'post',
    data: params,
  }) as any;
}

async function checkMailAvailable(params: { email: string }) {
  return request({
    url: 'v2/auth/check_registered',
    method: 'post',
    data: params,
  });
}

async function register(params) {
  return request({
    url: 'v2/auth/register',
    method: 'post',
    data: params,
  });
}

async function askToResetPwd(params: any) {
  return request({
    url: 'v2/auth/ask_reset_pwd',
    method: 'post',
    data: params,
  });
}

async function resetPwdWithKey(id: string, params: any) {
  return request({
    url: `v2/auth/reset_pwd/${id}`,
    method: 'post',
    data: params,
  });
}

const PublicService = {
  login,
  refresh,
  checkMailAvailable,
  register,
  askToResetPwd,
  resetPwdWithKey,
  getConfig
};

export default PublicService;
