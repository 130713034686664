import { css } from '@emotion/react';
import { Row } from 'components/antd';
import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import { EButton } from 'components/base';
import { PasswordFields } from 'components/misc/PasswordFields';
import { useQueryState } from 'contexts/router';
import React from 'react';
import { PublicService } from 'services';
import { useRequestWithLoading } from 'services/hook';
import { useTranslate } from 'utils/intl';
import { ModalNotification } from 'utils/notification';
import BackToLogin from './components/BackToLogin';
import EmailWithValidator from './components/EmailWithValidator';

const styles = {
  form: css`
    margin-top: 12px;
  `,
};

function Register() {
  const [t] = useTranslate();
  const { updateQuery } = useQueryState();
  const [form] = FormWrap.useForm();
  const [loading, register] = useRequestWithLoading(PublicService.register);
  return (
    <FormWrap
      form={form}
      name="register"
      onFinish={async (values) => {
        await register(values);
        ModalNotification.success(['auth.registerSuccess'], () => updateQuery({ action: 'login' }));
      }}
      css={styles.form}
    >
      <EmailWithValidator />
      <PasswordFields />
      <FormItemWrap.Input name="company" label="auth.company" />
      <FormItemWrap.Input name="firstName" label="auth.firstName" required={false} />
      <FormItemWrap.Input name="lastName" label="auth.lastName" required={false} />
      <FormItemWrap.Input name="phone" label="auth.phone" required={false} />
      <FormItemWrap>
        <Row align="middle" justify="space-between">
          <EButton _type="solid" htmlType="submit" loading={loading} />
          <BackToLogin />
        </Row>
      </FormItemWrap>
    </FormWrap>
  );
}

export default Register;
