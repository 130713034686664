import { EText } from 'components/base';
import { useQueryState } from 'contexts/router';
import React from 'react';
import { useTranslate } from 'utils/intl';

export default function () {
  const { updateQuery } = useQueryState();
  const [t] = useTranslate();
  return (
    <EText type="link" onClick={() => updateQuery({ action: 'login' })}>
      {t('button.backTo', { label: t('auth.login') })}
    </EText>
  );
}
