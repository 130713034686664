
export function filtersToParams({
  apiKey,
  from = 'now-12h/h',
  to = 'now',
  filters,
}: {
  apiKey: string;
  from?: string;
  to?: string;
  filters?: any;
}) {
  return {
    apikey: apiKey === '*' ? [] : [apiKey],
    s: from,
    e: to,
    ...filters,
  };
}

export default {};
