import { css } from '@emotion/react';
import { Col, Row, Space } from 'components/antd';
import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import { EButton } from 'components/base';
import PlayerPreview from 'components/misc/PlayerPreview';
import TableTemplate from 'components/misc/TableTemplate';
import React, { useEffect, useState } from 'react';
import { usePlayerModel } from '../function';

const styles = {
  form: css`
    margin-top: 8px;
    max-width: 400px;
  `,
};

export default function SetPlayerConfig({ step = 1 }) {
  const { step: currentStep, content, editContent, updateStep } = usePlayerModel();
  const [form] = FormWrap.useForm();
  const [preview, setPreview] = useState<any>({});

  useEffect(() => {
    if (currentStep === step && form) {
      const values = { url: content?.url, ...(content?.playerConfig ?? {}) };
      form.setFieldsValue(values);
    }
  }, [currentStep, content]);

  const onFinish = async (values) => {
    if (!content?._id) return;
    const resValues = TableTemplate.unflattenObject(await form.getFieldsValue()) as any;
    const res = await editContent(content?._id, { playerConfig: resValues });
    console.log(res);
    updateStep(currentStep + 1);
  };

  const onPreview = async () => {
    const res = TableTemplate.unflattenObject(await form.getFieldsValue()) as any;
    setPreview({ url: res.url ?? content?.url, logo: res.logo, ...res.socials });
  };

  if (currentStep !== step) return null;
  return (
    <Row gutter={[20, 20]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={10}>
        <FormWrap form={form} name="player_config" css={styles.form} onFinish={onFinish}>
          <FormItemWrap.Input name="url" label="page.player.contentUrl" required={false} />
          <FormItemWrap.Input name="logo" label="page.player.logoUrl" required={false} />
          <FormItemWrap.Input name="socials.facebook" label="page.player.facebookUrl" required={false} />
          <FormItemWrap.Input name="socials.twitter" label="page.player.twitterUrl" required={false} />
          <FormItemWrap.Input name="socials.linkedin" label="page.player.linkedinUrl" required={false} />
          <Space>
            <EButton _type="solid" text={'Next'} htmlType="submit" />
            <EButton text="page.player.preview" onClick={onPreview} />
          </Space>
        </FormWrap>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={14}>
        <PlayerPreview {...preview} />
      </Col>
    </Row>
  );
}
