import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
import { ChartWrapperProps, withChartWrapper } from './functions/wrappers';
import { ChartConfig } from './options';
import './piechart.css';
import './tooltip.css';
import { getOrCreate } from './utils';

export interface PiechartProps extends ChartWrapperProps {
  data?: any;
  chartConfig?: ChartConfig;
  aes?: {
    innerRadius: number;
    outerRadius: number;
    centerTxt: string;
    // This should return a html string
    tooltipFormater?: (d: any) => string;
  };
}

const PieChart: React.FC<PiechartProps> = ({ chartConfig, data, aes, width, height }) => {
  chartConfig = chartConfig ? chartConfig : new ChartConfig();
  height = height ? height : chartConfig.height;
  chartConfig.setSize(width, height);
  const ref = useRef<any>();
  useEffect(() => {
    const svgContainer = d3.select(ref.current).select('svg');
    const g = svgContainer
      .select('.chart-container')
      .attr('transform', `translate(${width / 2}, ${(height as any) / 2})`);
    const arcs = d3.pie().value((v: any) => v['value'])(data);
    const tooltipContainer = d3.select(ref.current).select('.tooltip-container');
    const arcGen = d3
        .arc()
        .innerRadius(aes?.innerRadius || 10)
        .outerRadius(aes?.outerRadius || 14)
        .cornerRadius(10)
        .padAngle(2)
        .padRadius(3),
      arcGen2 = d3
        .arc()
        .innerRadius((aes?.innerRadius || 10) * 0.95)
        .outerRadius((aes?.outerRadius || 14) * 1.05)
        .cornerRadius(12)
        .padAngle(2.2)
        .padRadius(3);

    g.selectAll('path.pie')
      .data(arcs)
      .join('path')
      .on('mouseover', (e, d) => {
        d3.select(e.srcElement)
          .transition()
          .duration(100)
          .attr('d', arcGen2(d as any) as any);

        const [x, y] = arcGen2.centroid(d as any);

        tooltipContainer
          .style('width', '100px')
          .style('display', null)
          .style('left', `${x + width / 2}px`)
          .style('top', `${(y + (height as any)) / 2}px`)
          .select('.tooltip-content')
          .html(
            aes?.tooltipFormater
              ? aes.tooltipFormater(d)
              : `${(d['data'] as any)['key']}: ${(d['data'] as any)['value']}<br/>`
          );
        // .html(`${(d['data'] as any)['key']}<br/>`);
      })
      .on('mouseleave', (e, d) => {
        d3.select(e.srcElement)
          .transition()
          .duration(100)
          .attr('d', arcGen(d as any) as any);
        tooltipContainer.style('display', 'none');
      })
      .transition()
      .duration(100)
      .attr('d', arcGen as any)
      .attr('class', 'pie')
      .attr('fill', (v: any) => v['data']['color']);

    getOrCreate(g, 'center-tip', 'text').text(aes?.centerTxt || '');
  }, [data, width, height, aes]);
  return (
    <div ref={ref}>
      <svg
        height={chartConfig.height}
        width={chartConfig.width}
        viewBox={`0 0 ${chartConfig.width} ${chartConfig.height}`}
      >
        <g className="chart-container"></g>
        <g className="chart-legend"></g>
      </svg>
      <div className="tooltip-container" style={{ display: 'none' }}>
        <div className="tooltip-header"></div>
        <div className="tooltip-content"></div>
      </div>
    </div>
  );
};

export default withChartWrapper(PieChart);
