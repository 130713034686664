import { buildContext } from 'contexts/cors';
import { useQueryState } from 'contexts/router';
import { useCallback, useEffect, useState } from 'react';
import { DefaultService } from 'services';
import { useRestFetcher } from 'utils/hooks/fetcher';

function _usePlayerModel() {
  const { query, updateQuery } = useQueryState({ step: 0 });
  const [content, setContent] = useState<any>(undefined);
  const { data: contents, loading, add: addContent, edit: editContent } = useRestFetcher({
    service: DefaultService['channels'],
  });

  useEffect(() => {
    if (query.contentId) {
      setContent(contents.find((e) => e._id === query.contentId));
    }
  }, [query.contentId, contents]);

  const updateValues = useCallback(
    (values) => {
      updateQuery(values);
    },
    [updateQuery]
  );

  const updateStep = useCallback((step: number) => updateQuery({ step }), [updateQuery]);

  const selectContent = useCallback((contentId: string) => updateQuery({ contentId }), [updateQuery]);

  return {
    addContent,
    loading,
    contents,
    content,
    step: parseInt(query.step),
    updateValues,
    updateStep,
    selectContent,
    editContent,
  };
}

export const [PlayerConfigProvider, usePlayerModel] = buildContext(_usePlayerModel);
