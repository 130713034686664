import React from 'react';
import { Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

interface LoadingProps {
  size?: number;
  loading?: boolean;
  children?: React.ReactNode;
  wrap?: boolean;
}

function Loading({ loading = false, size = 24, children = null, wrap = true }: LoadingProps) {
  const icon = (
    <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: size }} spin />}>
      {children}
    </Spin>
  );

  if (!wrap) {
    return (
      <Row
        align="middle"
        justify="center"
        css={css`
          min-height: 100px;
        `}
      >
        {icon}
      </Row>
    );
  }

  return icon;
}

export default Loading;
