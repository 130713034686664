import { css } from '@emotion/react';
import { Col, Row } from 'components/antd';
import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import { EButton, EText } from 'components/base';
import { PasswordFields } from 'components/misc/PasswordFields';
import { useQueryState } from 'contexts/router';
import React, { useEffect, useState } from 'react';
import { PublicService } from 'services';
import { useRequestWithLoading } from 'services/hook';
import { ModalNotification, Notification } from 'utils/notification';
import BackToLogin from './components/BackToLogin';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    height: 90%;
  `,
  form: css`
    flex: 1;
  `,
};

function ResetPassword() {
  const [state, setState] = useState({ email: '', id: '', securedKey: '' });
  const { query, updateQuery } = useQueryState();
  const [loading, resetPwdWithKey] = useRequestWithLoading(PublicService.resetPwdWithKey);

  const goToLogin = () => updateQuery({ action: 'login', e: undefined, id: undefined, securedKey: undefined });

  const onFinish = async (values) => {
    try {
      await resetPwdWithKey(state.id, { securedKey: state.securedKey, password: values.password });
      ModalNotification.success(['auth.resetPwdSuccess', 'auth.clickOkToLogin'], goToLogin);
    } catch (e) {
      Notification.error(e.message);
    }
  };

  useEffect(() => {
    if (!query.e || !query.securedKey || !query.id) {
      ModalNotification.warning(['MISS NECESSARY PARAMS'], goToLogin);
      return;
    }
    setState({ email: atob(query.e), securedKey: query.securedKey, id: atob(query.id) });
  }, [query.securedKey]);

  return (
    <div css={styles.container}>
      <Row align="middle" justify="center" css={styles.form}>
        <Col span={24}>
          <FormWrap name="ask_to_reset_pwd" onFinish={onFinish}>
            <FormItemWrap label="email">
              <EText>{state.email}</EText>
            </FormItemWrap>
            <PasswordFields />
            <FormItemWrap>
              <Row align="middle" justify="space-between">
                <EButton text="submit" _type="solid" htmlType="submit" loading={loading} />
                <BackToLogin />
              </Row>
            </FormItemWrap>
          </FormWrap>
        </Col>
      </Row>
      <Row align="middle" justify="space-between" />
    </div>
  );
}

export default ResetPassword;
