import React, { useCallback } from 'react';
import { css } from '@emotion/react';
import { Button, Checkbox, Form, Input } from 'components/antd';
import { getLocalStorageValue } from 'utils/localStorage';
import { useAuth } from 'contexts/auth';
import { useRouterActions, useQuery, useQueryState } from 'contexts/router';
import { Notification } from 'utils/notification';
import { EText } from 'components/base';
import { Col, Divider, Row } from 'antd';
import { useRequestWithLoading } from 'services/hook';
import { useTranslate } from 'utils/intl';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    height: 90%;
  `,
  form: css`
    flex: 1;
  `,
};

function useModel() {
  const { login: sendLoginReq, checkAuth } = useAuth();
  const { redirect: redirectUrl } = useQuery();
  const { redirect } = useRouterActions();
  const [loading, _sendLoginReq] = useRequestWithLoading(sendLoginReq);

  const onLogin = async (params: any) => {
    try {
      await _sendLoginReq(params);
      await checkAuth();
      if (redirectUrl) {
        redirect(redirectUrl);
      } else {
        redirect('/');
      }
    } catch (e) {
      Notification.error(e.message);
    }
  };

  const defaultEmail = getLocalStorageValue('user.login') || '';
  return { loading, login: onLogin, defaultEmail };
}

const LoginForm: React.FC<any> = (props) => {
  const [t] = useTranslate();
  const { loading, login, defaultEmail } = useModel();
  const { updateQuery } = useQueryState();

  return (
    <div css={styles.container}>
      <Row>
        <EText>
          {t('auth.needAccount')}&nbsp;
          <EText type="link" onClick={() => updateQuery({ action: 'register' })}>
            {t('auth.createAccount')}
          </EText>
        </EText>
      </Row>
      <Row justify="center" align="middle" css={styles.form}>
        <Col span={24}>
          <Form layout="vertical" initialValues={{ remember: true, email: defaultEmail }} onFinish={login as any}>
            <Form.Item
              label={t('auth.id')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('form.empty', { label: t('auth.id') }),
                },
              ]}
            >
              <Input placeholder={t('form.inputPlaceholder', { label: t('auth.id') })} />
            </Form.Item>

            <Form.Item
              label={t('auth.password')}
              name="password"
              rules={[{ required: true, message: t('form.empty', { label: t('auth.password') }) }]}
            >
              <Input.Password placeholder={t('form.inputPlaceholder', { label: t('auth.password') })} />
            </Form.Item>

            <Row justify="space-between">
              <Form.Item noStyle name="remember" valuePropName="checked">
                <Checkbox>{t('auth.remember')}</Checkbox>
              </Form.Item>
              <Button
                css={css`
                  min-width: 100px;
                `}
                type="primary"
                htmlType="submit"
                shape="round"
                loading={loading}
              >
                {t('auth.logInBtn')}
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <EText type="link" onClick={() => updateQuery({ action: 'forgot_pwd' })}>
          {t('auth.forgotPassword')}
        </EText>
        <Divider type="vertical" />
        <a target="_blank" rel="noopener noreferrer" href="https://www.easybroadcast.fr/contact.html">
          <EText type="link">contact us</EText>
        </a>
      </Row>
    </div>
  );
};

export default LoginForm;
