import React, { useCallback, useEffect } from 'react';
import { useParams, useLocation, useHistory, RouteProps } from 'react-router';
import { parse, stringify } from 'querystring';
import { useAuth } from './auth';

export function usePathParams() {
  const params = useParams();
  return params ?? {};
}

// result of useLocation()
// {
//   key: 'ac3df4', // not with HashHistory!
//   pathname: '/somewhere',
//   search: '?some=search-string',
//   hash: '#howdy',
//   state: {
//     [userDefined]: true
//   }
// }
// pathname - (string) The path of the URL
// search - (string) The URL query string
// hash - (string) The URL hash fragment
// state - (object) location-specific state that was provided to e.g. push(path, state) when this location was pushed onto the stack. Only available in browser and memory history.

export function useQuery(): Record<string, string> {
  const { search } = useLocation();

  let params: any = {};

  if (search.startsWith('?') && search.length > 1) {
    params = parse(search.substring(1));
  }

  return { ...params };
}

export function usePath() {
  const { pathname } = useLocation();
  return pathname;
}

export function useCurrentRoute() {
  const { flattenRoutes: _routes } = useAuth();
  const path = usePath();
  const currentSelectedRoutes = _routes.filter((r) => r.path === path);
  return currentSelectedRoutes;
}

export function useRouterActions() {
  const history = useHistory();
  const location = useLocation();

  const redirectWithCurrentPath = useCallback(
    (path = '/', params: any = {}) => {
      const { pathname, search } = location;
      const queryStr = stringify({ redirect: `${pathname}${search}`, ...params });
      history.replace(`${path}${queryStr ? '?' + queryStr : ''}`);
    },
    [location]
  );

  const redirect = useCallback((path = '/', params: any = {}) => {
    const queryStr = stringify(params);
    history.replace(`${path}${queryStr ? '?' + queryStr : ''}`);
  }, []);

  const resetQuery = useCallback(
    (params: any = {}) => {
      const queryStr = stringify(params);
      history.replace(`${location.pathname}${queryStr ? '?' + queryStr : ''}`);
    },
    [location.pathname]
  );

  const goTo = useCallback((path = '/', params: any = {}) => {
    const queryStr = stringify(params);
    history.push(`${path}${queryStr ? '?' + queryStr : ''}`);
  }, []);

  return { redirect, resetQuery, goTo, redirectWithCurrentPath };
}

export function useQueryState(
  defaultValue: Record<string, any> = {}
): { query: Record<string, string>; updateQuery: (updates: Record<string, any>) => void } {
  const query = useQuery();
  const { resetQuery } = useRouterActions();

  const updateQuery = (updates: Record<string, string>) => {
    resetQuery({ ...query, ...updates });
  };

  // initiate
  useEffect(() => {
    resetQuery({ ...defaultValue, ...query });
  }, []);

  return { query, updateQuery };
}
