import { ExclamationCircleOutlined, InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';
import React from 'react';

type TipsType = 'question' | 'info' | 'warn';

interface TipsIconProps extends Partial<TooltipPropsWithTitle> {
  tipsText?: string;
  type?: TipsType;
  className?: string;
  style?: object;
  iconClassName?: string;
  iconStyle?: object;
}

function renderIcon(type: TipsType, { iconClassName: className = '', iconStyle: style = {} }: TipsIconProps) {
  const iconProps = { className, style };
  switch (type) {
    case 'question':
      return <QuestionCircleOutlined {...iconProps} />;
    case 'warn':
      return <ExclamationCircleOutlined {...iconProps} />;
    case 'info':
    default:
      return <InfoCircleOutlined {...iconProps} />;
  }
}

const TipsIcon: React.FC<TipsIconProps> = ({
  tipsText = 'unset',
  type = 'info',
  style = { paddingLeft: 2 },
  ...props
}) => {
  return (
    <Tooltip {...props} style={style} title={tipsText}>
      {renderIcon(type, props)}
    </Tooltip>
  );
};

export default TipsIcon;
