import { css, jsx } from '@emotion/react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from 'components/misc/GradienIcon';
import { useLayout } from '../model';

export default function CollapsedIcon() {
  const { menuCollapsed, triggerCollapsed, mobileMode } = useLayout();
  return jsx(menuCollapsed === mobileMode ? MenuFoldOutlined : MenuUnfoldOutlined, {
    css: css`
      font-size: 20px;
      padding: 0 20px;
      cursor: pointer;
      transition: color 0.3s;
    `,
    onClick: () => triggerCollapsed(),
  });
}
