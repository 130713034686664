import { Col, DatePicker, Radio, Row, Select, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import EChartsReact from 'echarts-for-react';
import React from 'react';
import { useLocation } from 'react-router';
import { useTranslate } from 'utils/intl';
import { getToken } from 'utils/localStorage';
import { AudienceMapWithData } from './components';
const { RangePicker } = DatePicker;
// http://localhost:3000/#/analytics/content?start=2022-08-14&end=2022-08-18&content=https://cdnamd-hls-globecast.akamaized.net/live/ramdisk/al_aoula_inter/hls_snrt/al_aoula_inter.m3u8&api_key=snrt
interface ContentBasicInfo {
  name: string;
  url: string;
}
const { Option } = Select;
const BasicInfoDisplay: React.FC<ContentBasicInfo> = ({ name, url }) => {
  const [t] = useTranslate();

  // cap to get only half 2 more on top and 2 more on the bottom
  return (
    <div>
      <div>
        <h1>
          {t('misc.name')}: {name}
        </h1>
      </div>
      <div>
        <h1>
          {t('misc.url')}: <Space size="large" />
          {/* https://ant.design/components/select/ */}
          <Select
            showSearch
            size="small"
            defaultValue={url}
            placeholder="Select a person"
            optionFilterProp="children"
            filterOption={(input, option) =>
              ((option!.children as unknown) as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value={url}>{url}</Option>
          </Select>
        </h1>
      </div>
    </div>
  );
};
const ContentRankingDisplay = () => {
  // Click to select
  const data = [
    { rank: 1, url: 'https://google.com', viewers: 1000, highlight: false },
    { rank: 2, url: 'https://a.google.com', viewers: 800, highlight: false },
    { rank: 3, url: 'https://b.google.com', viewers: 300, highlight: true },
    { rank: 4, url: 'https://c.google.com', viewers: 299, highlight: false },
    { rank: 5, url: 'https://d.google.com', viewers: 100, highlight: false },
    { rank: 6, url: 'https://e.google.com', viewers: 10, highlight: false },
    { rank: 7, url: 'https://f.google.com', viewers: 1, highlight: false },
  ];
  const option = {
    height: 100,
    width: 100,
    grid: {
      top: 0,
      left: 0,
    },
    tooltip: {
      trigger: 'item',
    },
    dataset: {
      source: data,
      dimensions: ['url', 'viewers'],
    },
    yAxis: {
      type: 'category',
      inverse: true,
      show: false,
    },
    xAxis: { show: false },
    series: [
      {
        type: 'bar',
        emphasis: {
          disable: false,
          focus: 'self',
        },
        itemStyle: {
          color: (i: any) => {
            if (i.value.highlight) {
              return 'rgba(42, 50, 112, 0.8)';
            }
            return 'lightgrey';
          },
        },
        tooltip: {
          formatter: (x) => {
            return `${x.value.url}.<br/>Rank: ${x.value.rank}<br/> Viewers: ${x.value.viewers}`;
          },
        },
      },
    ],
  };
  return (
    <div>
      <h1>Content Ranking: 10</h1>
      <EChartsReact style={{ height: '100px' }} option={option} />
    </div>
  );
};
const TimerangeSelector = () => {
  return (
    <div>
      <Radio.Group size="small" defaultValue="1 day" buttonStyle="solid">
        <Radio.Button value="1 day">1 day</Radio.Button>
        <Radio.Button value="2 day">2 day</Radio.Button>
        <Radio.Button value="7 day">7 day</Radio.Button>
        <Radio.Button value="30 day">30 day</Radio.Button>
      </Radio.Group>
      <Space size="large" /> --- <Space size="middle" />
      <RangePicker size="small" />
    </div>
  );
};
const BigNumberWithBarchart = () => {
  const title = 'Views';
  const data = {
    value: 10451,
    breakdown: [
      ['Mon', 100],
      ['Tue', 106],
      ['Wed', 130],
      ['Sat', 130],
      ['Sun', 130],
    ],
  };
  const option = {
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      top: 5,
      left: 5,
      bottom: 20,
    },
    dataset: {
      source: data.breakdown,
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {},
    series: [{ type: 'bar' }],
  };
  return (
    <div>
      {/* <Row style={{ padding: '10px' }}>
        <Col span={6} >
          <div style={{ fontSize: '2rem' }}>Views</div>
          <div style={{ fontSize: '1.5rem' }}>1,500</div>
        </Col>
        <Col span={12}>
          
        </Col>
      </Row> */}
      <h1>
        {title} <span style={{ fontSize: '13px', fontStyle: 'italic' }}>{data.value}</span>
      </h1>
      <EChartsReact option={option} style={{ height: '100px' }} />
    </div>
  );
};

const blockStyle = { backgroundColor: 'white', padding: '1rem', borderRadius: '10px', marginBottom: '1rem' };
const ContentCentricPage = () => {
  const [t] = useTranslate();
  // const { apiKey, from, to, realTimeLastNMinutes, selectedFilters: filters, updateDate } = useFilters();
  // const content: ContentBasicInfo = { name: 'Test', url: 'http://google.com' };
  const jwtToken = getToken().accessToken;
  // console.info("Token = ", jwtToken['accessToken']);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  if (!params.has('start') || !params.has('end') || !params.has('content') || !params.has('api_key')) {
    return <div>Invalid query string. </div>;
  }

  return (
    <Content style={{ padding: '20px', maxWidth: 1200, margin: 'auto' }}>
      <Row style={blockStyle}>
        <Col>
          <div>Api Key: {params.get('api_key')}</div>
          <div>URL: {params.get('content')}</div>
          <div>Time range: {params.get('start')} - {params.get('end')}</div>
        </Col>
        {/* <Col span={18} push={6}>
          <BasicInfoDisplay {...content} />
          <br />
          <TimerangeSelector />
        </Col>
        <Col span={6} pull={18}>
          <ContentRankingDisplay />
        </Col> */}
      </Row>
      <Row style={blockStyle}>
        <Col span={16}>
          <AudienceMapWithData option={{ apiKey: 'snrt', from: 'now-1d', to: 'now', filters: {} }} useTable={false} />
        </Col>
        <Col span={8}>
          <Row>
            <Col span={24}>
              <iframe
                title="Daily Visits"
                scrolling="no"
                style={{ overflow: 'hidden' }}
                frameBorder={0}
                width="100%"
                height={220}
                src={`https://api.easybroadcast.fr/data/dash/daily_metric/data?token=${jwtToken}&${location.search.slice(1)}`}
              />
            </Col>
            <Col span={24}>
              <iframe
                title="Daily Data"
                scrolling="no"
                style={{ overflow: 'hidden' }}
                frameBorder={0}
                width="100%"
                height={220}
                src={`https://api.easybroadcast.fr/data/dash/daily_metric/views?token=${jwtToken}&${location.search.slice(1)}`}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={1}>
            <Col span={8}>
              <iframe
                title="Startup Delay"
                scrolling="no"
                style={{ overflow: 'hidden' }}
                frameBorder={0}
                width="100%"
                height={220}
                src={`https://api.easybroadcast.fr/data/dash/qoe_summary/startupTime?token=${jwtToken}&${location.search.slice(1)}`}
              />
              <div>Startup Time is measured between the openning of the browser and the moment is video is ready to be played.</div>
            </Col>
            <Col span={8}>
              <iframe
                title="QoE Summary"
                scrolling="no"
                style={{ overflow: 'hidden' }}
                frameBorder={0}
                width="100%"
                height={220}
                src={`https://api.easybroadcast.fr/data/dash/qoe_summary/sessionTime?token=${jwtToken}&${location.search.slice(1)}`}
              />
              <div>Watching Time is the duration of users watching sessions. Sessions that are less than 30s are ignored.</div>
            </Col>
            <Col span={8}>
              <iframe
                title="Rebuffering Time"
                scrolling="no"
                style={{ overflow: 'hidden' }}
                frameBorder={0}
                width="100%"
                height={220}
                src={`https://api.easybroadcast.fr/data/dash/qoe_summary/rebufTimeSum?token=${jwtToken}&${location.search.slice(1)}`}
              />
              <div>The Rebuffering Time is the duration of the video stallings. The boxplot above shows the distribution of rebuffering time per session.</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={blockStyle}>
        <Col span={24}>
          <iframe
            title="Qoe Breakdown"
            scrolling="no"
            style={{ overflow: 'hidden' }}
            frameBorder={0}
            width="100%"
            height={500}
            src={`https://api.easybroadcast.fr/data/dash/qoe_breakdown?token=${jwtToken}&${location.search.slice(1)}`}
          />
        </Col>
      </Row>
      <Row style={blockStyle}>
        <Col span={24}>
          <iframe
            title="Temporal comparison"
            scrolling="no"
            style={{ overflow: 'hidden' }}
            frameBorder={0}
            width="100%"
            height={600}
            src={`https://api.easybroadcast.fr/data/dash/temporal_compare?token=${jwtToken}&${location.search.slice(1)}`}
          />
        </Col>
      </Row>

    </Content>
  );
};

export default ContentCentricPage;
