import { css } from '@emotion/react';
import { Col, Row } from 'components/antd';
import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import { EButton, EText } from 'components/base';
import { useQueryState } from 'contexts/router';
import React from 'react';
import { PublicService } from 'services';
import { useRequestWithLoading } from 'services/hook';
import { useTranslate } from 'utils/intl';
import { ModalNotification, Notification } from 'utils/notification';
import BackToLogin from './components/BackToLogin';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    height: 90%;
  `,
  form: css`
    flex: 1;
  `,
};

function ForgotPassword() {
  const { updateQuery } = useQueryState();
  const [loading, askToResetPwd] = useRequestWithLoading(PublicService.askToResetPwd);
  const [t] = useTranslate();
  const onFinish = async (values) => {
    try {
      await askToResetPwd({
        ...values,
        url: window.location.protocol + '//' + window.location.host + '/#/auth/user?action=reset_pwd',
      });
      ModalNotification.success(['auth.askResetPwdSuccess', 'auth.clickOkToLogin'], () =>
        updateQuery({ action: 'login' })
      );
    } catch (e) {
      Notification.error(e.message);
    }
  };
  return (
    <div css={styles.container}>
      <Row align="middle" justify="center" css={styles.form}>
        <FormWrap name="ask_to_reset_pwd" onFinish={onFinish}>
          <FormItemWrap wrapperCol={{ span: 24, offset: 0 }}>
            <EText type="paragraph">{t('auth.sendEmailToResetPwd')}</EText>
          </FormItemWrap>
          <FormItemWrap.Input name="email" label="auth.email" />
          <FormItemWrap wrapperCol={{ span: 24, offset: 0 }}>
            <Row align="middle" justify="space-between">
              <EButton _type="solid" htmlType="submit" loading={loading} />
              <BackToLogin />
            </Row>
          </FormItemWrap>
        </FormWrap>
      </Row>
    </div>
  );
}

export default ForgotPassword;
