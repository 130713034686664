import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
import { QualityPercent } from './BreakdownPercent';
import { getOrCreate } from './utils';

export interface PercentBarProps {
  data?: QualityPercent[];
  width?: number;
  height?: number;
  ticks?: number;
}

const PercentBar: React.FC<PercentBarProps> = ({ data, width = 100, height = 10, ticks = 4 }) => {
  const ref = useRef<any>();
  useEffect(() => {
    const svgElement = d3.select(ref.current).attr('width', width).attr('height', height);
    const x = d3
      .scaleLinear()
      .domain([0, 100])
      .range([2, width - 15]);

    if (data) {
      svgElement
        .selectAll('rect')
        .data(data)
        .join('rect')
        .attr('x', (d) => x(d.fromPercent))
        .attr('width', (d) => x(d.toPercent) - x(d.fromPercent))
        .attr('fill', (d) => d.color)
        .attr('height', height / 3)
        .selectChildren('title')
        .data((v, i) => {
          return [v];
        })
        .join('title')
        .text((d) => {
          return d.key;
        });

      getOrCreate(svgElement, 'axis', 'g')
        .attr('transform', `translate(0, ${height / 3})`)
        .call(
          d3
            .axisBottom(x)
            .ticks(ticks)
            .tickFormat((v, i) => (i == ticks - 1 ? `${v}%` : `${v}`)) as any
        );
    }
  }, [data, width, ticks]);
  return <svg ref={ref}></svg>;
};

export default PercentBar;
