import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';
import { ChartWrapperProps, withChartWrapper } from './functions/wrappers';
import { ChartConfig } from './options';
import './timeseries.css';
import './tooltip.css';
import { getOrCreate } from './utils';

export interface TimeseriesChartProps extends ChartWrapperProps {
  // tooltip settings
  // markers
  // data types settings
  data?: any[];
  aes: {
    x: string;
    ySeries: { y: string; name: string; lineColor?: string; fillColor?: string }[];
    yMin?: number;
    tooltipHeader: string;
    tooltipFormater: (s: any, datum: any) => string;
    yticksFormater?: (y: number) => string;
    showGrid?: boolean;
  };
  chartConfig?: ChartConfig;
  onTimerangeChange?: (from: Date, to: Date) => any;
  onBack?: () => any;
}

const functorKeyScale = (v: string, scale: any) => {
  return (d: any) => {
    return scale(d[v]);
  };
};

const TimeseriesChart: React.FC<TimeseriesChartProps> = ({
  data,
  aes,
  chartConfig,
  width,
  onTimerangeChange,
  onBack,
}) => {
  const ref = useRef<any>();
  const config = chartConfig ? chartConfig : new ChartConfig();
  config.paddings.bottom = 18.5;
  const { height } = config;
  const [prevRange, setPrevRange] = useState<Date[]>();

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    // config the graph size
    config.setSize(width, height);

    // get the container
    const container = d3.select(ref.current);
    // get the svg element
    const svg = container.select('svg');

    // get the list of line containers
    const containers = aes.ySeries.map((_, i) => getOrCreate(svg.select('g.lineplot'), `line-${i}`, 'g'));

    // const textContainer = container.select('.text-container')
    const tooltipContainer = container.select('.tooltip-container');
    const xaxis = svg.select('.lineplot-xaxis'),
      yaxis = svg.select('.lineplot-yaxis');
    const vline = svg.selectAll('.vline');
    const yExtend = config.yExtend();
    const dateBisector = d3.bisector((v: any) => v[aes.x]);
    const curveType = d3.curveLinear;

    // the x axis is
    const xDomain = d3.extent(data.map((v) => v[aes.x])) as any;
    const x = d3.scaleTime().domain(xDomain).range(config.xExtend());

    if (aes.showGrid) {
      const xgrid = svg
        .select('.xgrid')
        // .attr('transform', 'translate(0,' + (height - config.paddings.bottom) + ')')
        .call(
          d3
            .axisBottom(x)
            .ticks(10)
            .tickSize(height - 30) as any
        );
    }
    const brush = d3
      .brushX()
      .extent([
        [config.xExtend()[0], config.yExtend()[0]],
        [config.xExtend()[1], config.yExtend()[1]],
      ])
      .on('end', (m) => {
        svg.select('.selection').style('display', 'none');
        if (!m.selection) {
          if (prevRange && !!onTimerangeChange) {
            onTimerangeChange(prevRange[0], prevRange[1]);
          }
          if (onBack) onBack();
          // x.domain(xDomain);
        } else {
          setPrevRange(x.domain());
          const [newFrom, newTo] = m.selection.map(x.invert);

          // x.domain([newFrom, newTo]);
          if (onTimerangeChange) {
            onTimerangeChange(newFrom as Date, newTo as Date);
          }
        }

        drawLines();
      });

    svg.call(brush as any);

    const yDomain = aes.ySeries.map((s) => data.map((v) => v[s.y])).reduce((m, y) => [...m, ...y]);

    // take the yMin or not take
    if (aes.yMin !== undefined) {
      yDomain.push(aes.yMin);
    }
    const yMinMax = d3.extent(yDomain);

    const y = d3
      .scaleLinear()
      .domain(yMinMax as any)
      .range(config.yExtend().reverse());

    if (aes.showGrid) {
      const ygrid = svg
        .select('.ygrid')
        .attr('transform', 'translate(30, 0)')
        .call(
          d3
            .axisRight(y)
            .ticks(10)
            .tickSize(width - 40) as any
        );
    }
    // initiate the verticle line
    vline
      .selectAll('line')
      .data([{ x: 0 }])
      .join('line')
      .attr('x1', (d) => d.x)
      .attr('x2', (d) => d.x)
      .attr('y1', yExtend[0])
      .attr('y2', yExtend[1])
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '2,4');

    // create the verticle line
    svg.on('mousemove click touchmove', (d) => {
      let xvalue = d.offsetX;
      xvalue = x.invert(xvalue);
      // the verticle line
      vline
        .selectAll('line')
        .data([{ x: d.offsetX }])
        .join('line')
        .style('display', null)
        .attr('transform', `translate(${d.offsetX}, 0)`);

      let idx = dateBisector.left(data, xvalue) - 1;
      const left = Math.round(x(xvalue)) + 'px';

      tooltipContainer.style('left', left).style('display', null);

      tooltipContainer.select('.tooltip-header').text((xvalue as Date).toLocaleString());
      tooltipContainer
        .select('.tooltip-content')
        .html(aes.ySeries.map((s) => aes.tooltipFormater(s, data[idx])).reduce((a, b) => a + b));
    });

    svg.on('mouseleave', (d) => {
      tooltipContainer.style('display', 'none');
      vline.selectAll('line').style('display', 'none');
    });

    let yAxis = d3.axisRight(y);

    if (!!aes.yticksFormater) {
      yAxis = yAxis.tickFormat(aes.yticksFormater as any);
    }

    // drow all the lines
    const drawLines = () => {
      svg.select('.selection').style('display', 'none');
      xaxis.attr('transform', `translate(0, ${config.yExtend()[1]})`).call(d3.axisBottom(x) as any);
      yaxis.attr('transform', `translate(${config.xExtend()[0]}, 0)`).call(yAxis as any);
      containers.forEach((c, id) => {
        // Create the line
        c.selectAll('path')
          // we use this instead of datum because datum cannot calculate leave
          .data([data])
          .join('path')
          .attr('fill', 'none')
          .attr('stroke', aes.ySeries[id].lineColor || 'black')
          .attr('stroke-width', 2)
          .attr('d', d3.line().curve(curveType).x(functorKeyScale(aes.x, x)).y(functorKeyScale(aes.ySeries[id].y, y)));

        const p = getOrCreate(c, 'patharea', 'path');
        // put the area
        p.datum(data)
          .attr('fill', aes.ySeries[id].fillColor || '#A6B1E1')
          .attr('opacity', '.5')
          .attr(
            'd',
            d3
              .area()
              .x(functorKeyScale(aes.x, x))
              .curve(curveType)
              .y0(y(yMinMax[0]))
              .y1(functorKeyScale(aes.ySeries[id].y, y))
          );
      });
    };
    drawLines();
  }, [data, width]);
  return (
    <div style={{ height, width }} ref={ref}>
      <div className="tooltip-container" style={{ display: 'none' }}>
        <div className="tooltip-header">Tooltip</div>
        <div className="tooltip-content">The content here</div>
      </div>
      <svg viewBox={`0 0 ${width} ${height}`}>
        <g className="xgrid grid"></g>
        <g className="ygrid grid"></g>
        <g className="lineplot"></g>
        <g className="lineplot-xaxis"></g>
        <g className="vline"></g>
        <g className="dots-container"></g>
        <g className="text-container"></g>
        <g className="lineplot-yaxis"></g>
      </svg>
    </div>
  );
};

export default withChartWrapper(TimeseriesChart);
