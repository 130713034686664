import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Checkbox, Space } from 'components/antd';
import FormWrap, { FormSelect } from 'components/antd/FormWrap';
import { EButton, EText } from 'components/base';
import { Form as ChannelForm } from 'containers/accounts/ChannelPage';
import { usePlayerModel } from '../function';
import { useTranslate } from 'utils/intl';

const styles = {
  container: css`
    width: 100%;
    margin-top: 8px;
    max-width: 400px;
  `,
  select: css`
    width: 100%;
  `,
};

export default function SelectChannel({ step = 0 }) {
  const { loading, content, contents, selectContent, step: currentStep, addContent, updateStep } = usePlayerModel();
  const [create, setCreate] = useState(false);
  const [form] = FormWrap.useForm();
  const [t] = useTranslate();

  if (currentStep !== step) {
    return (
      <EText>
        {[
          `${t('column.content')}: ${content?.name ?? t('common.unknown')}`,
          `${t('column.url')}: ${content?.url ?? t('common.unknown')}`,
        ].join(', ')}
      </EText>
    );
  }

  return (
    <Space direction="vertical" css={styles.container}>
      <FormSelect
        css={styles.select}
        options={contents}
        placeholder={t('page.player.selectContent')}
        value={content?._id}
        onChange={selectContent}
        disabled={create}
      />
      <Checkbox checked={create} onChange={() => setCreate((prev) => !prev)}>
        {t('page.player.createContent')}
      </Checkbox>
      {create && (
        <ChannelForm
          form={form}
          name="player_create_channel"
          onFinish={async (values) => {
            const res = await addContent({ ...values, apiKey: values['apiKey._id'] });
            console.log(res._id);
            setCreate(false);
            selectContent(res._id);
          }}
        />
      )}
      <Space>
        <EButton _type="solid" text="button.next" disabled={create} onClick={() => updateStep(currentStep + 1)} />
        {create && (
          <EButton
            text={'Create'}
            loading={loading}
            onClick={async () => {
              form.submit();
            }}
          />
        )}
      </Space>
    </Space>
  );
}
