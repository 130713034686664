import request from 'utils/request';

export async function getUser() {
  return request({ url: 'v2/user' });
}

export async function updateProfile(parama: Record<string, string>) {
  return request({ url: 'v2/user/update_profile', method: 'post', data: parama });
}

export async function updateState(parama: Record<string, string>) {
  return request({ url: 'v2/user/update_filter_state', method: 'post', data: parama });
}

export async function resetPassword(parama: Record<string, string>) {
  return request({ url: 'v2/user/reset_pwd', method: 'post', data: parama });
}

export default { getUser, resetPassword, updateProfile, updateState };
