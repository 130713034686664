import { useCallback, useState } from 'react';
import { buildContext } from 'contexts/cors';

const initMobileMode = document.documentElement.clientWidth < 768;

export const [LayoutProvider, useLayout] = buildContext(function () {
  const [mobileMode, setMobileMode] = useState(initMobileMode);
  const [menuCollapsed, setMenuCollapsed] = useState(initMobileMode);

  const handleResize = useCallback(() => {
    const isMobileMode = document.documentElement.clientWidth < 768;
    if (isMobileMode) {
      setMenuCollapsed(false);
    }
    setMobileMode(isMobileMode);
  }, []);

  const triggerCollapsed = useCallback((open?: boolean) => setMenuCollapsed((prevState) => open ?? !prevState), []);

  return { mobileMode, setMobileMode, menuCollapsed, triggerCollapsed, handleResize };
});
