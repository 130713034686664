import { FormItemWrap } from 'components/antd/FormWrap';
import React from 'react';
import { useTranslate } from 'utils/intl';

export function validatePwd(password = '') {
  if (!password.match(/[a-zA-Z]+/)) {
    return 'auth.oneLetterMin';
  }
  if (!password.match(/[0-9]+/)) {
    return 'auth.oneNumberMin';
  }

  if (password.length < 8) {
    return 'auth.eightCharMin';
  }
  return false;
}

interface FormItemProps {
  label?: string;
  name: string;
  rules?: any[];
}

export function PasswordFields(props: { password?: FormItemProps; confirm?: FormItemProps }) {
  const {
    password = { label: 'auth.password', name: 'password', rules: [] },
    confirm = { label: 'auth.confirm', name: 'confirmPassword' },
  } = props;

  const [t] = useTranslate();
  return (
    <>
      <FormItemWrap.Input
        type="password"
        {...password}
        tooltip="auth.pwdRule"
        rules={[
          ...(password.rules ?? []),
          {
            validator: async (_, password) => {
              if (!password) {
                return Promise.resolve();
              }
              const error = validatePwd(password);
              if (error) {
                return Promise.reject(new Error(t(error) ?? t('form.invalid', { label: t('auth.password') })));
              }
            },
          },
        ]}
        hasFeedback
      />
      <FormItemWrap.Input
        type="password"
        {...confirm}
        dependencies={[password.name]}
        hasFeedback
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue(password.name) === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('auth.notMatch')));
            },
          }),
        ]}
      />
    </>
  );
}
