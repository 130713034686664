import Loading from 'components/misc/Loading';
import { useAuth } from 'contexts/auth';
import { useRouterActions } from 'contexts/router';
import React, { useEffect } from 'react';

function ProtectedLayout({ redirectTo, children }: any) {
  const { authReady: ready, user, checkAuth } = useAuth();
  const { redirectWithCurrentPath } = useRouterActions();

  useEffect(() => {
    checkAuth();
  }, []);

  const isLogin = !!user?.email;

  if (ready && !isLogin) {
    redirectWithCurrentPath(redirectTo);
    return null;
  }

  if (!ready) return <Loading loading wrap={false} />;

  return children;
}

export default ProtectedLayout;
