import ReactEcharts from 'echarts-for-react';
import React from 'react';
import themeStyle from 'styles/theme.less';
import { pieLabelFormatter } from '../functions/formatter';

export interface BasePieChartData {
  name: string;
  value: string;
}

interface BasePieChartProps {
  data: BasePieChartData[];
  color?: string[];
}

const defaultColors = [
  themeStyle.primaryColor1,
  themeStyle.primaryColor,
  themeStyle.chartYellow,
  themeStyle.chartRed,
  themeStyle.lightPrimary1,
  themeStyle.lightChartPrimary,
  themeStyle.lightChartRed,
];

function exportPng() {
  // let eInstance = { current: null };
  // need to define echart react instance
  let eInstance: any;
  if (!eInstance.current) return;
  const ins = eInstance.current?.getEchartsInstance();
  if (!ins) return;
  const base64 = ins.getDataURL({ pixelRatio: 2, backgroundColor: '#fff' }).replace('image/png', 'image/octet-stream');
  window.location.href = base64; // it will save locally
}

function BasePieChart({ data, color }: BasePieChartProps) {
  const eInstance = React.useRef(null as any);
  React.useEffect(() => {}, []);
  const eOptions: any = {
    color: color || defaultColors,
    series: [
      {
        data,
        type: 'pie',
        minAngle: 5,
        radius: ['25%', '45%'],
        labelLine: {
          show: true,
        },
        avoidLabelOverlap: true,
        label: {
          formatter: pieLabelFormatter,
          show: true,
          alignTo: 'none',
          fontSize: 12,
          backgroundColor: 'rgba(255,255,255,0.85)',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 12,
            fontWeight: 'bold',
            backgroundColor: 'rgba(255,255,255, 0.95)',
            borderRadius: 4,
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        animation: false,
      },
    ],
  };

  return <ReactEcharts option={eOptions} ref={eInstance} />;
}

export default BasePieChart;
