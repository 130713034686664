import { css } from '@emotion/react';
import { Row } from 'components/antd';
import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import TagGroup from 'components/antd/TagGroup';
import { TableModalTemplate } from 'components/misc/TableTemplate';
import { useAuth } from 'contexts/auth';
import { useFilters } from 'contexts/filters';
import React, { useCallback, useEffect, useState } from 'react';
import { DefaultService } from 'services';
import { useLicenses } from 'services/hook';
import {
  ACCESS_FEATURES,
  ACCESS_TYPE,
  getAccessFeatureOptions,
  getAccessType,
  getRoleTypeOptions,
  ROLE_TYPE
} from 'utils/enums';

// {
//   "features": {
//       "accessType": "INHERIT",
//       "values": []
//   },
//   "apiKeys": {
//       "accessType": "INHERIT",
//       "values": []
//   },
//   "origins": {
//       "accessType": "INHERIT",
//       "values": []
//   },
//   "channels": {
//       "accessType": "INHERIT",
//       "values": []
//   },
//   "roleType": "GLOBAL",
//   "createdBy": "system",
//   "_id": "60425d7111f0b77f68382a12",
//   "name": "license admin role",
//   "createdAt": "2021-03-05T16:33:53.589Z",
//   "updatedAt": "2021-03-05T16:33:53.589Z",
//   "__v": 0
// }

function AccessTag({ accessType, values }: any = {}) {
  return accessType === ACCESS_TYPE.CUSTOMIZED ? (
    <TagGroup options={values.map((v) => (typeof v === 'string' ? v : v.name ?? 'unset'))} />
  ) : (
    accessType
  );
}

const modelParams = {
  name: 'page.user.role',
  service: DefaultService.roles,
  searchKeys: ['name'],
  parseData: (params) => {
    return TableModalTemplate.flattenObject({
      ...params,
      license: params.license?._id,
      apiKeys: { ...params.apiKeys, values: params.apiKeys.values.map((v) => v._id) },
    });
  },
};

const tableColumns = [
  {
    title: 'column.name',
    dataIndex: 'name',
    clickToEdit: true,
  },
  {
    title: 'page.user.roleTypeAndLicense',
    dataIndex: 'roleType',
    render: (type, record) => (
      <>
        <Row>{type}</Row>
        <Row
          css={css`
            font-size: 12px;
          `}
        >
          {record?.license?.name ?? ''}
        </Row>
      </>
    ),
  },
  {
    title: 'column.features',
    dataIndex: 'features',
    // TODO: show enum as tag
    render: (props) => <AccessTag {...props} />,
    width: '35%',
  },
  {
    title: 'column.apikeys',
    dataIndex: 'apiKeys',
    // TODO: show enum as tag
    render: (props) => <AccessTag {...props} />,
    width: '20%',
  },
];

function RoleForm(props) {
  const [featuresDisabled, setFeaturesDisabled] = useState(true);
  const [apikeysDisabled, setApikeysDisabled] = useState(true);
  const [originsDisabled, setOriginsDisabled] = useState(true);
  const [roleType, setRoleType] = useState(ROLE_TYPE.GLOBAL);

  const { apiKeys, availableFilters } = useFilters();
  const { user } = useAuth();
  const { modalSelectedData, ...restProps } = props;

  const { licenses } = useLicenses();
  const originAccess = user.origins ?? [];
  console.log(originAccess.findIndex((e) => e.includes('*')))

  const onFormValuesChange = useCallback((_values) => {
    const values = TableModalTemplate.flattenObject(_values) as any;
    if (values['features.accessType']) {
      setFeaturesDisabled(values['features.accessType'] !== ACCESS_TYPE.CUSTOMIZED);
    }
    if (values['apiKeys.accessType']) {
      setApikeysDisabled(values['apiKeys.accessType'] !== ACCESS_TYPE.CUSTOMIZED);
    }
    if (values['origins.accessType']) {
      setOriginsDisabled(values['origins.accessType'] !== ACCESS_TYPE.CUSTOMIZED);
    }
    if (values.roleType) {
      setRoleType(values.roleType);
    }
  }, []);

  useEffect(() => {
    if (!modalSelectedData) return;
    onFormValuesChange(modalSelectedData);
  }, [modalSelectedData, onFormValuesChange]);

  return (
    <FormWrap {...restProps} onValuesChange={onFormValuesChange}>
      <FormItemWrap.Input label="column.display" name="name" required />
      <FormItemWrap.RadioGroup
        label="page.user.roleType"
        name="roleType"
        options={getRoleTypeOptions(user.features)}
        tooltip="page.user.roleTypeTips"
      />
      {/* TODO: { license options: for the case that internal wants to create role for the others } */}
      {licenses.length > 1 && (
        <FormItemWrap.Select
          name="license"
          label="column.license"
          tooltip="page.user.licenseTips"
          required={false}
          options={licenses}
          disabled={roleType !== ROLE_TYPE.NORMAL}
        />
      )}
      <FormItemWrap.RadioGroup label="column.features" name="features.accessType" options={getAccessType()} />
      <FormItemWrap.TagCheckBox
        name="features.values"
        options={getAccessFeatureOptions(user.features)}
        required={false}
        disabled={featuresDisabled}
      />
      <FormItemWrap.RadioGroup label="column.apikeys" name="apiKeys.accessType" options={getAccessType()} />
      <FormItemWrap.Select
        multiple
        name="apiKeys.values"
        options={apiKeys}
        required={false}
        disabled={apikeysDisabled}
      />
      <FormItemWrap.RadioGroup label="origins" name="origins.accessType" options={getAccessType()} required={false} />
      <FormItemWrap.Select
        multiple
        input={originAccess.findIndex((e) => e.includes('*')) !== -1}
        name="origins.values"
        options={availableFilters.origins}
        required={false}
        disabled={originsDisabled}
      />
    </FormWrap>
  );
}

export default function () {
  const model = TableModalTemplate.useModel(modelParams);
  const { checkAccess } = useAuth();
  return (
    <TableModalTemplate
      {...model}
      data={model.data.map((e) => ({
        ...e,
        readOnly: !checkAccess(ACCESS_FEATURES.INTERNAL) && e.roleType === ROLE_TYPE.GLOBAL,
      }))}
      baseColumns={tableColumns}
      formComponent={<RoleForm modalSelectedData={model.modalSelectedData} form={model.form} name={modelParams.name} />}
    />
  );
}
