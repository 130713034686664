import React from 'react';
import { css } from '@emotion/react';
import { FormItemWrap } from 'components/antd/FormWrap';
import { useTranslate } from 'utils/intl';

const styles = {
  inline: css`
    display: inline-block;
  `,
  left: css`
    width: 40px;
  `,
  right: css`
    width: calc(100% - 40px);
  `,
};

export function FilterItem({ name = '', disabled = true, options = [] }: any = {}) {
  const noLabel = {
    wrapperCol: {
      span: 24,
      offset: 0,
    },
  };
  const [t] = useTranslate();
  return (
    <FormItemWrap
      label={t(`page.v2vConfig.${name}`)}
      extra={t('page.v2vConfig.filterItemDesc', {
        prefix: t(disabled ? 'page.v2vConfig.filterItemAll' : 'page.v2vConfig.filterItemSelected', { name }),
      })}
    >
      <FormItemWrap.Switch name={`enable_allowed_${name}`} {...noLabel} css={[styles.inline, styles.left]} />
      <FormItemWrap.Select
        multiple
        disabled={disabled}
        name={`allowed_${name}`}
        {...noLabel}
        css={[styles.inline, styles.right]}
        options={options}
        selectProps={{ maxTagCount: 2 }}
      />
    </FormItemWrap>
  );
}

export function FilterItemGroup({ items = [] }: { items: any[] }) {
  return (
    <>
      {items.map((e) => (
        <FilterItem {...e} />
      ))}
    </>
  );
}

export default FilterItemGroup;
