import Icon from '@ant-design/icons';
import React from 'react';

const iconWrapper = (component: any, props: any) => <Icon component={() => component} {...props} />;

export const MenuUnfoldOutlined = (props: any) =>
  iconWrapper(
    <svg focusable="false" aria-hidden="true" width="1em" height="1em" fill="url(#lg)" viewBox="64 64 896 896">
      <linearGradient id="lg" x1="0" y1="0" x2="1" y2="1">
        <stop id="icon-lg-color1" offset="0" />
        <stop id="icon-lg-color2" offset="1" />
      </linearGradient>
      <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 000-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0014.4 7z" />
    </svg>,
    props
  );

export const MenuFoldOutlined = (props: any) =>
  iconWrapper(
    <svg focusable="false" aria-hidden="true" width="1em" height="1em" fill="url(#lg)" viewBox="64 64 896 896">
      <linearGradient id="lg" x1="0" y1="0" x2="1" y2="1">
        <stop id="icon-lg-color1" offset="0" />
        <stop id="icon-lg-color2" offset="1" />
      </linearGradient>
      <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM115.4 518.9L271.7 642c5.8 4.6 14.4.5 14.4-6.9V388.9c0-7.4-8.5-11.5-14.4-6.9L115.4 505.1a8.74 8.74 0 000 13.8z" />
    </svg>,
    props
  );

export const BellOutlined = (props: any) =>
  iconWrapper(
    <svg focusable="false" aria-hidden="true" width="1em" height="1em" fill="url(#lg)" viewBox="64 64 896 896">
      <linearGradient id="lg" x1="0" y1="0" x2="1" y2="1">
        <stop id="icon-lg-color1" offset="0" />
        <stop id="icon-lg-color2" offset="1" />
      </linearGradient>
      <path d="M816 768h-24V428c0-141.1-104.3-257.7-240-277.1V112c0-22.1-17.9-40-40-40s-40 17.9-40 40v38.9c-135.7 19.4-240 136-240 277.1v340h-24c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h216c0 61.8 50.2 112 112 112s112-50.2 112-112h216c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM512 888c-26.5 0-48-21.5-48-48h96c0 26.5-21.5 48-48 48zM304 768V428c0-55.6 21.6-107.8 60.9-147.1S456.4 220 512 220c55.6 0 107.8 21.6 147.1 60.9S720 372.4 720 428v340H304z" />
    </svg>,
    props
  );

export const DownloadOutlined = (props: any) =>
  iconWrapper(
    <svg focusable="false" aria-hidden="true" width="1em" height="1em" fill="url(#lg)" viewBox="64 64 896 896">
      <linearGradient id="lg" x1="0" y1="0" x2="1" y2="1">
        <stop id="icon-lg-color1" offset="0" />
        <stop id="icon-lg-color2" offset="1" />
      </linearGradient>
      <path d="M505.7 661a8 8 0 0012.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
    </svg>,
    props
  );

export const RedoOutlined = (props: any) =>
  iconWrapper(
    <svg focusable="false" aria-hidden="true" width="1em" height="1em" fill="url(#lg)" viewBox="64 64 896 896">
      <linearGradient id="lg" x1="0" y1="0" x2="1" y2="1">
        <stop id="icon-lg-color1" offset="0" />
        <stop id="icon-lg-color2" offset="1" />
      </linearGradient>
      <path d="M758.2 839.1C851.8 765.9 912 651.9 912 523.9 912 303 733.5 124.3 512.6 124 291.4 123.7 112 302.8 112 523.9c0 125.2 57.5 236.9 147.6 310.2 3.5 2.8 8.6 2.2 11.4-1.3l39.4-50.5c2.7-3.4 2.1-8.3-1.2-11.1-8.1-6.6-15.9-13.7-23.4-21.2a318.64 318.64 0 01-68.6-101.7C200.4 609 192 567.1 192 523.9s8.4-85.1 25.1-124.5c16.1-38.1 39.2-72.3 68.6-101.7 29.4-29.4 63.6-52.5 101.7-68.6C426.9 212.4 468.8 204 512 204s85.1 8.4 124.5 25.1c38.1 16.1 72.3 39.2 101.7 68.6 29.4 29.4 52.5 63.6 68.6 101.7 16.7 39.4 25.1 81.3 25.1 124.5s-8.4 85.1-25.1 124.5a318.64 318.64 0 01-68.6 101.7c-9.3 9.3-19.1 18-29.3 26L668.2 724a8 8 0 00-14.1 3l-39.6 162.2c-1.2 5 2.6 9.9 7.7 9.9l167 .8c6.7 0 10.5-7.7 6.3-12.9l-37.3-47.9z" />
    </svg>,
    props
  );

export const SaveOutlined = (props: any) =>
  iconWrapper(
    <svg focusable="false" aria-hidden="true" width="1em" height="1em" fill="url(#lg)" viewBox="64 64 896 896">
      <linearGradient id="lg" x1="0" y1="0" x2="1" y2="1">
        <stop id="icon-lg-color1" offset="0" />
        <stop id="icon-lg-color2" offset="1" />
      </linearGradient>
      <path d="M893.3 293.3L730.7 130.7c-7.5-7.5-16.7-13-26.7-16V112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V338.5c0-17-6.7-33.2-18.7-45.2zM384 184h256v104H384V184zm456 656H184V184h136v136c0 17.7 14.3 32 32 32h320c17.7 0 32-14.3 32-32V205.8l136 136V840zM512 442c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144-64.5-144-144-144zm0 224c-44.2 0-80-35.8-80-80s35.8-80 80-80 80 35.8 80 80-35.8 80-80 80z" />
    </svg>,
    props
  );

export const UserAddOutlined = (props: any) =>
  iconWrapper(
    <svg focusable="false" aria-hidden="true" width="1em" height="1em" fill="url(#lg)" viewBox="64 64 896 896">
      <linearGradient id="lg" x1="0" y1="0" x2="1" y2="1">
        <stop id="icon-lg-color1" offset="0" />
        <stop id="icon-lg-color2" offset="1" />
      </linearGradient>
      <path d="M678.3 642.4c24.2-13 51.9-20.4 81.4-20.4h.1c3 0 4.4-3.6 2.2-5.6a371.67 371.67 0 00-103.7-65.8c-.4-.2-.8-.3-1.2-.5C719.2 505 759.6 431.7 759.6 349c0-137-110.8-248-247.5-248S264.7 212 264.7 349c0 82.7 40.4 156 102.6 201.1-.4.2-.8.3-1.2.5-44.7 18.9-84.8 46-119.3 80.6a373.42 373.42 0 00-80.4 119.5A373.6 373.6 0 00137 888.8a8 8 0 008 8.2h59.9c4.3 0 7.9-3.5 8-7.8 2-77.2 32.9-149.5 87.6-204.3C357 628.2 432.2 597 512.2 597c56.7 0 111.1 15.7 158 45.1a8.1 8.1 0 008.1.3zM512.2 521c-45.8 0-88.9-17.9-121.4-50.4A171.2 171.2 0 01340.5 349c0-45.9 17.9-89.1 50.3-121.6S466.3 177 512.2 177s88.9 17.9 121.4 50.4A171.2 171.2 0 01683.9 349c0 45.9-17.9 89.1-50.3 121.6C601.1 503.1 558 521 512.2 521zM880 759h-84v-84c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v84h-84c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h84v84c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-84h84c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z" />
    </svg>,
    props
  );

export const PlaySquareOutlined = (props: any) =>
  iconWrapper(
    <svg focusable="false" aria-hidden="true" width="1em" height="1em" fill="url(#lg)" viewBox="64 64 896 896">
      <linearGradient id="lg" x1="0" y1="0" x2="1" y2="1">
        <stop id="icon-lg-color1" offset="0" />
        <stop id="icon-lg-color2" offset="1" />
      </linearGradient>
      <path d="M442.3 677.6l199.4-156.7a11.3 11.3 0 000-17.7L442.3 346.4c-7.4-5.8-18.3-.6-18.3 8.8v313.5c0 9.4 10.9 14.7 18.3 8.9z" />
      <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656z" />
    </svg>,
    props
  );

export const CubeOutlined = (props: any) =>
  iconWrapper(
    <svg id="Capa_1" viewBox="0 0 511.973 511.973" width="1em" height="1em" fill="currentColor">
      <path d="m255.989 0-221.693 127.993v255.985l221.693 127.994 221.688-127.994v-255.985zm176.683 136.651-176.683 101.965-176.688-101.965 176.688-102.01zm-368.376 25.977 176.693 101.969v204.074l-176.693-102.013zm206.693 306.043v-204.074l176.688-101.968v204.03z" />
    </svg>,
    props
  );

export const EasyBroadcastOutlined = (props: any) =>
  iconWrapper(
    <svg focusable="false" aria-hidden="true" width="1.7em" height="1.7em" fill="currentColor" viewBox="0 0 250 200">
      <path
        d="M224.6,72.5L224.6,72.5c-1.6-0.6-3.2-1.1-4.9-1.5c-2-9.1-10.1-17.6-21.2-22c-6.7-2.6-13.9-3.4-20.2-2.3
			c-2.2-4.8-5.3-9-9.2-12.2c-0.4-0.4-0.9-0.7-1.3-1.1c-4.6-3.4-10-5.2-15.4-5.2c-4.5,0-8.8,1.2-12.7,3.4
			c-7.3-9.3-18.5-14.8-30.4-14.8c-4.4,0-8.7,0.7-12.9,2.2c-0.6,0.2-1.2,0.4-1.8,0.7c-13.2,5.5-22.2,17.6-23.8,31.7
			c-8.7,2.2-16.1,8.3-19.7,16.8c-0.4,1-0.8,2-1.1,2.9c-5.2,2.3-9.7,6-13,10.7c-2.9,4.1-4.7,9-5.3,14c-0.2,1.4-0.3,2.6-0.3,3.8
			c0,17.1,14,31.1,31.2,31.1c0.7,0,46.7-0.1,87.7-0.1l63.6-0.1c16.1,0,29.1-13.5,29.1-30.2C243.2,88.1,235.9,77,224.6,72.5z
			 M214,120.8l-63.7,0.1c-41.1,0-87,0.1-87.7,0.1c-11.7,0-21.2-9.5-21.2-21.1c0-0.8,0.1-1.6,0.2-2.6c0.4-3.4,1.7-6.7,3.6-9.5
			c2.6-3.7,6.1-6.4,10.3-7.9c0.1,0,0.3-0.1,0.3-0.1l2.7-0.9l0.6-2.8c0.2-1.2,0.6-2.4,1.2-3.7C63,65.9,69.1,61.5,76,60.8l4.5-0.4
			l0-4.5c0-11.8,7-22.3,17.8-26.7c0.4-0.2,0.9-0.4,1.3-0.5c3.1-1.1,6.3-1.6,9.5-1.6c10.1,0,19.6,5.5,24.8,14.3l3,5.2l4.6-3.9
			c3.2-2.8,6.9-4.2,10.8-4.2c3.3,0,6.6,1.1,9.5,3.2c0.3,0.2,0.6,0.5,0.9,0.7c3.5,2.8,6.2,7.1,7.7,11.9l1.6,5l4.9-1.8
			c5.1-1.8,11.6-1.5,17.9,0.9c8.9,3.5,15.4,10.7,15.4,17.1l0,4.7l4.7,0.3c0.3,0,0.6,0,0.9,0.1c1.7,0.2,3.4,0.6,5,1.2
			c7.5,3,12.3,10.4,12.3,18.8C233.2,111.7,224.6,120.8,214,120.8z"
      />
      <path d="M113.6,52.3c-2.8,0-5,2.2-5,5v47c0,2.8,2.2,5,5,5s5-2.2,5-5v-47C118.6,54.5,116.3,52.3,113.6,52.3z" />
      <path
        d="M168.8,75.4L133.9,54c-1.5-0.9-3.5-1-5.1-0.1c-1.6,0.9-2.6,2.6-2.6,4.4v45.1c0,1.9,1,3.6,2.7,4.4
			c0.7,0.4,1.5,0.6,2.3,0.6c1,0,2-0.3,2.8-0.9L169,83.8c1.4-1,2.2-2.6,2.2-4.3C171.2,77.8,170.3,76.3,168.8,75.4z"
      />
    </svg>,
    props
  );
