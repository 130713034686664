import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import TableModalTemplate from 'components/misc/TableTemplate';
import React, { useCallback, useEffect, useState } from 'react';
import { generateRestService } from 'services';
import { useAlertModel } from './index';

const modelParams = {
  name: 'page.alert.channel',
  service: generateRestService('alert/channels'),
  searchKeys: ['name'],
};

const columns = [
  { title: 'column.name', dataIndex: 'name', clickToEdit: true, width: '30%' },
  { title: 'page.alert.channelType', dataIndex: 'alertChannelType', width: 150 },
  {
    title: 'page.alert.channelValue',
    dataIndex: 'alertChannelType',
    render: (type, record) => {
      if (type === 'email') return record.emailList.join(', ');
      return record.webhookUrl;
    },
  },
];

function Form(props) {
  const { users } = useAlertModel();
  const [type, setType] = useState('email');

  const onValuesChange = useCallback((values) => {
    if (values.alertChannelType) setType(values.alertChannelType);
  }, []);

  const { modalSelectedData, ...restProps } = props;

  useEffect(() => {
    if (!modalSelectedData) return;
    onValuesChange(modalSelectedData);
  }, [modalSelectedData]);

  return (
    <FormWrap {...restProps} onValuesChange={onValuesChange}>
      <FormItemWrap.Input label="column.display" name="name" />
      <FormItemWrap.RadioGroup
        required
        label="page.alert.channelType"
        name="alertChannelType"
        options={['email', 'mattermost', 'slack']}
      />
      {type === 'email' ? (
        <FormItemWrap.Select
          label="page.alert.emailList"
          name="emailList"
          options={users.map((u) => ({ label: u.email, value: u.email }))}
          input
        />
      ) : (
        <FormItemWrap.Input label="page.alert.webhookUrl" name="webhookUrl" required={false} />
      )}
    </FormWrap>
  );
}

export default function () {
  const model = TableModalTemplate.useModel(modelParams);

  return (
    <TableModalTemplate
      {...model}
      baseColumns={columns}
      formComponent={<Form modalSelectedData={model.modalSelectedData} form={model.form} name={modelParams.name} />}
    />
  );
}
