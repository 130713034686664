import { useFilters } from 'contexts/filters';
import { useQueryState } from 'contexts/router';
import { useCallback, useEffect, useState } from 'react';
import { DefaultService } from 'services';
import loadFiltersAPI from 'services/data/filters';
import Notification from 'utils/notification';

const filters = ['origins', 'isps', 'browsers', 'os', 'countries'].map((e) => ({
  key: `allowed_${e}`,
  name: e,
  enableKey: `enable_allowed_${e}`,
  options: [],
}));

const getFilterOptions = async (_apiKey: string) => {
  if (!_apiKey) return;
  let res = await loadFiltersAPI(_apiKey);
  res = Object.fromEntries(
    Object.entries(res).map(([k, v]: any) => {
      if (v.buckets) {
        return [k, v.buckets];
      }
      return [k, []];
    })
  );

  const { origins = [], isp = [], browser = [], os = [], country = [] } = res;
  const _res = {
    origins,
    isps: isp,
    browsers: browser,
    os,
    countries: country,
  };
  return Object.fromEntries(
    Object.entries(_res).map(([k, v]: any) => [
      k,
      v.map((e) => ({ c: e.doc_count, label: `${e.key} (${e.doc_count} Viewers)`, value: e.key })),
    ])
  );
};

function parseData(params) {
  const res = {};
  filters.map(({ key, enableKey }) => {
    if (params[key]) {
      // if the filter value is empty
      // set form value is false
      if (params[key].length === 0) {
        res[key] = [];
        res[enableKey] = false;
      } else {
        res[key] = [...params[key]];
        res[enableKey] = true;
      }
    }
  });
  return { ...params, ...res };
}

function toData(params) {
  return { ...params };
}

function calculatePredict(values, filterOptions = {}) {
  const r = filters
    .map((e) => ({ name: e.name, value: values[e.key] }))
    .filter((e) => e.value.length > 0)
    .map((e) => {
      let enable = 0;
      let disable = 0;
      filterOptions[e.name].forEach((foe) => {
        if (e.value.includes(foe.value)) enable += foe.c;
        else disable += foe.c;
      });
      return { enable, disable };
    })
    .reduce((prev, curr) => (curr.enable / (curr.enable + curr.disable)) * prev, 1);
  const predictPercentage = (r * values.allowed_percentage) / 100;
  const sum = filterOptions[filters[0].name].map((e) => e.c).reduce((p, c) => p + c, 0);
  return { enable: Math.ceil(predictPercentage * sum), disable: sum - Math.ceil(predictPercentage * sum) };
}

export function useModel(form) {
  const { apiKeys, loadApiKeys, currentApiKeyValue } = useFilters();
  const { query, updateQuery } = useQueryState({ id: apiKeys[0]._id });
  const [apiKey, setApiKey] = useState<any>({});
  const [formFilters, setFormFilters] = useState(filters.map((e) => ({ ...e, disabled: true })));
  const [filterOptions, setFilterOptions] = useState({});
  const [predict, setPredict] = useState({ enable: 100, disable: 0 });

  const reset = () => {
    if (apiKey) {
      form.setFieldsValue(apiKey);
    }
  };

  const save = async (values) => {
    const { _id, ...rest } = toData(values);
    try {
      await DefaultService.apikeys.update(values._id, rest);
      await loadApiKeys();
      setApiKey(values);
      Notification.success('update success');
    } catch (e) {
      Notification.error(e.message);
    }
  };

  const updateFilterStatus = (values) => {
    setFormFilters((prev) => {
      return prev.map((e) => {
        if (typeof values[e.enableKey] !== 'undefined') {
          return { ...e, disabled: !values[e.enableKey] };
        }
        return { ...e };
      });
    });
  };

  const updatePredict = useCallback(
    (formValues) => {
      setPredict(calculatePredict(formValues, filterOptions));
    },
    [filterOptions]
  );

  const updateFilterOptions = async (_v) => {
    const _apikey = _v.key;
    if (!_apikey) return;
    const res = await getFilterOptions(_apikey);
    setFilterOptions(res);
    setPredict(calculatePredict(_v, res));
  };

  const updateApiKeyQuery = (id) => updateQuery({ id });

  const resetApiKeyParams = useCallback(
    async (apiKeyInstance: any) => {
      if (!apiKeyInstance) return;
      const _v = parseData(apiKeyInstance);
      setApiKey(_v);
      form.setFieldsValue(_v);

      // update filter status
      updateFilterOptions(_v);
      updateFilterStatus(_v);
    },
    [form]
  );

  const onFormValueChanged = useCallback(
    (values, allValues) => {
      if (values._id) updateApiKeyQuery(values._id);
      //  slider triggers this function
      if (values.allowed_percentage) return;
      updateFilterStatus(values);
      updatePredict(allValues);
    },
    [updateApiKeyQuery, updatePredict]
  );

  useEffect(() => {
    // update selected apikey
    if (query.id) {
      resetApiKeyParams(apiKeys.find((a) => a._id === query.id));
    }
  }, [query.id, apiKeys, resetApiKeyParams]);

  return {
    filterOptions,
    formFilters: formFilters.map((e) => ({ ...e, options: filterOptions[e.name] ?? [] })),
    reset,
    save,
    resetApiKeyParams,
    onFormValueChanged,
    apiKeys,
    predict,
    updatePredict,
  };
}

export default {};
