import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import TableModalTemplate from 'components/misc/TableTemplate';
import React from 'react';
import { generateRestService } from 'services';
import { MetricsOperator } from 'utils/enums';

const modelParams = {
  name: 'page.alert.metric',
  service: generateRestService('alert/metrics'),
  searchKeys: ['name'],
};

const columns = [
  { title: 'column.name', dataIndex: 'name', clickToEdit: true },
  { title: 'page.alert.minValue', dataIndex: 'minValue' },
  { title: 'page.alert.minValue', dataIndex: 'maxValue' },
  { title: 'page.alert.unit', dataIndex: 'unit' },
];

function Form(props) {
  return (
    <FormWrap {...props}>
      <FormItemWrap.Input label="column.name" name="name" />
      <FormItemWrap.Select
        required={false}
        multiple
        label="page.alert.supportedOperators"
        name="supportedOperators"
        options={Object.values(MetricsOperator)}
      />
      <FormItemWrap.Input label="page.alert.unit" name="unit" />
      <FormItemWrap.Input type="number" label="page.alert.minValue" name="minValue" />
      <FormItemWrap.Input type="number" label="page.alert.maxValue" name="maxValue" />
      <FormItemWrap.Input label="page.alert.queryTmp" name="queryTmp" />
      <FormItemWrap.Input label="page.alert.messageTmp" name="messageTmp" />
      <FormItemWrap.Input label="page.alert.valueField" name="valueField" />
    </FormWrap>
  );
}

export default function () {
  const model = TableModalTemplate.useModel(modelParams);
  return (
    <TableModalTemplate
      {...model}
      baseColumns={columns}
      formComponent={<Form form={model.form} name={modelParams.name} />}
    />
  );
}
