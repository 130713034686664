import Loading from 'components/misc/Loading';
import { useFilters } from 'contexts/filters';
import HeaderContentLayout from 'layouts/HeaderContentLayout';
import React from 'react';
import FilterHeader from '../components/FilterHeader';

export function withLayout(Component) {
  return function (props) {
    const { apiKey } = useFilters();
    if (!apiKey) return <Loading loading wrap={false} />;
    return (
      <HeaderContentLayout header={<FilterHeader />}>
        <Component {...props} />
      </HeaderContentLayout>
    );
  };
}

export default function CommonLayout({ children }) {
  const { apiKey } = useFilters();
  if (!apiKey) return <Loading loading wrap={false} />;
  return <HeaderContentLayout header={<FilterHeader />}>{children}</HeaderContentLayout>;
}
