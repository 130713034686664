import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { default as duration, default as durationPlugin } from 'dayjs/plugin/duration';
import isTodayExtend from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(isTodayExtend);
dayjs.extend(durationPlugin);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(duration);

export interface TimeRangeOption {
  key: string;
  start: dayjs.Dayjs;
  display?: string;
  auto?: boolean;
  lastNMinutes?: number;
}
export const timeRangeOptions: TimeRangeOption[] = [
  { start: dayjs().add(-30, 'm'), auto: true, lastNMinutes: 30 },
  { start: dayjs().add(-1, 'h'), auto: true, lastNMinutes: 60 },
  { start: dayjs().add(-2, 'h'), auto: true },
  { start: dayjs().add(-4, 'h'), auto: true },
  { display: 'Today', start: dayjs().hour(4).minute(0).second(0), auto: true },
  { start: dayjs().add(-24, 'h'), auto: true },
  { display: 'Last 2 days', start: dayjs().hour(4).minute(0).second(0).add(-1, 'd') },
  // { display: 'This week', start: dayjs().day(1).hour(4).minute(0).second(0) },
  { display: 'Last 7 days', start: dayjs().hour(4).minute(0).second(0).add(-6, 'd') },
  // { display: 'This month', start: dayjs().date(1).hour(4).minute(0).second(0).millisecond(0) },
  { display: 'Last 28 days', start: dayjs().hour(4).minute(0).second(0).add(-27, 'd').millisecond(0) },
].map((t) => ({ ...t, key: t.display || `Last ${dayjs().to(t.start, true)}` }));

export function showTimes(timestamp: string | number) {
  return dayjs().diff(dayjs(timestamp), 'week') > 4;
}

export function getRelativeTime(timestamp: string | number) {
  return dayjs(timestamp).fromNow();
}

const timeFilterFormat = 'DD/MM/YY HH:mm:ss';

export function parseToCustomisedDateStr({
  start,
  end,
}: {
  start: dayjs.Dayjs | string | Date;
  end: dayjs.Dayjs | string | Date;
}): string {
  return `${dayjs(start).format(timeFilterFormat)} - ${dayjs(end).format(timeFilterFormat)}`;
}

export function parseToDayRange(dateText: string): { start: dayjs.Dayjs; end: dayjs.Dayjs } {
  const tmp = dateText.trim().split('-');
  if (tmp.length !== 2) {
    throw new Error('NOT THE DATE FORMAT');
  }

  if (dayjs(tmp[0], timeFilterFormat).isValid() && dayjs(tmp[0], timeFilterFormat).isValid()) {
    return {
      start: dayjs(tmp[0], timeFilterFormat),
      end: dayjs(tmp[1], timeFilterFormat),
    };
  }

  const previousFormat = 'DD/MM/YY';

  if (dayjs(tmp[0], previousFormat).isValid() && dayjs(tmp[0], previousFormat).isValid()) {
    return {
      start: dayjs(tmp[0], previousFormat).hour(4).minute(0).second(0).millisecond(0),
      end: dayjs(tmp[1], previousFormat).add(1, 'day').hour(3).minute(59).second(59).millisecond(999),
    };
  }

  return {
    start: dayjs().add(-1, 'day'),
    end: dayjs(),
  };
}

export function compareDiff(start: string | Date, end: string | Date, unit: any = 'minute', resultInFloat = false) {
  return dayjs(end).diff(dayjs(start), unit, resultInFloat);
}

export function dateFormatter(
  date: Date | number | string,
  { type = 'normal' }: { type?: 'normal' | 'relative' } = {}
) {
  switch (type) {
    case 'relative':
      return dayjs(date).toNow();
    default:
      return dayjs(date).format('HH:mm:ss DD/MM/YYYY');
  }
}

// export function formatDuration(milliseconds: number, unitInAbbr = true) {
//   if (milliseconds < 100) return [duration(milliseconds).asMilliseconds() + 'ms'];
//   if (milliseconds > 100 && milliseconds < 1000 * 60)
//     return duration(milliseconds).asSeconds() + (unitInAbbr ? 's' : 'seconds');
//   if (milliseconds < 60 * 60 * 1000 && milliseconds > 1000 * 60)
//     return duration(milliseconds).asMinutes() + (unitInAbbr ? 'm' : 'minutes');
//   return duration(milliseconds).asHours().toFixed(0) + (unitInAbbr ? 'h' : 'hours');
// }

export function parseTimeRangeKey(timeRangeKey: string): { start; end: Date; lastNMinutes?: number } {
  const option = timeRangeOptions.find(({ key }) => key === timeRangeKey);
  if (option) {
    return { start: option.start.toDate(), end: dayjs().toDate(), lastNMinutes: option.lastNMinutes };
  }

  if (timeRangeKey.split('##').length === 2) {
    const [start, end] = timeRangeKey.split('##');
    return { start: dayjs(start).toDate(), end: dayjs(end).toDate() };
    // return [dayjs(start).toDate(), dayjs(end).toDate()];
  }

  return {
    start: timeRangeOptions[0].start.toDate(),
    end: dayjs().toDate(),
    lastNMinutes: timeRangeOptions[0].lastNMinutes,
  };
}
