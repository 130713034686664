import BarChartCard from 'components/visualizations/BarChartCard';
import { BaseOption, useViewsByDayOfData } from 'containers/analytics/utils/hooks';
import React from 'react';
// import { loadViewsByDayOf } from 'services/data/timeseries';
import { useTranslate } from 'utils/intl';

// const weekDayMapping = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const ViewByWeekdaysBarchartWithData = ({ option }: { option: BaseOption }) => {
  const [t] = useTranslate();
  const { byWeekDayData, totalViews } = useViewsByDayOfData({ option, type: 'weekday' });

  return (
    <BarChartCard
      data={byWeekDayData}
      summaryText={totalViews.toLocaleString()}
      intlKey="analytics.views"
      extra="Total"
      tipsText={t('analytics.tooltips.viewcard')}
    />
  );
};

export default ViewByWeekdaysBarchartWithData;
