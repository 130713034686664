import { stringify } from 'querystring';
import React, { useEffect, useState } from 'react';
import { Text } from 'components/base';
import { PlayCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

const test_stream = 'https://live.easybroadcast.fr/hls/live/playlist.m3u8';

const styles = {
  container: css`
    background: #000;
    padding: 25px;
    max-width: 450px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #f1f1f1;
  `,
  icon: css`
    font-size: 50px;
    margin-bottom: 12px;
  `,
  text: css`
    color: #fff;
    font-size: 18px;
  `,
  iframe: css`
    overflow: hidden;
    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;
    max-width: 500px;
    & iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  `,
};

export default function ({ url, logo, facebook, twitter, linkedin }: any) {
  const [_url, setUrl] = useState('');

  useEffect(() => {
    setUrl(url);
  }, [url]);

  if (!_url)
    return (
      <div css={styles.container}>
        <PlayCircleOutlined css={styles.icon} />
        <Text
          type="link"
          onClick={() => {
            setUrl(test_stream)
          }}
        >
          Try EasyBroadcast test stream
        </Text>
      </div>
    );

  const queryString = stringify({
    url: _url,
    logo,
    facebook,
    twitter,
    linkedin,
  });

  return (
    <div css={styles.iframe}>
      <iframe title="player_preview" src={`player_iframe.html?${queryString}`} />
    </div>
  );
}
