import React, { useCallback, useEffect, useState } from 'react';
import AlertItems from './AlertItems';
import Metrics from './Metrics';
import AlertChannel from './AlertChannel';
import { DefaultService, generateRestService } from 'services';
import { buildContext } from 'contexts/cors';
import TopTabsLayout from 'layouts/TopTabsLayout';
import { ACCESS_FEATURES } from 'utils/enums';

const alertService = {
  items: generateRestService('alert/records'),
  metrics: generateRestService('alert/metrics'),
  channels: generateRestService('alert/channels'),
};

function _useAlertModel() {
  const [users, setUsers] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [alertChannels, setAlertChannels] = useState([]);
  const [contents, setContents] = useState([]);
  useEffect(() => {
    async function init() {
      setContents(await DefaultService['channels'].list());
      setUsers(await DefaultService['users'].list());
      setAlertChannels((await alertService.channels.list()) as any);
      setMetrics((await alertService.metrics.list()) as any);
    }
    init();
  }, []);
  const reload = useCallback(async () => {
    setAlertChannels((await alertService.channels.list()) as any);
    setMetrics((await alertService.metrics.list()) as any);
  }, []);
  return { users, metrics, alertChannels, contents, setAlertChannels, setMetrics, reload };
}

export const [AlertProvider, useAlertModel] = buildContext(_useAlertModel);

export default function () {
  const tabs = [
    {
      key: 'items',
      title: 'page.alert.items',
      component: <AlertItems />,
    },
    {
      key: 'channels',
      title: 'page.alert.channels',
      component: <AlertChannel />,
    },
    {
      key: 'metrics',
      title: 'page.alert.metrics',
      component: <Metrics />,
      authorize: [ACCESS_FEATURES.INTERNAL],
    },
  ];
  return (
    <AlertProvider>
      <TopTabsLayout tabs={tabs} />
    </AlertProvider>
  );
}
