import request from 'utils/request';

export { default as AuthService } from './auth';
export { default as PublicService } from './public';

const URL_PREFIX = 'v2/';

export function generateRestService(path: string) {
  const baseUrl = URL_PREFIX + path;
  return {
    async list({ offset, limit = -1 }: { offset?: number; limit?: number } = {}) {
      return request({ url: baseUrl, params: { offset, limit } });
    },
    async create(data: any) {
      return request({ url: baseUrl, method: 'post', data });
    },
    async update(id: string, data: any) {
      return request({ url: `${baseUrl}/${id}`, method: 'patch', data });
    },
    async destroy(id: string) {
      return request({ url: `${baseUrl}/${id}`, method: 'delete' });
    },
  };
}

const defaultServiceNames = ['apikeys', 'licenses', 'channels', 'roles', 'users'];

export const DefaultService: Record<string, any> = Object.fromEntries(
  defaultServiceNames.map((name) => [name, generateRestService(name)])
);
