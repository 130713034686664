// import CardContainer from 'components/ChartCards/components/CardContianer';
import Loading from 'components/misc/Loading';
import BasePieChart from 'components/visualizations/base/BasePieChart';
// eslint-disable-next-line import/named
import ChartCardLayout from 'layouts/ChartCardLayout';
import React from 'react';

interface PieChartCardProps {
  data?: any;
  title?: string;
  loading?: boolean;
}

const PieChartCard: React.FC<PieChartCardProps> = ({ loading, data, title }) => {
  // if (!data) {
  //   return (
  //     <ChartCardLayout title={title} style={{ height: 360 }}>
  //       <Loading loading />
  //     </ChartCardLayout>
  //   );
  // }
  return (
    <ChartCardLayout title={title}>
      <Loading loading={loading} />
      <BasePieChart data={data} />
    </ChartCardLayout>
  );
};

export default PieChartCard;
