import React from 'react';
import { Tag } from 'antd';
import { css } from '@emotion/react';

function TagGroup({ options: _options = [] }: { options: (string | { name: string })[] }) {
  let options = _options.map(e => typeof e === 'string' ? e : e.name);

  return (
    <>
      {options.map((f, i) => (
        <Tag
          key={String(i)}
          css={css`
            margin-bottom: 4px;
          `}
        >
          {f.replace('_', ' ')}
        </Tag>
      ))}
    </>
  );
}

export default TagGroup;
