import { validateExpired } from './common';
import { AUTHORITY_KEY } from './constants';

export interface JWTParams {
  accessToken: string;
  refreshToken: string;
}

export function setLocalStorageValue(key: string, value: any) {
  localStorage.setItem(AUTHORITY_KEY + '.' + key, JSON.stringify(value));
}

export function getLocalStorageValue(key: string) {
  const str = localStorage ? localStorage.getItem(AUTHORITY_KEY + '.' + key) : '';
  let res;
  try {
    if (str) {
      res = JSON.parse(str);
    }
  } catch (e) {
    res = str;
  }
  return res;
}

export function removeLocalStorageValue(key: string) {
  localStorage.removeItem(AUTHORITY_KEY + '.' + key);
}

export function setToken(token?: JWTParams) {
  if (token) {
    localStorage.setItem(AUTHORITY_KEY, JSON.stringify(token));
  } else {
    localStorage.removeItem(AUTHORITY_KEY);
  }
}

export function getToken() {
  const localStorageStr = localStorage ? localStorage.getItem(AUTHORITY_KEY) : '';
  let res;
  try {
    if (localStorageStr) {
      res = JSON.parse(localStorageStr);
    }
  } catch (e) {
    res = localStorageStr;
  }
  return res;
}

export function getValidToken() {
  const tokens = getToken();
  if (tokens && !validateExpired(tokens.refreshToken)) {
    return tokens;
  }
  return false;
}
