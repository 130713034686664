import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import TagGroup from 'components/antd/TagGroup';
import TableModalTemplate from 'components/misc/TableTemplate';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { generateRestService } from 'services';
import { MetricsOperator } from 'utils/enums';
import { useTranslate } from 'utils/intl';
import { useAlertModel } from './index';

// {
//   "alertChannels": [
//       {
//           "_id": "60916313bb588bb5ede30d70",
//           "name": "test"
//       }
//   ],
//   "durationInSec": 3600,
//   "messageTmp": "",
//   "_id": "6091c438dc28a2c996ba8c0b",
//   "name": "test alert item",
//   "stream": null,
//   "metric": {
//       "_id": "609160b1cb2117b20bdbdf20",
//       "name": "test"
//   },
//   "operator": "gt",
//   "threshold": 100,
//   "createdBy": "60425d7111f0b77f68382a13",
//   "createdAt": "2021-05-04T22:01:28.027Z",
//   "updatedAt": "2021-05-04T22:01:28.027Z",
//   "__v": 0
// }

const modelParams = {
  name: 'page.alert.item',
  service: generateRestService('alert/records'),
  searchKeys: ['name'],
  parseData: (params) => {
    return TableModalTemplate.flattenObject({ ...params, alertChannels: params.alertChannels.map((e) => e._id) });
  },
  toData: (params) => {
    const res = TableModalTemplate.unflattenObject(params) as any;
    return { ...res, metric: res.metric?._id, channel: res.channel?._id };
  },
};

function Form(props) {
  const [metric, setMetric] = useState<any>({});
  const { alertChannels, contents, metrics } = useAlertModel();
  const onValuesChange = useCallback(
    (values) => {
      if (values['metric._id']) {
        // const _metric = metrics.find(e => e._id === values.metric);
        setMetric(metrics.find((e) => e._id === values['metric._id']) ?? {});
      }
    },
    [metrics]
  );

  const { modalSelectedData, ...rest } = props;
  useEffect(() => {
    if (!modalSelectedData) return;
    onValuesChange(modalSelectedData);
  }, [modalSelectedData]);

  return (
    <FormWrap {...rest} onValuesChange={onValuesChange}>
      <FormItemWrap.Input label="column.display" name="name" />
      <FormItemWrap.Select required label="column.content" name="channel._id" options={contents} />
      <FormItemWrap.Select required label="page.alert.metric" name="metric._id" options={metrics} />
      <FormItemWrap.Select
        required
        label="page.alert.duration"
        name="durationInSec"
        options={(metric?.supportedDurationsSec ?? []).map((e) => ({
          value: e,
          label: dayjs.duration({ seconds: e }).humanize(true),
        }))}
      />
      <FormItemWrap.RadioGroup
        enums
        label="page.alert.operator"
        name="operator"
        options={metric?.supportedOperators ?? Object.values(MetricsOperator)}
        tooltip="page.alert.operatorTips"
      />
      <FormItemWrap.Input
        type="number"
        inputProps={{ min: metric?.minValue, max: metric?.maxValue }}
        label="page.alert.threshold"
        name="threshold"
      />
      <FormItemWrap.Select label="page.alert.channels" name="alertChannels" multiple options={alertChannels} />
    </FormWrap>
  );
}

export default function () {
  const model = TableModalTemplate.useModel(modelParams);
  const { reload } = useAlertModel();
  useEffect(() => {
    reload();
  }, []);
  const [t] = useTranslate();
  const columns = [
    { title: 'column.name', dataIndex: 'name', clickToEdit: true },
    { title: 'column.content', dataIndex: 'channel', render: (v) => v?.name },
    { title: 'page.alert.metric', dataIndex: 'metric', render: (v) => v?.name },
    { title: 'page.alert.threshold', dataIndex: 'threshold', render: (v, r) => `${t(`enums.${r.operator}.label`)} ${v} ${r.metric?.unit}` },
    {
      title: 'page.alert.channel',
      dataIndex: 'alertChannels',
      render: (v) => <TagGroup options={v.map((e) => e.name)} />,
    },
  ];
  return (
    <TableModalTemplate
      {...model}
      baseColumns={columns}
      formComponent={<Form modalSelectedData={model.modalSelectedData} form={model.form} name={modelParams.name} />}
    />
  );
}
