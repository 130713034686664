import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Avatar, Divider, Dropdown, Layout, Menu, Row, Space } from 'antd';
import { Text } from 'components/base';
import { useAuth } from 'contexts/auth';
import { useFilters } from 'contexts/filters';
import { useCurrentRoute, useRouterActions } from 'contexts/router';
import React from 'react';
import { COPYRIGHT_FOOTER_TEXT } from 'utils/constants';
import { useTranslate } from 'utils/intl';
import Select from '../components/antd/Select';
import CollapsedIcon from './components/CollapsedIcon';
import LeftSideMenu from './components/LeftSideMenu';
import { LayoutProvider, useLayout } from './model';

const styles = {
  mainLayout: css`
    transition: all 0.2s;
    min-height: 100vh;
    overflow: initial;
  `,
  border: css`
    border-top: 1px solid #f1f1f1;
  `,
};

export function Footer() {
  return (
    <Layout.Footer style={{ textAlign: 'center', background: 'transparent' }}>
      <Text style={{ fontSize: '0.75rem' }}>{COPYRIGHT_FOOTER_TEXT}</Text>
      <Divider type="vertical" />
    </Layout.Footer>
  );
}

function ApiKeySelect() {
  const { apiKeys, currentApiKeyValue, selectApiKeys, updateApiKey } = useFilters();
  return (
    <Select
      bordered={false}
      showArrow
      css={css`
        width: 120px;
      `}
      options={apiKeys}
      maxTagCount={0}
      maxTagTextLength={12}
      dropdownMatchSelectWidth={150}
      placeholder="apikeys"
      value={currentApiKeyValue?._id}
      onChange={updateApiKey}
    />
  );
}

function renderName(user) {
  if (!user) return '';
  if (user?.profile?.firstName || user?.profile?.lastName)
    return [user?.profile?.firstName ?? '_', user?.profile?.lastName].join(' ');
  return user.username ?? user.email;
}

function Header() {
  const { user, logout } = useAuth();
  const { goTo } = useRouterActions();
  const [t] = useTranslate();
  const [route] = useCurrentRoute();
  const { handleResize, mobileMode, menuCollapsed } = useLayout();

  const menu = (
    <Menu>
      <Menu.Item icon={<SettingOutlined />} onClick={() => goTo('/setting/accout', { tab: 'profile' })}>
        {t('route.setting.title')}
      </Menu.Item>
      <Menu.Item icon={<LogoutOutlined />} onClick={() => logout()}>
        {t('auth.logout')}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row align="middle" justify="space-between">
        <Space size={1} align="baseline">
          <CollapsedIcon />
          {!mobileMode && <Text type="title">{t(route?.title)}</Text>}
        </Space>

        <Dropdown overlay={menu} placement="bottomCenter">
          <Space
            css={css`
              margin: 0 8px;
            `}
          >
            <Avatar size="small" icon={<UserOutlined />} />
            {!mobileMode && (
              <Text
                ellipsis
                css={css`
                  width: 100px;
                `}
              >
                {renderName(user)}
              </Text>
            )}
          </Space>
        </Dropdown>
      </Row>
    </>
  );
}

function getSideWidth({ mobileMode, menuCollapsed }): number {
  if (mobileMode) return 0;
  return menuCollapsed ? 64 : 200;
}

const BaseLayout: React.FC<any> = (props) => {
  const { children = null } = props;
  const { handleResize, mobileMode, menuCollapsed } = useLayout();

  React.useEffect(() => {
    handleResize();
    window.onresize = handleResize;
    return () => {
      window.onresize = null;
    };
  }, []);

  return (
    <Layout>
      <LeftSideMenu />
      <Layout
        css={css`
          ${styles.mainLayout};
          margin-left: ${getSideWidth({ mobileMode, menuCollapsed })}px;
        `}
      >
        <Layout.Header style={{ background: '#fff', padding: 0 }}>
          <Header />
        </Layout.Header>
        <div css={styles.border} />
        <Layout.Content>{children}</Layout.Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

function BaseLayoutWrap(props: any) {
  return (
    <LayoutProvider>
      <BaseLayout {...props} />
    </LayoutProvider>
  );
}

export default BaseLayoutWrap;
