import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { AuthService, DefaultService } from 'services';
import { useAvailableFiltersAPI } from 'services/data/filters';
import { useChannels } from 'services/hook';
import { parseTimeRangeKey, timeRangeOptions } from 'utils/days';
import { useAuth } from './auth';
import { buildContext } from './cors';

const filterKeys = ['country', 'content', 'browser', 'os', 'isp', 'origins'];
export const emptyFilter = Object.fromEntries(filterKeys.map((key) => [key, []]));

function getInitDate(user): [Date, Date, string] {
  const defaultKeyFromUser = user?.filterState?.timeRangeKey;
  if (defaultKeyFromUser) {
    const { start, end } = parseTimeRangeKey(user?.filterState?.timeRangeKey);
    return [start, end, defaultKeyFromUser];
  }
  return [dayjs().add(-30, 'm').toDate(), dayjs().toDate(), timeRangeOptions[0].display ?? ''];
}

function getInitApiKey(user, apiKeys) {
  if (user?.filterState?.apiKey) {
    const a = apiKeys.find((ak) => ak._id === user?.filterState?.apiKey);
    if (a) return a;
  }
  return apiKeys[0];
}
function parseFilter({ content, contentsFromDB, ...rest }) {
  const res = Object.fromEntries(Object.entries(rest).map(([k, v]) => [k, v.map((e) => ({ label: e, value: e }))]));
  return {
    ...res,
    content: content.map((c) => {
      const res = contentsFromDB.find((_c) => _c.url === c);
      if (!res) return { label: c.slice(-25), value: c };
      return { value: c, _id: res._id, label: res.name.slice(-25) };
    }),
  };
}
function _useFilters() {
  const { user, setReady, authReady } = useAuth();

  const [apiKeys, setApiKeys] = useState<any[]>([]);
  const [realTimeLastNMinutes, setRealTimeLastNMinutes] = useState<number | undefined>(undefined);
  const [statApiKeys, setStatApiKeys] = useState<any[]>([]);
  const [curApiKeys, setCurApiKeys] = useState<any[]>([]);
  const [currentApiKey, setCurApiKey] = useState<any>({});
  const [date, setDate] = useState<[Date, Date, string]>(getInitDate(user));
  // const [statFilters, setStatFilters] = useState<any>(emptyFilter);
  const [selectedFilters, setSelectedFilters] = useState<any>(emptyFilter);
  const { channels: contents, loadChannels: loadContents } = useChannels();
  const { statFilters } = useAvailableFiltersAPI(currentApiKey?.key);
  const loadApiKeys = useCallback(async () => {
    let resApiKeys = await DefaultService.apikeys.list();
    setApiKeys([...resApiKeys]);

    if (user.apiKeys.indexOf('*') !== -1) {
      // add All ApiKey Option
      resApiKeys = [{ _id: '*', id: '*', key: '*', name: 'All' }, ...resApiKeys];
    }

    setStatApiKeys([...resApiKeys]);

    setCurApiKey(getInitApiKey(user, resApiKeys));

    if (!authReady) {
      setReady(true);
    }
  }, [authReady, setReady, user]);

  const updateDate = useCallback(
    ({
      start,
      end,
      dateOptionKey,
      lastNMinutes,
    }: {
      start: Date;
      end: Date;
      dateOptionKey?: string;
      lastNMinutes?: number;
    }) => {
      // console.info(start, end, dateOptionKey);
      setRealTimeLastNMinutes(lastNMinutes);
      setDate([
        start,
        end,
        dateOptionKey === undefined ? `${start.toISOString()}##${end.toISOString()}` : dateOptionKey,
      ]);
    },
    []
  );

  const updateSelectedFilters = useCallback((filters: any, clear = false, reset = false) => {
    if (clear) {
      setSelectedFilters(filters);
    } else {
      setSelectedFilters((prev) => ({ ...prev, ...filters }));
    }
    if (reset) {
      setSelectedFilters(emptyFilter);
    }
  }, []);

  const updateApiKey = useCallback(
    (apiKeyId: string) => {
      if (apiKeyId) {
        const a = statApiKeys.find((ak) => ak._id === apiKeyId);
        if (a) setCurApiKey(a);
        AuthService.updateState({ apiKey: a._id });
      }
    },
    [statApiKeys]
  );

  useEffect(() => {
    if (user) {
      loadApiKeys();
      setDate(getInitDate(user));
    } else {
      setApiKeys([]);
      setCurApiKeys([]);
    }
  }, [loadApiKeys, user]);

  return {
    apiKeys,
    // stat api key has all option
    statApiKeys,
    curApiKeys,
    apiKey: currentApiKey.key,
    currentApiKeyValue: currentApiKey,
    from: date[0],
    to: date[1],
    realTimeLastNMinutes,
    dateOptionKey: date[2],
    availableFilters: parseFilter({ ...statFilters, contentsFromDB: contents }),
    selectedFilters,
    updateDate,
    updateSelectedFilters,
    updateApiKey,
    loadApiKeys,
    contents,
    loadContents,
  };
}

const [FiltersProvider, useFilters] = buildContext(_useFilters);
export { useFilters, FiltersProvider };
