import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import { TableModalTemplate } from 'components/misc/TableTemplate';
import { useConfig } from 'contexts/auth';
import { useFilters } from 'contexts/filters';
import React from 'react';
import { COUNTRY_LIST } from 'utils/enums';
import { DefaultService } from '../../services';

// {
//   "maxUsers": 1000,
//   "maxApiKeys": 1000,
//   "apiKeys": [],
//   "features": [
//       "INTERNAL",
//       "USER_MANAGE",
//       "LICENSE_MANAGE",
//       "CHANNEL_CONFIG",
//       "PLAYER_INSTALL",
//       "V2V_CONFIG",
//       "NOTIFICATION",
//       "REPORT"
//   ],
//   "createdBy": "system",
//   "_id": "60425d7111f0b77f68382a10",
//   "name": "EB System",
//   "region": "FR",
//   "createdAt": "2021-03-05T16:33:53.171Z",
//   "updateAt": "2021-03-05T16:33:53.171Z",
//   "__v": 0
// }

const modelParams = {
  name: 'column.license',
  service: DefaultService.licenses,
  searchKeys: ['name'],
  parseData: (params) => ({ ...params, apiKeys: params.apiKeys.map((e) => e._id) }),
};

const tableColumns = [
  {
    title: 'column.name',
    dataIndex: 'name',
    clickToEdit: true,
  },
  {
    title: 'column.region',
    dataIndex: 'region',
  },
  {
    title: 'column.features',
    dataIndex: 'features',
    showAsTags: true,
    width: '40%',
  },
  {
    title: 'column.apikeys',
    dataIndex: 'apiKeys',
    showAsTags: true,
    width: '30%',
  }
];

function LicenseForm(props) {
  const { apiKeys } = useFilters();
  const { features } = useConfig();
  return (
    <FormWrap {...props}>
      <FormItemWrap.Input label="column.display" name="name" />
      <FormItemWrap.Select
        label="column.region"
        name="region"
        options={COUNTRY_LIST.map((c) => ({ label: c.emoji + ' ' + c.name, value: c.code }))}
      />
      <FormItemWrap.TagCheckBox label="column.features" name="features" options={Object.values(features)} required={false} />
      <FormItemWrap.Select multiple label="column.apikeys" name="apiKeys" options={apiKeys} required={false} />
      <FormItemWrap.Input label="column.maxUsers" name="maxUsers" type="number" required={false} />
      <FormItemWrap.Input label="column.maxApikeys" name="maxApiKeys" type="number" required={false} />
    </FormWrap>
  );
}

export default function () {
  const model = TableModalTemplate.useModel(modelParams);
  return (
    <TableModalTemplate
      {...model}
      baseColumns={tableColumns}
      formComponent={<LicenseForm form={model.form} name={modelParams.name} />}
    />
  );
}
