import { EText } from 'components/base';
import React from 'react';
import * as CONSTANTS from 'utils/constants';
import { useTranslate } from 'utils/intl';

const generateHtmlTem = (body: string) => `<!DOCTYPE html>
<html>
<head>
<meta name="viewport" content="width=device-width, height=device-height, initial-scale=1, maximum-scale=1" />
</head>
<body style="height: 100vh;">
<div>
${body}
</body>
</html>`;

function download(data: any, filename: any, type?: any) {
  var file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    var a = document.createElement('a'),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

export default function ({ channelId }: any) {
  const [t] = useTranslate();

  const code1 = `<div id="eb_player"></div>`;
  const code2 = `<script src="${CONSTANTS.BASE_URL}v2/player/embed/${channelId}" defer></script>`;

  // const playerHtmlURL = `${window.location.origin}/player.html`;
  const playerHtmlURL = `https://rd.easybroadcast.fr/player.html`;
  const iframeCode = `<iframe title="player" src="${playerHtmlURL}?channel_id=${channelId}" />`;

  const onDownload = (body: string, prefix: string) => {
    // const body = `<div style="height:  400px;">${code1}</div> \n${code2}`;
    download(generateHtmlTem(body), 'eb_template_' + prefix + '_' + channelId.substring(0, 5) + '.html');
  };

  return (
    <>
      <div style={{ width: '100%', padding: 20, borderRadius: 4, background: '#f0f0f0' }}>
        <EText type="paragraph">// html element</EText>
        <EText type="paragraph">{code1}</EText>
        <EText type="paragraph" copyable={{ text: `${code1} \n${code2}`, tooltips: false }}>
          {code2}
        </EText>
      </div>
      <EText>
        {t('page.player.copyCode')}
        <EText type="link" onClick={() => onDownload([code1, code2].join('\n'), 'element')}>
          {t('page.player.download')}
        </EText>
        {t('page.player.toTest')}
      </EText>

      <div style={{ marginTop: 30, width: '100%', padding: 20, borderRadius: 4, background: '#f0f0f0' }}>
        <EText type="paragraph">// iframe</EText>
        <EText type="paragraph" copyable={{ text: iframeCode, tooltips: false }}>
          {iframeCode}
        </EText>
      </div>
      <EText>
        {t('page.player.copyCode')}
        <EText type="link" onClick={() => onDownload(iframeCode, 'iframe')}>
          {t('page.player.download')}
        </EText>
        {t('page.player.toTest')}
      </EText>
    </>
  );
}
