import React from 'react';
import CardPageLayout from 'layouts/CardPageLayout';
import { EButton, EText } from 'components/base';
import { Col, Row } from 'components/antd';
import { Space, Steps } from 'antd';
import { css } from '@emotion/react';
import { useTranslate } from 'utils/intl';
import CodeInstallation from './steps/CodeInstallation';
import { PlayerConfigProvider, usePlayerModel } from './function';
import SelectChannel from './steps/SelectChannel';
import SetPlayerConfig from './steps/PlayerConfig';

const styles = {
  content: css`
    margin-top: 16px;
    min-height: 550px;
  `,
};

function CodeInstall({ step = 2 }) {
  const { content, updateStep, step: currentStep } = usePlayerModel();
  if (currentStep !== step) return null;
  return (
    <Space direction="vertical" size={16} style={{ marginTop: 8 }}>
      <CodeInstallation channelId={content?._id ?? 'unset'} />
      <EButton _type="solid" text={'Next'} onClick={() => updateStep(currentStep + 1)} />
    </Space>
  );
}

function InstallCheck({ step = 3 }) {
  const { step: currentStep } = usePlayerModel();
  const [t] = useTranslate();
  if (currentStep !== step) return null;
  return <EText>{t('page.player.finish')}</EText>;
}

function ConfigSteps() {
  const { step, updateStep, content } = usePlayerModel();
  const [t] = useTranslate();

  const notSelectContent = !content;
  const noPlayer = !content?.playerConfig;

  const steps = [
    { title: 'page.player.createOrSelectContent', content: <SelectChannel /> },
    { title: 'page.player.playerConfig', content: <SetPlayerConfig />, disabled: notSelectContent },
    { title: 'page.player.codeInstall', content: <CodeInstall />, disabled: notSelectContent || noPlayer },
    { title: 'page.player.finish', content: <InstallCheck />, disabled: notSelectContent || noPlayer },
  ];

  return (
    <Steps direction="vertical" size="small" progressDot current={step} onChange={updateStep}>
      {steps.map(({ title, content, disabled }, index) => (
        <Steps.Step key={String(index)} title={t(title)} description={content} disabled={disabled} />
      ))}
    </Steps>
  );
}

function PlayerPage() {
  const [t] = useTranslate();
  return (
    <PlayerConfigProvider>
      <CardPageLayout>
        <CardPageLayout.Title>{t('page.player.title')}</CardPageLayout.Title>
        <EText>{t('page.player.desc')}</EText>
        <Row gutter={[10, 10]} css={styles.content}>
          <Col sm={24} md={24}>
            <ConfigSteps />
          </Col>
        </Row>
      </CardPageLayout>
    </PlayerConfigProvider>
  );
}

export default PlayerPage;
