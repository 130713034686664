import React from 'react';
import { Form, Input, Select, Radio, InputNumber, Switch } from 'antd';
import { useTranslate } from 'utils/intl';
import { compareStr } from 'utils/common';
import { Text } from 'components/base';
import TagCheckbox from './TagCheckbox';
import { cloneElement } from 'utils/helpers';
import { FormItemProps } from 'antd/lib/form/FormItem';
import Tooltip from 'antd/es/tooltip';

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
  labelAlign: 'left',
};

const layoutWithoutLabel = {
  span: 17,
  offset: 7,
};

function LabelText({ label }: { label: string }) {
  return (
    <Text type="cardTitle" style={{ textTransform: 'capitalize' }}>
      {label}
    </Text>
  );
}

// form items wrap

export function FormSelect(props: any) {
  const { options = [] } = props;
  const _options = options.map((o: any) => ({ value: o._id ?? o.id ?? o.value, label: o.name ?? o.label }));
  return (
    <Select
      showSearch
      filterOption={(input: any, option: any) => compareStr(option?.children ?? option?.label, input)}
      dropdownStyle={{ zIndex: 2000 }}
      {...props}
      options={_options}
    />
  );
}

export function FormRadioGroup(props: any) {
  const { options = [] } = props;
  return (
    <Radio.Group {...props}>
      {options.map((ele: any) => (
        <Radio value={ele} key={ele}>
          {ele}
        </Radio>
      ))}
    </Radio.Group>
  );
}

interface FormItemWrapProps extends FormItemProps {
  required?: boolean;
  label?: string;
  name?: string;
  placeholder?: string;
  children?: React.ReactNode;
  tooltip?:
    | string
    | {
        title: string;
        icon: React.ReactNode;
      };
  disabled?: boolean;
  valuePropName?: string;
}

export function FormItemWrap(props: FormItemWrapProps) {
  const {
    required = false,
    label,
    children = null,
    tooltip: _tooltip,
    disabled = false,
    rules: _propsRules = [],
    ...rest
  } = props;
  const [t] = useTranslate();

  let tooltip = _tooltip;
  if (typeof _tooltip === 'string') {
    tooltip = t(_tooltip);
  } else if (typeof _tooltip?.title === 'string') {
    tooltip = { ..._tooltip, title: t(_tooltip.title) };
  }

  const rules = [
    ...(required ? [{ required: true, message: t('form.empty', { label: label ? t(label) : 'it' }) }] : []),
    ..._propsRules,
  ];

  const labelProps = label
    ? {
        label: <LabelText label={t(label)} />,
      }
    : { wrapperCol: layoutWithoutLabel };

  const formItemProps = {
    ...labelProps,
    rules,
    tooltip,
    ...rest,
  };

  return <Form.Item {...formItemProps}>{cloneElement(children, { disabled })}</Form.Item>;
}

function FormItemSelect(props: any) {
  const {
    label: _label,
    placeholder = '',
    options: _options = [],
    multiple = false,
    input = false,
    selectProps: _selectProps = {},
    ...rest
  } = props;
  const [t] = useTranslate();

  let selectProps = {};

  if (multiple) {
    selectProps = { ...selectProps, mode: 'multiple', ..._selectProps };
  }

  if (input) {
    selectProps = { ...selectProps, mode: 'tags', ..._selectProps };
  }

  let options = _options;
  if (typeof _options[0] === 'string') options = options.map((e) => ({ label: e, value: e }));

  let label;
  if (_label) {
    label = t(_label);
  }
  
  return (
    <FormItemWrap required={false} label={_label} {...rest}>
      <FormSelect
        {...selectProps}
        options={options}
        placeholder={placeholder || t('form.selectPlaceholder', { label })}
      />
    </FormItemWrap>
  );
}

interface InputItemProps {
  type?: 'normal' | 'number' | 'password';
  inputProps?: Record<string, any>;
}

function FormItemInput(props: InputItemProps & FormItemWrapProps) {
  const { label: _label, placeholder = '', type = 'normal', inputProps = {}, ...rest } = props;
  const [t] = useTranslate();

  let label;
  if (_label) {
    label = t(_label);
  }

  let element;
  switch (type) {
    case 'number':
      element = <InputNumber placeholder={placeholder || t('form.numberPlaceholder')} min={0} {...inputProps} />;
      break;
    case 'password':
      element = <Input.Password placeholder={placeholder || t('form.inputPlaceholder', { label })} {...inputProps} />;
      break;
    default:
      element = <Input placeholder={placeholder || t('form.inputPlaceholder', { label })} {...inputProps} />;
  }

  return (
    <FormItemWrap required={true} label={_label} {...rest}>
      {element}
    </FormItemWrap>
  );
}

function FormItemSwitch(props: { isNumber?: boolean } & FormItemWrapProps) {
  return (
    <FormItemWrap required={false} {...props} valuePropName="checked">
      <Switch size="small" />
    </FormItemWrap>
  );
}

function FormItemTagCheckbox(props: { options?: ({ label: string; value: string } | string)[] } & FormItemWrapProps) {
  const { options, ...rest } = props;
  return (
    <FormItemWrap required={false} {...rest}>
      <TagCheckbox options={options} />
    </FormItemWrap>
  );
}

function FormItemRadioGroup(
  props: {
    enums?: boolean;
    options?: ({ label: string; value: string; tips?: string } | string)[];
  } & FormItemWrapProps
) {
  const { options: _options = [], enums = false, ...rest } = props;
  const [t] = useTranslate();
  let options = _options.map((e) =>
    typeof e === 'string'
      ? { label: enums ? t(`enums.${e}.label`) : e, value: e, tips: enums ? t(`enums.${e}.tips`) : undefined }
      : e
  );
  return (
    <FormItemWrap {...rest} required={true}>
      <Radio.Group buttonStyle="solid" size="small">
        {options.map((e) => (
          <Tooltip title={e.tips} key={e.value}>
            <Radio.Button value={e.value}>{e.label}</Radio.Button>
          </Tooltip>
        ))}
      </Radio.Group>
    </FormItemWrap>
  );
}

FormItemWrap.Input = FormItemInput;
FormItemWrap.Select = FormItemSelect;
FormItemWrap.TagCheckBox = FormItemTagCheckbox;
FormItemWrap.RadioGroup = FormItemRadioGroup;
FormItemWrap.Switch = FormItemSwitch;

function FormWrap(props: any) {
  return (
    <Form colon={false} {...layout} {...props}>
      {props.children}
    </Form>
  );
}

export const { useForm, Item } = Form;
FormWrap.Item = Item;
FormWrap.useForm = useForm;

export default FormWrap;
