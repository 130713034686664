import axios from 'axios';
import QueryString from 'qs';
import { useCallback, useRef, useState } from 'react';
import * as CONSTANTS from './constants';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const jwt = require('jwt-decode');

export function validateExpired(token: string) {
  const { exp } = jwt(token);
  return exp < new Date().getTime() / 1000;
}

export function decodeUser(token: string) {
  const res = jwt(token);
  return res;
}

export const createAxiosInstance = (baseURL: string = CONSTANTS.BASE_URL, timeout = 60000) =>
  axios.create({
    baseURL,
    timeout,
    paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
  });

export function getTagsColor(index: number) {
  const colors_cons = [
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    'lime',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
  ];
  const colors = ['magenta', 'volcano', 'gold', 'green', 'blue', 'purple', 'red', 'orange', 'lime', 'cyan', 'geekblue'];
  return colors[index % colors.length];
}

export function formatTextInShort(text: string, length = 15) {
  return text.length >= length
    ? `${text.substring(0, 5)}...${text.substring(text.length - length + 5, text.length)}`
    : text;
}

export const blueColors = ['#d0f0fc', '#a4d8f2', '#75caed', '#25a7d8', '#0085cd', '#0052ba'];
export const opacitys = [0.1, 0.2, 0.3, 0.5, 0.7, 1];
export const redColors = ['#faebee', '#f4d9df', '#ecc5ce', '#e19fae', '#d5788b', '#c23b58'];
export const qualityColorMap = ['#c13d59', '#f8d119', '#08c388'];

export function useTimeout(ms: number): [boolean, () => void, () => void] {
  const [ready, setReady] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  const start = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setReady(true);
    timerRef.current = setTimeout(() => {
      setReady(false);
    }, ms);
  }, [ms]);

  const stop = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  return [ready, start, stop];
}

export function fakeRequest() {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), 1500);
  });
}

export const compareStr = (str = '', search = '') => str.trim().toLowerCase().includes(search.trim().toLowerCase());

// Here are some nice functions
export const formatString = function (str: string, ...args: any[]) {
  let i = 0;
  return str.replace(/{}/g, function () {
    return typeof args[i] != 'undefined' ? args[i++] : '';
  });
};
