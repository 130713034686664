import React from 'react';
import { AutoComplete, Input } from 'antd';

interface SearchOption {
  value: string;
}

interface SearchBarProps {
  onSearch: (text: string) => void;
  placeholder?: string;
}

export default function (props: SearchBarProps & any) {
  const { placeholder, onSearch, ...rest } = props;

  return (
    <AutoComplete
      {...rest}
      onSearch={onSearch}
      dropdownClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={500}
    >
      <Input.Search allowClear placeholder={placeholder} />
    </AutoComplete>
  );
}
