import { ThemeProvider } from '@emotion/react';
import AuthPage from 'containers/accounts/AuthPage';
import { AuthProvider, useAuth } from 'contexts/auth';
import { FiltersProvider } from 'contexts/filters';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Redirect, Route, Switch } from 'react-router';
import { HashRouter } from 'react-router-dom';
import themeStyle from 'styles/theme.less';
import { IntlProvider } from 'utils/intl';
import BaseLayout from './layouts/BaseLayout';
import ProtectedLayout from './layouts/ProtectedLayout';

const queryClient = new QueryClient();

// TODO: add Intl Provider and Theme Provider out of the whole application

function ProtectedContainers() {
  const { flattenRoutes } = useAuth();

  // console.log(flattenRoutes);
  return (
    <BaseLayout>
      <Switch>
        {flattenRoutes.map((r: any) => (
          <Route
            key={r.key}
            exact={r.exact ?? true}
            path={r.path}
            component={(r.redirect ? () => <Redirect to={{ pathname: r.redirect }} /> : r.component) as any}
          />
        ))}
        <Route path="/" exact component={() => <Redirect to={flattenRoutes[0].path} />} />
        <Route component={() => <Redirect to={flattenRoutes[0].path} />} />
      </Switch>
    </BaseLayout>
  );
}

function AppContainer() {
  const { lang } = useAuth();
  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider language={lang}>
        <FiltersProvider>
          <HashRouter>
            <Switch>
              <Route path="/auth/user" exact component={AuthPage} />
              <ProtectedLayout redirectTo="/auth/user">
                <ProtectedContainers />
              </ProtectedLayout>
            </Switch>
          </HashRouter>
        </FiltersProvider>
      </IntlProvider>
    </QueryClientProvider>
  );
}

export default function App() {
  return (
    <ThemeProvider theme={{ colors: themeStyle }}>
      <AuthProvider initUser={null} initRoutes={[]}>
        <AppContainer />
      </AuthProvider>
    </ThemeProvider>
  );
}
