import React from 'react';
import { Select } from 'antd';

export default function (props: any) {
  const { options = [], ...rest } = props;
  return (
    <Select
      optionFilterProp="label"
      showSearch
      filterOption
      {...props}
      options={options.map(({ name, id, _id }: any) => ({ value: id ?? _id, label: name }))}
    />
  );
}
