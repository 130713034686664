import { css } from '@emotion/react';
import { Col, Layout, Row } from 'antd';
import { ReactComponent as EBLongWhiteLogo } from 'assets/eb_long_logo_white.svg';
import { ReactComponent as EBSchemaWhite } from 'assets/eb_schema_white.svg';
import { EText, Text } from 'components/base';
import NotFound from 'components/misc/NotFound';
import { useQueryState } from 'contexts/router';
import React from 'react';
import { COPYRIGHT_FOOTER_TEXT } from 'utils/constants';
import { useTranslate } from 'utils/intl';
import ForgotPassword from './ForgotPassword';
import styles from './index.module.less';
import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';
const left = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 11,
  xl: 11,
};
const right = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 13,
  xl: 13,
};

const top = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 0,
  xl: 0,
};

const actions = [
  { key: 'login', title: 'auth.login', component: <Login /> },
  { key: 'register', title: 'auth.register', component: <Register /> },
  { key: 'reset_pwd', title: 'auth.resetPwd', component: <ResetPassword /> },
  { key: 'forgot_pwd', title: 'auth.forgotPwd', component: <ForgotPassword /> },
];

function LeftLogo() {
  return (
    <>
      <EBLongWhiteLogo className={styles.logo} />
      <EBSchemaWhite className={styles.leftImage} />
    </>
  );
}

function TopLogo() {
  return <EBLongWhiteLogo className={styles.logo} />;
}

const _styles = {
  contentContainer: css`
    min-height: 500px;
    margin: 0 auto;
    max-width: 800px;
    padding-top: 50px;
  `,
  right: css`
    background: #fff;
    max-width: 450px;
    margin: 0 auto;
    padding: 30px;
  `,
};

function AuthPage() {
  const { query } = useQueryState({ action: 'login' });
  const selected = actions.find(({ key }) => key === query.action) ?? actions[0];
  const [t] = useTranslate();

  return (
    <Layout className={styles.background}>
      <Layout.Content>
        <div style={{ paddingTop: 'calc(min(50vh, 400px) - 300px)' }}>
          <Row css={_styles.contentContainer}>
            <Col {...left} className={styles.left}>
              <LeftLogo />
            </Col>
            <Col {...top} className={styles.top}>
              <TopLogo />
            </Col>
            <Col {...right} css={_styles.right}>
              <Row>
                <EText type="title">{t(selected?.title)}</EText>
              </Row>
              {selected?.component ?? <NotFound />}
            </Col>
          </Row>
        </div>
      </Layout.Content>
      <Layout.Footer className={styles.footer}>
        <Text style={{ fontSize: '0.75rem' }}>{COPYRIGHT_FOOTER_TEXT}</Text>
      </Layout.Footer>
      
    </Layout>
  );
}

export default AuthPage;
