import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import StatusBadge from 'components/antd/StatusBadge';
import { TableModalTemplate } from 'components/misc/TableTemplate';
import React from 'react';
import { useLicensesAndRoles } from 'services/hook';
import { dateFormatter } from 'utils/days';
import { Status } from 'utils/enums';
import { DefaultService } from '../../../services';


// {
//   "profile": {
//       "lang": "en"
//   },
//   "username": "",
//   "disabled": false,
//   "createdBy": "60425d7111f0b77f68382a13",
//   "_id": "6086be0e41cf072fe9e680be",
//   "license": {
//       "_id": "60828d5779b32318850cfcfd",
//       "name": "Test"
//   },
//   "email": "test@easyb.fr",
//   "createdAt": "2021-04-26T13:20:14.227Z",
//   "updatedAt": "2021-04-26T13:20:14.227Z",
//   "__v": 0
// }

const modelParams = {
  name: 'page.user.user',
  service: DefaultService.users,
  searchKeys: ['email', 'name', 'username'],
  parseData: (params) => {
    return {
      ['role._id']: undefined,
      active: params.status === Status.ACTIVE,
      ['license._id']: undefined,
      ...TableModalTemplate.flattenObject(params),
    };
  },
  toData: (params) => {
    return {
      ...TableModalTemplate.unflattenObject({ ...params }),
      license: params['license._id'],
      role: params['role._id'],
      status: params['active'] ? Status.ACTIVE : Status.DISABLED,
    };
  },
};

const tableColumns = [
  {
    title: 'page.user.email',
    dataIndex: 'email',
    clickToEdit: true,
  },
  { title: 'column.license', dataIndex: 'license', render: (license) => license?.name ?? '' },
  { title: 'page.user.role', dataIndex: 'role', render: (role) => role?.name },
  {
    title: 'page.user.status',
    dataIndex: 'status',
    render: (status: number) => <StatusBadge status={status} />,
    width: 120,
  },
  { title: 'page.user.lastConnection', dataIndex: 'lastLogin', render: (date) => dateFormatter(date, { type: 'relative' }) },
];

function Form(props) {
  const { modalSelectedData, ...rest } = props;
  const { licenses, roles } = useLicensesAndRoles();
  return (
    <FormWrap {...rest} initialValues={{ active: true }}>
      <FormItemWrap.Input label="page.user.email" name="email" required />
      <FormItemWrap.Input label="page.user.username" name="username" required={false} />
      <FormItemWrap.Input label="page.user.newPassword" name="password" required={!modalSelectedData} />
      {licenses.length > 0 ? (
        <FormItemWrap.Select required label="column.license" name="license._id" options={licenses} />
      ) : null}
      <FormItemWrap.Select label="page.user.role" name="role._id" options={roles} required={false} />
      <FormItemWrap.Switch label="page.user.active" name="active" valuePropName="checked" required={false} />
    </FormWrap>
  );
}

export default function UserPage({ userStatus = Status.ACTIVE }: { userStatus?: Status }) {
  const model = TableModalTemplate.useModel(modelParams);
  return (
    <TableModalTemplate
      {...model}
      data={model.data.filter((u) => u.status === userStatus)}
      baseColumns={tableColumns}
      formComponent={<Form modalSelectedData={model.modalSelectedData} form={model.form} name={modelParams.name} />}
    />
  );
}
