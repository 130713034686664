import PieChartCard from 'components/visualizations/PieChartCard';
import { BaseOption, useSessionBreakdown } from 'containers/analytics/utils/hooks';
import { capitalizeAll } from 'humanize-plus';
import React from 'react';
import { useTranslate } from 'utils/intl';

const UserAgentPieWithData = ({ option, col, topN }: { option: BaseOption; col: 'os' | 'browser' | 'device'; topN: number }) => {
  const [t] = useTranslate();

  const { data, isLoading } = useSessionBreakdown({ option, by: col, topN });

  return <PieChartCard loading={isLoading} data={data} title={capitalizeAll(t(col.toUpperCase()))} />;
};

export default UserAgentPieWithData;
