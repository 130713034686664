import { useAuth } from 'contexts/auth';
import { useCallback, useEffect, useState } from 'react';
import { DefaultService } from 'services';

export function useChannels() {
  const [channels, setChannels] = useState<any[]>([]);

  async function loadChannels() {
    try {
      setChannels(await DefaultService['channels'].list());
    } catch (e) { }
  }

  useEffect(() => {
    loadChannels();
  }, []);
  return { channels, loadChannels };
}

export function useLicensesAndRoles() {
  const { user } = useAuth();
  const [licenses, setLicenses] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  useEffect(() => {
    async function init() {
      try {
        if (user.licenses.length > 1 || user.licenses.includes('*')) {
          setLicenses(await DefaultService['licenses'].list());
        }
        setRoles(await DefaultService['roles'].list());
      } catch (e) { }
    }
    init();
  }, [user]);
  return { licenses, roles };
}

export function useLicenses() {
  const { user } = useAuth();
  const [licenses, setLicenses] = useState<any[]>([]);
  useEffect(() => {
    async function init() {
      try {
        if (user.licenses.length > 1 || user.licenses.includes('*')) {
          setLicenses(await DefaultService['licenses'].list());
        }
      } catch (e) { }
    }
    init();
  }, [user]);
  return { licenses };
}

export function useRequestWithLoading(
  _req: (...params: any[]) => Promise<any>
): [boolean, (...params: any[]) => Promise<any>] {
  const [loading, setLoading] = useState(false);
  const req = useCallback(
    async (...params) => {
      setLoading(true);
      let res;
      try {
        res = await _req(...params);
      } finally {
        setLoading(false);
      }
      return res;
    },
    [_req]
  );
  return [loading, req];
}

export function useReqsWithLoading(...requests: ((...params: any) => Promise<any>)[]) {
  const [loading, setLoading] = useState(false);
  const _requests = [] as any[];
  for (let i = 0; i < requests.length; i += 1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const _req = useCallback(async (...params: any) => {
      setLoading(true);
      let res;
      try {
        res = await _req(...params);
      } finally {
        setLoading(false);
      }
      return res;
    }, []);
    _requests.push(_req);
  }
  return [loading, ..._requests];
}
