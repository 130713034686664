import BarChartCard from 'components/visualizations/BarChartCard';
import { useWatchingTimeData } from 'containers/analytics/utils/hooks';
import { useFilters } from 'contexts/filters';
import { duration } from 'dayjs';
import React from 'react';
import { useTranslate } from 'utils/intl';

export const WatchingTimeBarchartWithData = () => {
  const [t] = useTranslate();
  const { avgWatchingTime, watchingTimeData } = useWatchingTimeData();
  const { from, to } = useFilters();

  function formatWatchingHour(milliseconds: number) {
    if (milliseconds < 1000 * 60) return [duration(milliseconds).asSeconds().toFixed(0), 'seconds'];
    const mins = Math.floor(duration(milliseconds).asMinutes());
    if (mins < 60) return [`${mins}`, 'minutes'];
    return [`${Math.floor(mins / 60)}h${mins % 60}m`, ''];
  }

  return (
    <BarChartCard
      data={watchingTimeData}
      intlKey="analytics.watchingTime"
      summaryText={formatWatchingHour(avgWatchingTime * 1000)[0]}
      unit={formatWatchingHour(avgWatchingTime * 1000)[1]}
      extra="AVG"
      // tipsText={`measured between ${new Date(from).toLocaleString()} and ${new Date(to).toLocaleString()}`}
      tipsText={t('analytics.tooltips.wtcard', {
        start: new Date(from).toLocaleString(),
        end: new Date(to).toLocaleString(),
      })}
    />
  );
};

export default {};
