import React, { useCallback, useState } from 'react';
import { Notification } from 'utils/notification';
import { getToken, removeLocalStorageValue, setLocalStorageValue, setToken } from 'utils/localStorage';
import { PublicService, AuthService, DefaultService } from 'services';
import { buildContext } from './cors';
import { Routes, RouterProps } from 'router';
import { ACCESS_FEATURES } from 'utils/enums';

interface User {
  email: string;
  access: any;
}

const defaultConfig = {
  features: ACCESS_FEATURES,
};

function flatRoutes(routes: any[]) {
  function flatten(rs) {
    return rs.flatMap((r) => {
      if (r.children) {
        return [{ ...r, redirect: r.children[0]?.path, children: null }, ...r.children];
      }
      return [r];
    });
  }
  let res = [...routes];
  do {
    res = flatten(res);
  } while (res.some((r) => r.children));
  return res;
}

function getAuthorizedRoutes(routes: any[], userFeatures: any[] = []) {
  function checkAccess(route) {
    if (!route.authority) return true;
    // user features includes *
    if (userFeatures.includes('*')) return true;
    // user features has one of authority
    return route.authority.some((ra) => userFeatures.includes(ra));
  }

  function checkChildren(route) {
    if (route.children) {
      return { ...route, children: route.children.map(checkChildren).filter(checkAccess) };
    }
    return route;
  }

  return routes.map(checkChildren).filter(checkAccess);
}

function _useGlobal({ initUser = null, initRoutes = [] }: { initUser: any; initRoutes: any[] }) {
  const [ready, setReady] = useState(false);
  const [config, setConfig] = useState<any>(defaultConfig);
  const [user, setUser] = useState<any>(initUser);
  const [license, setLicense] = useState<any>(undefined);
  const [routes, setRoutes] = useState<RouterProps[]>(initRoutes);

  React.useEffect(() => {
    if (user?.license) {
      setLicense(user.license);
    }
  }, [user]);

  const login = useCallback(
    async ({ email, password, remember = false }: { email: string; password: string; remember: boolean }) => {
      // store email in local storage
      if (remember) {
        setLocalStorageValue('user.login', email);
      } else {
        removeLocalStorageValue('user.login');
      }

      const tokens = (await PublicService.login({ email, password })) as any;
      setToken(tokens);
    },
    []
  );

  const logout = useCallback(() => {
    setRoutes([]);
    setUser(null);
    setToken();
  }, []);

  const checkAuth = useCallback(async () => {
    if (getToken()) {
      // if token is available, try to get user
      try {
        const resUser: any = await AuthService.getUser();
        if (resUser) {
          setUser(resUser);

          // get routers
          const authorizedRoutes = getAuthorizedRoutes(Routes, resUser.features);
          setRoutes(authorizedRoutes);
        } else {
          setUser(null);
        }
        // get config
        const _config = await PublicService.getConfig();
        setConfig(_config);
      } catch (e) {
        // Token expired
        setUser(null);
        setReady(true);
      }
    } else {
      // token doesn't exist
      setUser(null);
      setReady(true);
    }
  }, []);

  const checkAccess = useCallback(
    (accessToCheck: string[] | string | undefined, operator: 'and' | 'or' = 'and') => {
      if (!user.features || !Array.isArray(user.features)) return false;
      if (accessToCheck === undefined) return true;
      if (typeof accessToCheck === 'string') {
        return user.features.includes('*') || user.features.indexOf(accessToCheck) > -1;
      } else {
        if (operator === 'and') {
          // all features to check should be included in access
          return accessToCheck.every((e) => user.features.includes('*') || user.features.indexOf(e) > -1);
        } else {
          // one of feature to check is included in access
          return accessToCheck.some((e) => user.features.includes('*') || user.features.indexOf(e) > -1);
        }
      }
    },
    [user]
  );

  const updateUserProfile = useCallback((profiles: any) => setUser((prev) => ({ ...prev, profile: profiles })), []);
  const updateUserState = useCallback((filterState: any, clear = false) => {
    if (clear) setUser((prev) => ({ ...prev, filterState }));
    setUser((prev) => ({ ...prev, filterState: { ...prev.filterState, ...filterState } }));
  }, []);

  return {
    user,
    authReady: ready,
    login,
    checkAuth,
    routes,
    flattenRoutes: flatRoutes(routes),
    logout,
    checkAccess,
    updateUserProfile,
    updateUserState,
    license,
    config,
    setReady,
  };
}

const _useAuth = ({
  user,
  authReady,
  login,
  checkAuth,
  routes,
  logout,
  checkAccess,
  updateUserProfile,
  updateUserState,
  license,
  setReady,
  flattenRoutes
}: any) => ({
  user,
  lang: user?.profile?.lang ?? 'en',
  authReady,
  login,
  checkAuth,
  routes,
  logout,
  checkAccess,
  updateUserProfile,
  updateUserState,
  license,
  setReady,
  flattenRoutes
});

const _useConfig = (values: any) => values.config;

export const [AuthProvider, useConfig, useAuth] = buildContext(_useGlobal, _useConfig, _useAuth);
