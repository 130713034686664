import FormWrap, { FormItemWrap } from 'components/antd/FormWrap';
import { TableModalTemplate } from 'components/misc/TableTemplate';
import { useFilters } from 'contexts/filters';
import { useQueryState } from 'contexts/router';
import { update } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { DefaultService } from 'services';

// {
//   "status": "PENDING",
//   "tags": [],
//   "playerConfig": null,
//   "createdBy": "60425d7111f0b77f68382a13",
//   "_id": "60914b4cde230ca89cc53c5f",
//   "name": "test 2",
//   "url": "http://cdnamd-hls-globecast.akamaized.net/live/ramdisk/espace_tv/hls_video/index.m3u8",
//   "apiKey": {
//       "_id": "6082936979b32318850cfcfe",
//       "name": "test1",
//       "key": "NdRymnNqFTIqoNp"
//   },
//   "createdAt": "2021-05-04T13:25:32.652Z",
//   "updatedAt": "2021-05-04T13:25:32.652Z",
//   "__v": 0
// }

const modelParams = {
  name: 'column.content',
  service: DefaultService.channels,
  searchKeys: ['name', 'url', 'apiKey'],
  toData: (params) => ({ ...params, apiKey: params['apiKey._id'] }),
};

const tableColumns = [
  {
    title: 'column.name',
    dataIndex: 'name',
    clickToEdit: true,
    width: '30%',
  },
  {
    title: 'column.apikey',
    dataIndex: 'apiKey',
    width: '20%',
    render: (apiKey) => apiKey.name,
  },
  {
    title: 'column.url',
    dataIndex: 'url',
    showWithTips: true,
  },
];

export function Form(props) {
  const { apiKeys } = useFilters();
  return (
    <FormWrap {...props}>
      <FormItemWrap.Input label="column.display" name="name" required />
      <FormItemWrap.Select label="column.apikey" name="apiKey._id" required options={apiKeys} />
      <FormItemWrap.Input label="column.url" name="url" required />
    </FormWrap>
  );
}

function useRouteQueryParams(model) {
  const { query } = useQueryState();
  const hasOpen = useRef(false);
  useEffect(() => {
    if (query?.id && model.data.length > 0 && !hasOpen.current) {
      model.openEditModal(query.id);
    }
  }, [query, model.data.length]);

  useEffect(() => {
    // console.log(model.modalVisible);
    if (model.modalVisible && query?.id) {
      hasOpen.current = true;
      model.search(query.url);
    }
  }, [model.modalVisible]);
}

export default function () {
  const model = TableModalTemplate.useModel(modelParams);
  useRouteQueryParams(model);

  return (
    <TableModalTemplate
      {...model}
      baseColumns={tableColumns}
      formComponent={<Form form={model.form} name={modelParams.name} />}
    />
  );
}
