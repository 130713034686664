import BarChartCard from 'components/visualizations/BarChartCard';
import { useRebufferingTimeData } from 'containers/analytics/utils/hooks';
import React from 'react';
import { useTranslate } from 'utils/intl';

const RebufferingTimeBarchartWithData = () => {
  const { rebufferingTimeData, avgRebufferingTime } = useRebufferingTimeData();
  const [t] = useTranslate();
  return (
    <BarChartCard
      data={rebufferingTimeData}
      intlKey="analytics.rebufferingTime"
      summaryText={avgRebufferingTime ? avgRebufferingTime.toFixed(2) : 'null'}
      unit={t('seconds')}
      extra="AVG"
    />
  );
};
export default RebufferingTimeBarchartWithData;
