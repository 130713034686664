import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Text } from 'components/base';
import EditableCellTable from 'components/input/EditableCellTable';
import dayjs from 'dayjs';
import React from 'react';
import styles from './mapTable.module.less';

function downloadCSV(csv: any, filename: any) {
  let csvFile, downloadLink;

  //define the file type to text/csv
  csvFile = new Blob([csv], { type: 'text/csv' });
  downloadLink = document.createElement('a');
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = 'none';

  document.body.appendChild(downloadLink);
  downloadLink.click();
}

function parseDataToMapData(data = { features: [] }) {
  const mapTableData = data.features
    .map((v: any) => ({
      id: v.properties.name,
      views: v.properties.views,
      v2v: Math.floor((v.properties.v2v / (1024 * 1024 * 1024)) * 100) / 100,
      cdn: Math.floor((v.properties.cdn / (1024 * 1024 * 1024)) * 100) / 100,
    }))
    .sort((a: any, b: any) => b.views - a.views);
  return mapTableData;
}

function downloadMapData(data: any) {
  const mapTableData = parseDataToMapData(data);
  let csvContent = mapTableData
    .map((e: any) => [e.id, e.views, e.v2v, e.cdn])
    .map((e: any) => e.join(','))
    .join('\n');

  downloadCSV('country,views,v2v(MB),cdn(MB)\n' + csvContent, `country_data_${dayjs().format('hhmm-DDMMYY')}`);
}

function MapTable({ data = { features: [] } }: any) {
  const mapTableData = parseDataToMapData(data);

  const getSummary = (pageData: any) => {
    let totalViews = 0;
    let totalV2V = 0;
    let totalCDN = 0;

    pageData.forEach(({ views, v2v, cdn }: any) => {
      totalViews += views;
      totalV2V += v2v;
      totalCDN += cdn;
    });

    return (
      <div style={{ display: 'flex' }}>
        <span className={styles.tableCell} style={{ textAlign: 'left' }}>
          <Text type="cardTitle">Total</Text>
        </span>
        <Tooltip title={`${totalViews} Visits`}>
          <span className={styles.tableCell}>{totalViews}</span>
        </Tooltip>
        <Tooltip title={`V2V: ${totalV2V.toFixed(2)} GB`}>
          <span className={styles.tableCell}>{totalV2V.toFixed(2)}</span>
        </Tooltip>
        <Tooltip title={`CDN: ${totalCDN.toFixed(2)} GB`}>
          <span className={styles.tableCell}>{totalCDN.toFixed(2)}</span>
        </Tooltip>
      </div>
    );
  };

  return (
    <div style={{}}>
      {/* <div style={{ display: 'flex', marginRight: '1rem', cursor: 'pointer', padding: '5px' }}>
        <div style={{ flexGrow: 1 }}>Total Views: {mapTableData.reduce((a: any, b: any) => a + b.views, 0)}</div>
        <div>
          <DownloadOutlined
            aria-description="Download csv"
            onClick={() => {
              let csvContent = mapTableData
                .map((e: any) => [e.id, e.views, e.v2v, e.cdn])
                .map((e: any) => e.join(','))
                .join('\n');

              downloadCSV('country,views,v2v(MB),cdn(MB)\n' + csvContent, 'country.csv');
            }}
          />
        </div>
      </div> */}
      <EditableCellTable
        className={styles.table}
        rowSelection={false}
        size="small"
        dataSource={mapTableData.filter((v: any) => v.views !== 0)}
        pagination={false}
        columns={[
          {
            title: 'Country',
            dataIndex: 'id',
            ellipsis: { showTitle: false },
            render: (country: any) => (
              <Tooltip placement="topLeft" title={country.length > 10 ? country : false}>
                {country}
              </Tooltip>
            ),
          },
          {
            title: 'Views',
            dataIndex: 'views',
            align: 'center',
          },
          {
            title: 'V2V(GB)',
            dataIndex: 'v2v',
            align: 'center',
          },
          {
            title: 'CDN(GB)',
            dataIndex: 'cdn',
            align: 'center',
          },
        ]}
        scroll={{ y: 340 }}
        footer={getSummary}
      />
    </div>
  );
}

MapTable.downloadCSVAction = {
  label: 'Data (.csv)',
  icon: <DownloadOutlined />,
  onClick: (data: any) => downloadMapData(data),
};

export default MapTable;
